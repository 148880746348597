import styled from 'styled-components';
import 'reactflow/dist/base.css';

export const FlowWrapper = styled.div<{
  $height: number;
  $locked: boolean;
}>`
  height: ${({ $height }) => `${$height}px`};
  width: 100%;
  position: relative;
  user-select: none;

  .react-flow {
    background-color: #303030;
    background-color: ${({ theme }) => theme.palette.container.background};
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .react-flow__renderer,
  .react-flow__pane,
  .react-flow__selectionpane {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .react-flow__attribution {
    bacground: none;
  }

  .react-flow__pane {
    z-index: 1;
  }

  .react-flow__renderer {
    z-index: 4;
  }

  .react-flow__selectionpane {
    z-index: 5;
  }

  .react-flow__selection {
    position: absolute;
    top: 0;
    left: 0;
  }

  .react-flow__edges {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 2;
  }

  .react-flow__edge {
    pointer-events: visibleStroke;

    &.inactive {
      pointer-events: none;
    }
  }

  @keyframes dashdraw {
    from {
      stroke-dashoffset: 10;
    }
  }

  .react-flow__edge-path {
    fill: none;
  }

  .react-flow__edge-textwrapper {
    pointer-events: all;
  }

  .react-flow__edge-text {
    fill: #fff;
    pointer-events: none;
    user-select: none;
  }

  .react-flow__connection {
    pointer-events: none;

    .animated {
      stroke-dasharray: 5;
      animation: dashdraw 0.5s linear infinite;
    }
  }

  .react-flow__connection-path {
    fill: none;
  }

  .react-flow__nodes {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transform-origin: 0 0;
    z-index: 3;
  }

  .react-flow__node {
    position: absolute;
    user-select: none;
    pointer-events: all;
    transform-origin: 0 0;
    background-color: #424242;
    box-shadow:
      0px 3px 3px -2px rgb(0 0 0 / 20%),
      0px 3px 4px 0px rgb(0 0 0 / 14%),
      0px 1px 8px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
    border: 1px solid #777;
    padding: 10px;
    transition: box-shadow 2s ease-in-out;
  }

  .react-flow__node-layoutWidgetNode {
    padding: 20px 10px 10px 20px;
    background-color: #303030;
    border-radius: 10px;
    border: 1px solid #1e1e1e;
    box-shadow:
      0px 4px 4px rgba(0, 0, 0, 0.25),
      inset 3px 3px 5px rgba(169, 194, 84, 0.3);
  }

  .react-flow__node__highlighted {
    box-shadow: 0px 0px 0px 2px rgb(255 255 255);
    transition: box-shadow 0.5s ease-in-out;
  }

  .react-flow__node.widget-selected {
    background: #ffffff;
    color: #0a0f26;
  }

  .react-flow__node.widget-related {
    background: #a5a5a5;
    color: #0a0f26;
  }

  .react-flow__node h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 0 0;
  }

  .react-flow__node h4 {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    margin: 2px 0 0 0;
  }

  .react-flow__nodesselection {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: left top;
    pointer-events: none;

    &-rect {
      position: absolute;
      pointer-events: all;
      cursor: grab;
    }
  }

  .react-flow__handle {
    pointer-events: none;

    &.connectable {
      cursor: ${({ $locked }) => ($locked ? 'pointer' : 'grab')} !important;
      pointer-events: all;
    }

    & > span {
      position: absolute;
      white-space: nowrap;
      top: -10px;
    }

    & span + span {
      margin-left: 5px;
    }
  }

  .react-flow__handle-left {
    top: 50%;
    left: -4px;
    transform: translate(0, -50%);

    & > span {
      left: 12px;
    }
  }

  .react-flow__handle-right {
    right: -4px;
    top: 50%;
    transform: translate(0, -50%);

    & > span {
      right: 12px;
    }
  }

  .react-flow__edgeupdater {
    cursor: move;
    pointer-events: all;
  }

  /* additional components */

  .react-flow__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .react-flow__controls {
    position: absolute;
    z-index: 5;
    bottom: 10px;
    left: 10px;

    &-button {
      width: 24px;
      height: 24px;

      svg {
        width: 100%;
      }
    }
  }

  .react-flow__minimap {
    position: absolute;
    z-index: 5;
    bottom: 10px;
    right: 10px;
  }

  .react-flow__selection {
    background: rgba(0, 89, 220, 0.08);
    border: 1px dotted rgba(0, 89, 220, 0.8);
  }

  .react-flow__edge {
    &.selected {
      .react-flow__edge-path {
        stroke: #555;
      }
    }

    &.animated path {
      stroke-dasharray: 5;
      animation: dashdraw 0.5s linear infinite;
    }

    &.updating {
      .react-flow__edge-path {
        stroke: #777;
      }
    }
  }

  .react-flow__edge-path {
    stroke: #b1b1b7;
    stroke-width: 1;
  }

  .react-flow__edge-text {
    font-size: 10px;
  }

  .react-flow__edge-textbg {
    fill: white;
  }

  .react-flow__connection-path {
    stroke: #b1b1b7;
    stroke-width: 1;
  }

  .react-flow__node {
    cursor: ${({ $locked }) => ($locked ? 'pointer' : 'grab')};
  }

  .react-flow__node-default,
  .react-flow__node-input,
  .react-flow__node-output {
    padding: 10px;
    border-radius: 3px;
    width: 150px;
    font-size: 12px;
    color: #222;
    text-align: center;
    border-width: 1px;
    border-style: solid;
  }

  .react-flow__node-default.selectable,
  .react-flow__node-input.selectable,
  .react-flow__node-output.selectable {
    &:hover {
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
    }
  }

  .react-flow__node-input {
    background: #fff;
    border-color: #0041d0;

    &.selected,
    &.selected:hover {
      box-shadow: 0 0 0 0.5px #0041d0;
    }

    .react-flow__handle {
      background: #0041d0;
    }
  }

  .react-flow__node-default {
    background: #fff;
    border-color: #1a192b;

    &.selected,
    &.selected:hover {
      box-shadow: 0 0 0 0.5px #1a192b;
    }

    .react-flow__handle {
      background: #1a192b;
    }
  }

  .react-flow__node-output {
    background: #fff;
    border-color: #ff0072;

    &.selected,
    &.selected:hover {
      box-shadow: 0 0 0 0.5px #ff0072;
    }

    .react-flow__handle {
      background: #ff0072;
    }
  }

  .react-flow__nodesselection-rect {
    background: rgba(0, 89, 220, 0.08);
    border: 1px dotted rgba(0, 89, 220, 0.8);
  }

  .react-flow__handle {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #555;
    border: 1px solid white;
    border-radius: 100%;

    &.connectable {
      cursor: crosshair;
    }
  }

  .react-flow__minimap {
    background-color: #fff;
  }

  .react-flow__controls {
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.08);

    &-button {
      background: #fefefe;
      border-bottom: 1px solid #eee;
      box-sizing: content-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      cursor: pointer;
      user-select: none;
      padding: 5px;

      svg {
        max-width: 12px;
        max-height: 12px;
      }

      &:hover {
        background: #f4f4f4;
      }
    }
  }
`;
