import styled from 'styled-components';

export const ModuleLabel = styled.span`
  background-color: rgba(0, 61, 127, 0.36);
  border-radius: 4px;
  padding: 3px 10px;
  font-size: 0.8em;
  text-transform: uppercase;
`;

export const ModuleLabelWrapper = styled.div`
  margin-top: 5px;
`;
