import { PayloadAction, Update } from '@reduxjs/toolkit';
import { Node as FlowNode, NodeChange as FlowNodeChange } from 'reactflow';
import { Layouts } from 'react-grid-layout';
import { Channel } from 'redux-saga';
import {
  actionChannel,
  all,
  call,
  getContext,
  put,
  SagaReturnType,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import { DataManager } from '@OrigamiEnergyLtd/react-ui-components';
import { MetaData } from '@OrigamiEnergyLtd/react-ui-components';
import {
  AliasedDataInput,
  Config,
  ModuleDTO,
  PublishModuleBody,
} from '@OrigamiEnergyLtd/ui-node-services';
import cloneDeep from 'lodash/cloneDeep';
import { IDashboardApiClient } from '../ApiClient';
import { processDataPaths } from '../components/ConfigEditor/processDataPaths';
import { renameDataOutputs } from '../components/ConfigEditor/renameDataOutputs';
import dashboardSlice, {
  Dashboard,
  ExportedDashboard,
} from '../store/dashboardSlice';
import dataLogSlice from '../store/dataLogSlice';
import flowSlice, {
  FlowPosition,
  FlowState,
  getFlowPositions,
} from '../store/flowSlice';
import { moduleSlice, PublishModuleActionPayload } from '../store/moduleSlice';
import {
  activeDashboardIdSelector,
  activeDashboardLayoutSelector,
  activeDashboardSelector,
  allWidgetByIdSelector,
  allWidgetSelector,
  betaModeSelector,
  configEditorConfigSelector,
  configEditorUnsavedChangesSelector,
  configEditorWidgetIdSelector,
  flowPositionByIdSelector,
  flowSelector,
  maxHeightGridSelector,
  organisationsSelector,
  showFlowSelector,
  widgetById,
  widgetComponontUrlSelector,
  widgetMetadataSelector,
} from '../store/selectors';
import widgetSlice, {
  AddWidgetPayload,
  DuplicateWidgetPayload,
  UpdateLayoutsRequestPayload,
  Widget,
  WidgetConfigPayload,
} from '../store/widgetSlice';
import { layoutToLayoutDTO, widgetToWidgetDTO } from '../util/conversionDTO';
import { exportDashboardToJson } from '../util/exportDashboard';
import {
  distinct,
  elementsToLinks,
  layoutsToElements,
  widgetsToElements,
} from '../util/flowGraph';
import { createGraphLayout } from '../util/flowGraphLayout';
import relations from './relations';
import routes from './routes';
import configEditorSlice, {
  ConfigEndEditAction,
  OpenConfigEditorRequestPayload,
  Organisation,
  SubmitConfigEditorPayload,
} from '../store/configEditorSlice';
import isEqual from 'lodash/isEqual';
import { ModuleDataInput } from '@OrigamiEnergyLtd/ui-node-services';
import preferenceSlice from '../store/preferenceSlice';
import { ThemeName } from '@OrigamiEnergyLtd/design-tokens';

const delay = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export function* addDashboard({ payload: label }: PayloadAction<string>) {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;
  const { dashboard, widgets, flow } = yield call(apiClient.addDashboard, {
    label,
  });

  yield put(dashboardSlice.actions.addDashboard(dashboard));
  yield put(widgetSlice.actions.setWidgets(widgets));
  yield put(flowSlice.actions.setFlow(flow));
}

export function* importDashboard({
  payload: {
    widgets: importedWidgets = [],
    flow: importedFlow = [],
    dashboard: importedDashboard,
  },
}: PayloadAction<ExportedDashboard>) {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;
  const { dashboard, widgets, flow } = yield call(
    apiClient.addDashboard,
    importedDashboard,
    importedWidgets,
    importedFlow,
  );

  yield put(dashboardSlice.actions.addDashboard(dashboard));
  yield put(widgetSlice.actions.setWidgets(widgets));
  yield put(flowSlice.actions.setFlow(flow));
}

export function* updateDashboard({
  payload,
}: PayloadAction<Update<Dashboard>>) {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;

  yield call(apiClient.updateDashboard, payload.id as string, payload.changes);
  yield put(dashboardSlice.actions.updateDashboard(payload));

  if (payload.changes.layout) {
    yield createFlow();
  }
}

export function* removeDashboard({
  payload: dashboardId,
}: PayloadAction<string>) {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;
  yield call(apiClient.deleteDashboard, dashboardId);
  yield put(dashboardSlice.actions.removeDashboard(dashboardId));
}

export function* addWidget({
  payload: { dashboardId, type, initialConfig },
}: PayloadAction<AddWidgetPayload>) {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;

  let config: Config | undefined;

  if (initialConfig) {
    const metaData: MetaData | undefined = yield selectOrGetMetadata(type);

    config = metaData?.dataPaths
      ? processDataPaths(initialConfig, metaData.dataPaths)
      : initialConfig;
  }

  const maxHeightGrid: number = yield select(maxHeightGridSelector);

  const widget: Widget = yield call(
    apiClient.addWidget,
    dashboardId,
    type,
    config,
    maxHeightGrid,
  );

  yield put(widgetSlice.actions.addWidget(widget));
  yield createFlow();

  yield highlightWidget(widget.id);
}

export function* openNewConfigIfSafe(
  action: PayloadAction<OpenConfigEditorRequestPayload>,
) {
  const currentConfigWidget: string | undefined = yield select(
    configEditorWidgetIdSelector,
  );
  if (currentConfigWidget === action.payload.widgetId) {
    return;
  }
  const showConfigWarning: boolean = yield select(
    configEditorUnsavedChangesSelector,
  );
  if (showConfigWarning && action.payload.widgetId) {
    yield put(
      configEditorSlice.actions.showUnsavedChangesWarning({
        action: ConfigEndEditAction.NEW_CONFIG,
        destinationId: action.payload.widgetId,
      }),
    );
    return;
  }
  yield put(configEditorSlice.actions.openConfig(action.payload));
}

export function* closeRequest(action: PayloadAction<undefined>) {
  const showConfigWarning: boolean = yield select(
    configEditorUnsavedChangesSelector,
  );
  if (showConfigWarning) {
    yield put(
      configEditorSlice.actions.showUnsavedChangesWarning({
        action: ConfigEndEditAction.CLOSE,
      }),
    );
    return;
  }
  yield put(configEditorSlice.actions.close());
}

export function* discardRequest(action: PayloadAction<undefined>) {
  const showConfigWarning: boolean = yield select(
    configEditorUnsavedChangesSelector,
  );
  if (showConfigWarning) {
    yield put(
      configEditorSlice.actions.showUnsavedChangesWarning({
        action: ConfigEndEditAction.DISCARD,
      }),
    );
    return;
  }
}

export function* selectOrGetMetadata(widgetType: string) {
  let metaData = (yield select(widgetMetadataSelector, widgetType)) as
    | MetaData
    | undefined;

  if (metaData === undefined) {
    const componontUrl = (yield select(
      widgetComponontUrlSelector,
      widgetType,
    )) as string;

    if (!componontUrl) return;

    const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;

    metaData = (yield call(
      apiClient.getMetaData,
      `${componontUrl}/metadata.json`,
    )) as MetaData;

    if (metaData) {
      yield put(
        configEditorSlice.actions.setMetadata({ widgetType, metaData }),
      );
    }
  }

  return metaData;
}

export function* loadConfigModule() {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;
  const config = (yield select(configEditorConfigSelector)) as unknown as any;

  let module: ModuleDTO | undefined;

  if (config?.moduleId) {
    module = (yield call(apiClient.getModule, config.moduleId)) as ModuleDTO;
  }

  yield put(configEditorSlice.actions.setModule(module));
}

export function* openConfigEditor(
  action: PayloadAction<OpenConfigEditorRequestPayload>,
) {
  const widget: Widget = yield select(widgetById, action.payload.widgetId);
  const metaData: MetaData | undefined = yield selectOrGetMetadata(widget.type);

  if (metaData === undefined) return;

  yield put(
    configEditorSlice.actions.open({
      widgetId: widget.id,
    }),
  );
}

export function* submitConfig({
  payload: { config, dataPaths },
}: PayloadAction<SubmitConfigEditorPayload>) {
  const widgetId: string = yield select(configEditorWidgetIdSelector);
  const processedConfig = dataPaths
    ? processDataPaths(config, dataPaths)
    : config;
  yield put(
    widgetSlice.actions.updateConfigRequest({
      id: widgetId,
      config: processedConfig,
      persist: true,
    }),
  );
  yield put(configEditorSlice.actions.close());
}

export function* updateLayouts({
  payload: { layouts },
}: PayloadAction<UpdateLayoutsRequestPayload>) {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;

  if (Object.keys(layouts).length === 0) return; // no changes, nothing to update

  const allWidgetById: { [key: string]: Widget } = yield select(
    allWidgetByIdSelector,
  );

  const layoutUpdatesMap = Object.entries(layouts)
    .flatMap(([size, _layouts]) => _layouts.map((layout) => ({ size, layout })))
    .reduce(
      (acc, { size, layout: { i: widgetId, x, y, w, h, minH } }) => {
        let layout = {
          [size]: { x, y, w, h, minH },
        };

        if (acc[widgetId]?.layout) {
          layout = { ...layout, ...acc[widgetId].layout };
        }

        return { ...acc, [widgetId]: { layout, widgetId } };
      },
      {} as { [key: string]: any },
    );

  const layoutUpdates = Object.values(layoutUpdatesMap).filter((layout) => {
    if (
      allWidgetById[layout.widgetId]?.layouts?.lg?.[0] !== undefined &&
      allWidgetById[layout.widgetId]?.layouts?.sm?.[0] !== undefined
    ) {
      const old = {
        lg: layoutToLayoutDTO(allWidgetById[layout.widgetId].layouts.lg[0]),
        sm: layoutToLayoutDTO(allWidgetById[layout.widgetId].layouts.sm[0]),
      };
      return !isEqual(old, layout.layout);
    }

    return true;
  });

  const widgetUpdates = Object.entries(layouts)
    .flatMap(([size, _layouts]) => _layouts.map((layout) => ({ size, layout })))
    .reduce(
      (acc, { size, layout }) => {
        const widgetChanges: Layouts = acc[layout.i] || ({} as Layouts);
        widgetChanges[size] = [layout];
        return { ...acc, [layout.i]: widgetChanges };
      },
      {} as { [widgetId: string]: Layouts },
    );

  const updates: Update<Widget>[] = Object.entries(widgetUpdates).map(
    ([id, layouts]) => ({
      id,
      changes: {
        layouts,
      },
    }),
  );

  yield put(widgetSlice.actions.updateWidgets(updates));

  const dashboardId: string = yield select(activeDashboardIdSelector);

  if (layoutUpdates.length > 0) {
    yield call(apiClient.updateDashboardLayout, {
      id: dashboardId,
      layouts: layoutUpdates,
    });
  }
}

export function* updateConfig({
  payload: { id, config, persist },
}: PayloadAction<WidgetConfigPayload>) {
  const baseWidget: Widget = yield select(widgetById, id);
  if (persist) {
    const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;

    yield call(apiClient.updateWidget, { ...baseWidget, config });
  }

  yield put(widgetSlice.actions.updateWidget({ ...baseWidget, config }));
  yield createFlow();
}

const duplicateString: (value: string, widgets: Widget[]) => string = (
  value = '',
  widgets: Widget[],
) => {
  const newValue = `${value}-copy`;

  for (let i = 0; i < widgets.length; i++) {
    if (newValue === widgets[i].config?.header) {
      return duplicateString(newValue, widgets);
    }
  }

  return newValue;
};

function* highlightWidget(widgetId: string) {
  yield put(widgetSlice.actions.highlightWidget(widgetId));
  yield call(delay, 1500);
  yield put(widgetSlice.actions.highlightWidget(''));
}

export function* duplicateWidget({
  payload: id,
}: PayloadAction<DuplicateWidgetPayload>) {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;

  const widget: Widget = yield select(widgetById, id);
  const allWidgets: Widget[] = yield select(allWidgetSelector);
  const metaData: MetaData | undefined = yield selectOrGetMetadata(widget.type);

  let config = widget.config ? cloneDeep(widget.config) : {};

  if (metaData?.dataPaths) {
    config = renameDataOutputs(config, metaData.dataPaths);
  }

  if (config.header) {
    config.header = duplicateString(config.header, allWidgets);
  }

  const duplicatedWidget: Widget = yield call(apiClient.duplicateWidget, {
    dashboardId: widget.dashboardId,
    layouts: widget.layouts,
    type: widget.type,
    config,
  });

  yield put(widgetSlice.actions.addWidget(duplicatedWidget));

  const flowPositions: { [key: string]: FlowPosition } = yield select(
    flowPositionByIdSelector,
  );

  const newPosition = {
    ...(flowPositions[id] || { x: 0, y: 0 }),
    widgetId: duplicatedWidget.id,
  };

  newPosition.x += 50;
  newPosition.y += 50;

  yield updateFlowPositions({
    ...flowPositions,
    [duplicatedWidget.id]: newPosition,
  });

  yield highlightWidget(duplicatedWidget.id);
}

export function* removeWidget(action: PayloadAction<string>) {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;
  const widget: Widget = yield select(widgetById, action.payload);

  yield call(apiClient.removeWidget, widget.dashboardId, widget.id);
  yield put(widgetSlice.actions.removeWidget(widget.id));
  yield createFlow();
}

export function* logout() {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;
  yield call(apiClient.logout.bind(apiClient));
  window.location.reload();
}

function* monitorChange<T>(
  selector: (state: any, ...args: any[]) => T,
  onChange: (newValue: T, previousValue: T | undefined) => void,
) {
  let previousValue: T | undefined = undefined;
  const monitor = function* (action: PayloadAction) {
    const nextValue: T = yield select(selector);
    if (nextValue !== previousValue) {
      previousValue = nextValue;
      yield onChange(nextValue, previousValue);
    }
  };
  yield takeEvery('*', monitor);
}

export function* cloneDashboard(action: PayloadAction<string>) {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;
  const { dashboard, widgets, flow } = yield call(
    apiClient.cloneDashboard,
    action.payload,
  );

  yield put(dashboardSlice.actions.addDashboard(dashboard));
  yield put(widgetSlice.actions.setWidgets(widgets));
  yield put(flowSlice.actions.setFlow(flow));
}

export function* changeDashboard(newDashboardId: string | undefined) {
  yield put(dashboardSlice.actions.setDashboardReady(false));
  const dataManager = (yield getContext('dataManager')) as DataManager;
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;

  yield put(dataLogSlice.actions.reset());

  const {
    dashboard,
    widgets = [],
    flow = [],
  }: ExportedDashboard = newDashboardId
    ? yield call(apiClient.getDashboard, newDashboardId)
    : {};

  yield put(widgetSlice.actions.setWidgets(widgets));
  yield put(flowSlice.actions.setFlow(flow));

  const dataOutputs: string[] = widgets.flatMap((w) => {
    const out: string[] = [
      ...(w.config?.dataOutputs || ([] as string[])),
      w.config?.dataOutput,
    ]
      .filter(distinct)
      .filter((dataOutput): dataOutput is string => dataOutput !== undefined);

    return out;
  });
  yield call(dataManager.reset.bind(dataManager), dataOutputs);

  if (dashboard) yield put(dashboardSlice.actions.setDashboardReady(true));
}

export function* exportDashboard(action: PayloadAction<string>) {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;
  const { dashboard, widgets, flow } = (yield call(
    apiClient.getDashboard,
    action.payload,
  )) as ExportedDashboard;
  yield call(
    exportDashboardToJson,
    dashboard,
    widgets?.map(widgetToWidgetDTO) || [],
    flow,
  );
}

export function* saveFlowOnNodeChange(action: PayloadAction<FlowNodeChange[]>) {
  const shouldSave = action.payload.reduce(
    (acc, node) => acc || (node.type === 'position' && !node.dragging),
    false,
  );

  if (shouldSave) {
    yield saveFlow();
  }
}

function* saveFlow() {
  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;

  const dashboardId: string = yield select(activeDashboardIdSelector);
  const { positions }: FlowState = yield select(flowSelector);

  yield call(apiClient.updateFlowCollections, dashboardId, positions);
}

function* updateFlowPositions(positions: { [key: string]: FlowPosition } = {}) {
  const { nodes, edges } = yield calculateFlowGraph(positions);
  yield put(
    flowSlice.actions.setGraph({
      nodes,
      edges,
      positions: nodes.map(getFlowPositions),
    }),
  );
  yield saveFlow();
}

export function* calculateFlowGraph(
  positions: { [key: string]: FlowPosition } = {},
) {
  const widgets: Widget[] = yield select(allWidgetSelector);
  const layout: string = yield select(activeDashboardLayoutSelector);

  const widgetNodes = widgetsToElements(widgets, positions);
  const layoutNodes = layoutsToElements(layout, positions);

  const isBeta: boolean = yield select(betaModeSelector);

  const allNodes = isBeta ? [...widgetNodes, ...layoutNodes] : [...widgetNodes];

  const edges = elementsToLinks(allNodes);
  const nodes: FlowNode[] = yield call(createGraphLayout, allNodes, edges);

  return { nodes, edges };
}

export function* createFlow() {
  const flowOpen: boolean = yield select(showFlowSelector);

  if (flowOpen) {
    const positions: { [key: string]: FlowPosition } = yield select(
      flowPositionByIdSelector,
    );

    const { nodes, edges } = yield calculateFlowGraph(positions);

    yield put(flowSlice.actions.setGraph({ nodes, edges }));
  }
}

export function* resetFlow() {
  const { nodes, edges } = yield calculateFlowGraph();
  const positions = nodes.map(getFlowPositions);

  yield put(flowSlice.actions.setGraph({ nodes, edges, positions }));

  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;
  const dashboardId: string = yield select(activeDashboardIdSelector);

  yield call(apiClient.updateFlowCollections, dashboardId, positions);
}

export function* publishModuleSaga({
  payload: { moduleInfo },
}: PayloadAction<PublishModuleActionPayload>) {
  const activeDashboard: ReturnType<typeof activeDashboardSelector> =
    yield select(activeDashboardSelector);

  if (!activeDashboard) {
    const putAction = moduleSlice.actions.moduleUpdateReturned({
      isSuccess: false,
      error: 'No active dashboard selected',
    });
    yield put(putAction);
    return;
  }

  const excludedWidgetIds = moduleInfo.config.excludedWidgetIds || [];

  const allWidgets: ReturnType<typeof allWidgetSelector> = yield select(
    allWidgetSelector,
  );

  const allWidgetDTOs = allWidgets
    .map(widgetToWidgetDTO)
    .filter((widgetDTO) => !excludedWidgetIds.includes(widgetDTO.id));

  const { outputs: internalDataOutputs, inputs: internalDatasources } =
    allWidgetDTOs.reduce(
      ({ outputs, inputs }, { config }) => {
        config?.dataOutputs?.forEach((d: string) => d && outputs.add(d));
        config?.datasources?.forEach((d: string) => d && inputs.add(d));

        return { outputs, inputs };
      },
      { outputs: new Set(), inputs: new Set() },
    );

  const {
    config: { dataOutputs, datasources, ...moduleConfigOther },
    ...moduleInfoOther
  } = moduleInfo;

  const publishModuleBodyDTO: PublishModuleBody = {
    ...moduleInfoOther,
    config: {
      ...moduleConfigOther,
      exposedDataOutputs: dataOutputs.map(({ datasource, description }) => ({
        datasource: datasource,
        description: description,
      })),
      consumedDatasources: datasources.map(
        ({ alias, description, configType, defaultValueOptions }) => {
          const cds: ModuleDataInput = {
            datasource: alias,
            configType: configType,
          };
          // Only set description / defaultValueOptions if present to prevent sending null / undefined values to api (which could cause 4XX errors)
          if (description) {
            cds.description = description;
          }
          if (defaultValueOptions) {
            cds.defaultValueOptions = defaultValueOptions;
          }
          return cds;
        },
      ),
      internalDataOutputs: [...internalDataOutputs] as string[],
      internalDatasources: [...internalDatasources] as string[],
      initialModuleWidgetConfig: {
        outputDatasources: dataOutputs
          .filter(({ alias }) => !!alias)
          .map(({ datasource, alias }) => ({ datasource, alias })),
        inputDatasources: datasources
          .filter(({ datasource }) => !!datasource)
          .map(({ datasource, alias, defaultValueOptions }) => {
            const ds: AliasedDataInput = {
              datasource,
              alias,
            };
            // Only set default if present to prevent sending null value to api (which would cause 4XX errors)
            const defaultValue = defaultValueOptions?.defaultValue;
            const hasDefaultValue =
              defaultValue !== undefined && defaultValue !== null;
            if (hasDefaultValue) {
              ds.defaultValue = defaultValue;
            }
            return ds;
          }),
      },
    },
    dashboard: {
      id: activeDashboard.id,
      label: activeDashboard.label,
      widgets: allWidgetDTOs,
    },
  };

  const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;

  try {
    const apiCallResult: SagaReturnType<typeof apiClient.publishModule> =
      yield call(apiClient.publishModule, publishModuleBodyDTO);

    const updatedDashboard: ExportedDashboard = yield call(
      apiClient.getDashboard,
      activeDashboard.id,
    );

    const allModules: ModuleDTO[] = yield call(apiClient.getModules);
    yield put(moduleSlice.actions.refreshModules(allModules));
    yield put(
      dashboardSlice.actions.updateDashboard({
        id: activeDashboard.id,
        changes: updatedDashboard.dashboard,
      }),
    );

    const updateAction = moduleSlice.actions.moduleUpdateReturned({
      isSuccess: true,
      dto: apiCallResult,
    });
    yield put(updateAction);

    return;
  } catch (e) {
    const updateAction = moduleSlice.actions.moduleUpdateReturned({
      isSuccess: false,
      error: 'Error publishing module',
    });
    console.error('Error publishing module', e);

    yield put(updateAction);
    return;
  }
}

export function* onRequestModuleConfigDrawerOpen() {
  const organisations: Organisation[] = yield select(organisationsSelector);
  yield put(moduleSlice.actions.onDrawerOpen());
  if (organisations.length === 0) {
    const apiClient = (yield getContext('apiClient')) as IDashboardApiClient;
    const organisations: Organisation[] = yield call(
      apiClient.getOrganisations,
    );
    yield put(configEditorSlice.actions.setOrganisations(organisations));
  }
}

export function* updateTheme({ payload: theme }: PayloadAction<ThemeName>) {
  window.localStorage.setItem('theme', theme as string);
  yield put(preferenceSlice.actions.updateTheme(theme));
}

export function* previewAndRootSagas() {
  yield takeLeading(
    (yield actionChannel(
      widgetSlice.actions.updateConfigRequest,
    )) as Channel<unknown>,
    updateConfig,
  );
  yield takeEvery(
    configEditorSlice.actions.openConfigRequest,
    openNewConfigIfSafe,
  );
  yield takeEvery(configEditorSlice.actions.openConfig, openConfigEditor);
  yield takeEvery(configEditorSlice.actions.submitConfigEditor, submitConfig);
  yield takeEvery(configEditorSlice.actions.closeRequest, closeRequest);
  yield takeEvery(configEditorSlice.actions.discardRequest, discardRequest);
  yield takeLatest(
    configEditorSlice.actions.loadConfigModule,
    loadConfigModule,
  );
}

export function* rootDashboardSagas() {
  yield takeEvery(dashboardSlice.actions.addDashboardRequest, addDashboard);
  yield takeEvery(dashboardSlice.actions.cloneDashboardRequest, cloneDashboard);

  yield takeEvery(
    dashboardSlice.actions.removeDashboardRequest,
    removeDashboard,
  );
  yield takeEvery(
    dashboardSlice.actions.importDashboardRequest,
    importDashboard,
  );
  yield takeEvery(
    dashboardSlice.actions.updateDashboardRequest,
    updateDashboard,
  );

  yield takeEvery(widgetSlice.actions.addWidgetRequest, addWidget);
  yield takeEvery(widgetSlice.actions.duplicateWidgetRequest, duplicateWidget);
  yield takeEvery(widgetSlice.actions.removeWidgetRequest, removeWidget);

  yield takeLeading(
    (yield actionChannel(
      widgetSlice.actions.updateLayoutsRequest,
    )) as Channel<unknown>,
    updateLayouts,
  );

  yield takeEvery(dashboardSlice.actions.logout, logout);
  yield takeEvery(dashboardSlice.actions.exportDashboard, exportDashboard);

  yield takeEvery(flowSlice.actions.setFlow, createFlow);
  yield takeEvery(flowSlice.actions.setShow, createFlow);

  yield takeEvery(flowSlice.actions.onNodesChange, saveFlowOnNodeChange);

  yield takeEvery(flowSlice.actions.resetPositions, resetFlow);

  yield takeEvery(
    moduleSlice.actions.requestDrawerOpen,
    onRequestModuleConfigDrawerOpen,
  );

  yield takeEvery(moduleSlice.actions.publishModule, publishModuleSaga);

  yield takeEvery(preferenceSlice.actions.updateThemeRequest, updateTheme);

  yield monitorChange(activeDashboardIdSelector, changeDashboard);
}

export function* rootSagas() {
  yield all([
    rootDashboardSagas(),
    previewAndRootSagas(),
    relations(),
    routes(),
  ]);
}

export function* previewSagas() {
  yield all([previewAndRootSagas()]);
}
