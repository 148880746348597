import { select } from 'redux-saga/effects';

import dashboardSlice from '../store/dashboardSlice';
import widgetSlice, { Widget } from '../store/widgetSlice';

import { put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { widgetsByDashboardIdSelector } from '../store/selectors';

export function* removeWidgetsByDashboardId(action: PayloadAction<string>) {
  const widgets: Widget[] = yield select(
    widgetsByDashboardIdSelector(action.payload),
  );
  const ids = Object.values(widgets).map(({ id }) => id);
  yield put(widgetSlice.actions.removeWidgets(ids));
}

export default function* relationSagas() {
  yield takeEvery(
    dashboardSlice.actions.removeDashboard,
    removeWidgetsByDashboardId,
  );
}
