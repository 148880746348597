import Editor, { useMonaco } from '@monaco-editor/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Button } from '@OrigamiEnergyLtd/react-ui-components';
import {
  EDITOR_JSON_CONFIG_CONTAINER,
  EDITOR_JSON_CONFIG_SAVE,
} from '../../util/testids';
import { useSelector } from 'react-redux';
import { currentThemeSelector } from '../../store/selectors';

type ConfigEditorProps = {
  config: any;
  JSONSchema?: any;
  onChange: (config: any) => void;
  onSave: (config: any) => void;
  onValidation?: (isValid: boolean) => void;
  showSubmit?: boolean;
};

export const ConfigJsonEditor: FC<ConfigEditorProps> = ({
  config,
  JSONSchema,
  onChange,
  onSave,
  onValidation,
  showSubmit = true,
}) => {
  // Originally value was sent to the editor via the defaultValue prop.
  //
  // This caused an issue when we needed to be able to change the widget config being edited without closing the
  // editor.
  //
  // Originally the fix was going to be to use a "key" prop to cause monico to un-mount/mount. However this didn't work
  // as the most sensible key to be used here is the id of the widget whose config is to be edited, and due to the way
  // our state store is set up this caused the first render on remount to be done with the config from the old widget
  // (which, as this is being passed to defaultConfig, then persisted despite the config prop updating).
  //
  // On the basis that this indicates that an edge based approach like this is going to be fragile, it was decided instead
  // to go with a standard React controlled-input pattern instead (AKA the value inside the monaco editor
  // becomes state inside the controlling component, and then passed down in inside the `value` prop of the underlying
  // element).
  //
  // If this is found to affect performance then an alternative if less conventional approach might be to have a
  // useEffect() or useRef() that detects a change in props.config and then uses that as a trigger to grab an instance
  // of the underlying non-react monaco editor and reset the current value imperatively.

  const [editorConfig, setEditorConfig] = useState(() =>
    JSON.stringify(config, null, 2),
  );
  useEffect(() => {
    const c = JSON.stringify(config, null, 2);
    setEditorConfig(c);
  }, [config]);

  const [markers, setMarkers] = useState<any[]>([]);
  const monaco = useMonaco();
  const themeName = useSelector(currentThemeSelector);

  const uid = useRef(Math.random() + '');

  useEffect(() => {
    if (!monaco || !JSONSchema) return;

    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: JSON.stringify(JSONSchema), // id of the first schema
          fileMatch: [uid.current], // associate with our model
          schema: JSONSchema,
        },
      ],
    });
  }, [monaco, JSONSchema]);

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(markers.length === 0 && !!editorConfig?.trim());
  }, [markers, editorConfig]);

  useEffect(() => {
    if (onValidation) onValidation(isValid);
  }, [onValidation, isValid]);

  const editor = (
    <Editor
      path={uid.current}
      theme={themeName === 'light' ? 'light' : 'vs-dark'}
      defaultLanguage="json"
      value={editorConfig}
      onChange={(value = '') => {
        setEditorConfig(value);
        try {
          onChange(JSON.parse(value));
        } catch (e) {
          // Ignore Error
        }
      }}
      onValidate={setMarkers}
    />
  );

  if (!showSubmit) {
    return editor;
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', height: '75vh' }}
      data-testid={EDITOR_JSON_CONFIG_CONTAINER}
    >
      <div style={{ flexGrow: 1 }}>{editor}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: 10,
          height: 25,
        }}
      >
        <Button
          variant="primary"
          disabled={!isValid}
          onClick={() => onSave(JSON.parse(editorConfig))}
          data-testid={EDITOR_JSON_CONFIG_SAVE}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
