/* eslint-disable no-case-declarations */
import { UiSchema } from '@rjsf/utils';
import { dynamicableToRjsf } from './dynamicableToRjsf';
import { dynamicInput } from './dynamicInput';
import {
  MetaData,
  RJSFCompatibleMetaData,
  SchemaWithDynamicable,
} from '@OrigamiEnergyLtd/react-ui-components';
import { JSONSchema7 } from 'json-schema';
import { autoEnumInput } from './autoEnumInput';
import { ModuleDataInput } from '@OrigamiEnergyLtd/ui-node-services';

export const parseMetaData = (
  metadata: MetaData,
  auxiliaryData: { [key: string]: any },
): RJSFCompatibleMetaData => {
  if (!metadata.JSONSchema.properties) {
    throw 'This schema has no properties, you must add properties to the schema to render the configuration form';
  }
  const { schema, paths } = swapRjsfComaptibleValues(
    metadata.JSONSchema,
    '',
    { dynamicPaths: [], autoEnumPaths: [] },
    auxiliaryData,
  );
  const schemaWithVersion = addRjsfVersionField(schema, metadata.version);
  const UISchema = updateObjectFieldTemplate(metadata.UISchema, paths);

  return { JSONSchema: schemaWithVersion, UISchema };
};

const addRjsfVersionField = (schema: JSONSchema7, version = 1): JSONSchema7 => {
  if (schema.properties?.version) {
    throw 'version is a reserved property, please use an alternative name for this field.';
  }
  schema.properties!.version = {
    type: 'number',
    default: version,
  };
  return schema;
};

const swapRjsfComaptibleValues = (
  schema: SchemaWithDynamicable | JSONSchema7,
  pathPrefix = '',
  paths: { dynamicPaths: string[]; autoEnumPaths: string[] },
  auxiliaryData: { [key: string]: any } = {},
): {
  schema: JSONSchema7;
  paths: { dynamicPaths: string[]; autoEnumPaths: string[] };
} => {
  const newSchema = JSON.parse(JSON.stringify({ ...schema }));
  if (newSchema.properties !== undefined) {
    Object.keys(newSchema.properties).forEach((key: string) => {
      switch (newSchema.properties[key].type) {
        case 'object':
          const { schema: objectSchema } = swapRjsfComaptibleValues(
            newSchema.properties[key],
            `${pathPrefix}${key}.`,
            paths,
            auxiliaryData,
          );
          newSchema.properties[key] = objectSchema;
          break;

        case 'array':
          const { schema: arraySchema } = swapRjsfComaptibleValues(
            newSchema.properties[key].items,
            `${pathPrefix}${key}.items.`,
            paths,
            auxiliaryData,
          );
          newSchema.properties[key] = {
            ...newSchema.properties[key],
            items: arraySchema,
          };
          break;

        case 'dynamicable':
          newSchema.properties[key] = dynamicableToRjsf(
            newSchema.properties[key],
          );
          // recurse into static property of dynamicable schema to ensure any dynamic values in there are converted
          const { schema: dynamicablestaticControl } = swapRjsfComaptibleValues(
            newSchema.properties[key].dependencies.dynamic.oneOf[1],
            `${pathPrefix}${key}.`,
            paths,
            auxiliaryData,
          );
          newSchema.properties[key].dependencies.dynamic.oneOf[1] =
            dynamicablestaticControl;
          paths.dynamicPaths.push(`${pathPrefix}${key}`);
          break;

        case 'autofillenum':
          let enumData = [''];
          let enumDescriptions = {};

          if (
            newSchema.properties[key].datakey &&
            auxiliaryData[newSchema.properties[key].datakey]?.length
          ) {
            enumData = auxiliaryData[newSchema.properties[key].datakey].map(
              ({ value }: { value: string }) => value,
            );

            enumDescriptions = auxiliaryData[
              newSchema.properties[key].datakey
            ].reduce(
              (
                acc: { [key: string]: string },
                { value, description }: { value: string; description?: string },
              ) => {
                return { ...acc, [value]: description ?? '' };
              },
              {},
            );
          }

          newSchema.properties[key] = {
            title: newSchema.properties[key].title,
            type: 'string',
            enum: enumData,
            enumDescriptions,
          };

          paths.autoEnumPaths.push(`${pathPrefix}${key}`);
          break;

        case 'stringvalidate':
          newSchema.properties[key] = {
            title: newSchema.properties[key].title,
            type: 'string',
          };

          break;

        case 'datadescription':
          const { datakey: descriptionDataKey, fieldtype } =
            newSchema.properties[key];

          newSchema.properties[key] = {
            title: newSchema.properties[key].title,
            type: fieldtype,
          };

          const descriptionData = auxiliaryData[descriptionDataKey];
          if (descriptionData) {
            newSchema.properties[key]['description'] = descriptionData;
          }

          break;

        case 'moduleInput':
          const getSchemaForDefaultInput = (): JSONSchema7[] => {
            return (
              auxiliaryData.consumedDatasources?.map((cd: ModuleDataInput) => {
                const dependencySchema: any = {
                  properties: {
                    // eslint-disable-next-line prettier/prettier
                    alias: { const: cd.datasource },
                  },
                };

                const getDependencySchemaDefaultValueField = () => {
                  return dependencySchema.properties['defaultValue'] ?? {};
                };

                // TODO: Does this need further tightening up?
                // Do we need to worry about edge cases where `defaultValueOptions` is present but `configType` is "datasource"?
                if (cd.configType === 'user' && cd.defaultValueOptions) {
                  dependencySchema.properties['defaultValue'] = {
                    ...getDependencySchemaDefaultValueField(),
                    type: 'string',
                    default: cd.defaultValueOptions.defaultValue,
                    title: 'Default value',
                  };

                  if (
                    cd.defaultValueOptions.inputType === 'dropdown' &&
                    cd.defaultValueOptions.isMultiSelect
                  ) {
                    dependencySchema.properties['defaultValue'] = {
                      ...getDependencySchemaDefaultValueField(),
                      type: 'array',
                      minItems: 1,
                      items: {
                        type: cd.defaultValueOptions.valueType,
                        enum: cd.defaultValueOptions.enumOptions,
                      },
                    };
                  } else {
                    dependencySchema.properties['defaultValue'] = {
                      ...getDependencySchemaDefaultValueField(),
                      type: cd.defaultValueOptions.valueType,
                    };
                    if (cd.defaultValueOptions.inputType === 'dropdown') {
                      dependencySchema.properties['defaultValue'] = {
                        ...getDependencySchemaDefaultValueField(),
                        enum: cd.defaultValueOptions.enumOptions ?? [],
                      };
                    }
                  }
                }
                return dependencySchema;
              }) || []
            );
          };

          const inputDsSchema: any = {
            type: 'array',
            title: 'Input Datasources',
            items: {
              ...swapRjsfComaptibleValues(
                {
                  type: 'object',
                  required: ['datasource', 'alias'],
                  properties: {
                    alias: {
                      type: 'autofillenum',
                      datakey: 'consumedDatasources',
                      title: 'Data Input',
                    },
                    datasource: {
                      type: 'string',
                      title: 'Datasource',
                    },
                  },
                } as any,
                `${pathPrefix}${key}.items.`,
                paths,
                auxiliaryData,
              ).schema,
            },
          };

          const depSchemas = getSchemaForDefaultInput();

          if (depSchemas && depSchemas.length !== 0) {
            inputDsSchema.items!['dependencies'] = {
              alias: {
                oneOf: depSchemas,
              },
            };
          }

          const { schema: arrayItemSchema } = swapRjsfComaptibleValues(
            inputDsSchema as unknown as JSONSchema7,
            `${pathPrefix}${key}.`,
            paths,
            auxiliaryData,
          );

          newSchema.properties[key] = arrayItemSchema;
          break;
      }
    });
  }

  return { schema: newSchema, paths };
};

// This takes the UISchema and an array of paths to dynamicable fields that need to be updated to use the the dynamic input control
const updateObjectFieldTemplate = (
  UISchema: UiSchema,
  {
    dynamicPaths,
    autoEnumPaths,
  }: { dynamicPaths: string[]; autoEnumPaths: string[] },
) => {
  const newSchema = JSON.parse(JSON.stringify(UISchema));

  dynamicPaths.forEach((path) => {
    const parts = path.split('.');
    let currentDefinition = newSchema;
    parts.forEach((part, i) => {
      if (!currentDefinition[part]) {
        currentDefinition[part] = {};
      }
      if (i === parts.length - 1) {
        currentDefinition[part]['ui:ObjectFieldTemplate'] = dynamicInput;
      }
      currentDefinition = currentDefinition[part];
    });
  });

  autoEnumPaths.forEach((path) => {
    const parts = path.split('.');
    let currentDefinition = newSchema;
    parts.forEach((part, i) => {
      if (!currentDefinition[part]) {
        currentDefinition[part] = {};
      }
      if (i === parts.length - 1) {
        currentDefinition[part]['ui:FieldTemplate'] = autoEnumInput;
      }
      currentDefinition = currentDefinition[part];
    });
  });

  newSchema.version = {
    'ui:widget': 'hidden',
  };

  return newSchema;
};
