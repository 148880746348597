import React, { FC } from 'react';
import {
  IconButton,
  Tooltip,
  ArrowUpIcon,
  ArrowDownIcon,
} from '@OrigamiEnergyLtd/react-ui-components';
import { DATA_TOOLS_TOGGLE_BUTTON } from '../../../util/testids';

export type DataToolsBar = {
  show: boolean;
  setShow: (show: boolean) => void;
  title: string;
  children?: React.ReactNode | undefined;
};

const BUTTON_STLYE = { width: 30, height: 30, margin: 5 };

const DataToolsBar: FC<DataToolsBar> = ({ show, setShow, title, children }) => (
  <div
    className="DataToolsBar"
    style={{ display: 'flex', justifyContent: 'space-between' }}
  >
    <div style={{ display: 'flex', alignItems: 'center', height: 48 }}>
      <div style={{ position: 'absolute', left: '50%', height: 'auto' }}>
        <Tooltip title={`${show ? 'Minimise' : 'Expand'} ${title}`}>
          <IconButton
            className="DataToolsBar__Toggle"
            data-testid={DATA_TOOLS_TOGGLE_BUTTON}
            size="small"
            style={BUTTON_STLYE}
            onClick={() => setShow(!show)}
          >
            {show ? <ArrowDownIcon size={20} /> : <ArrowUpIcon size={20} />}
          </IconButton>
        </Tooltip>
      </div>
      {Boolean(title) && (
        <div style={{ marginLeft: 15, marginRight: 15 }}>{title}</div>
      )}
      {children}
    </div>
  </div>
);

export default DataToolsBar;
