import React, { FC } from 'react';
import { useWidgetRegistry } from '../WidgetsRegistryProvider';
import { HeaderWidgetDefinition } from '../../layouts/layout';
import { WebComponentWrapper } from '@OrigamiEnergyLtd/react-ui-components';
import { SIDE_PANEL } from '../../util/testids';
import { updateUrlParam, URL_PARAM_PREFIX } from '../../route/routeUtils';
import { useDispatch } from 'react-redux';
import dataLogSlice from '../../store/dataLogSlice';

export type SidePanelComponentProps = {
  widgetDefinition: HeaderWidgetDefinition;
  visible: boolean;
};

export const SidePanelComponent: FC<SidePanelComponentProps> = ({
  widgetDefinition,
  visible,
}: SidePanelComponentProps) => {
  const widgetRegistry = useWidgetRegistry();
  const widgetRegistryItem = widgetRegistry.getItemByType(
    widgetDefinition.type,
  );

  const dispatch = useDispatch();
  return widgetRegistryItem?.webComponent ? (
    <div
      data-testid={SIDE_PANEL}
      style={{
        width: widgetDefinition.maxWidth,
        maxWidth: widgetDefinition.maxWidth,
        display: visible ? 'block' : 'none',
      }}
    >
      <div
        style={{
          height: 'calc(100vh - 20px )',
          overflowY: 'auto',
        }}
      >
        <WebComponentWrapper
          widgetId={`sidePanelWidget-${widgetDefinition.type}`}
          config={widgetDefinition.config}
          webComponentDefinition={widgetRegistryItem.webComponent}
          onUrlParamUpdate={(datasource, data) => {
            updateUrlParam(URL_PARAM_PREFIX + datasource, data);
          }}
          onReceiveData={(data, sourceWidgetId) => {
            dispatch(
              dataLogSlice.actions.onReceiveData({
                data,
                sourceWidgetId,
              }),
            );
          }}
        />
      </div>
    </div>
  ) : null;
};
