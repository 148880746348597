import React, { useCallback } from 'react';
import {
  ThemeProvider,
  CssBaseline,
} from '@OrigamiEnergyLtd/react-ui-components';
import Dashboard from './components/DashboardComponent';
import UnexpectedErrorBoundary from './components/UnexpectedErrorBoundary';
import { useStore } from 'react-redux';
import { NotificationPayload } from '@OrigamiEnergyLtd/ui-utilities';
import notificationsSlice from './store/notificationsSlice';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNotificationEventListener } from './events/useDashboardEventListeners';
import preferenceSlice from './store/preferenceSlice';
import { useImportTheme } from './util/hooks';

const App = () => {
  const store = useStore();

  const { theme, themeName } = useImportTheme();

  (window as any).toggleBeta = () => {
    store.dispatch(preferenceSlice.actions.toggleBeta());
  };

  const notificationCallback = useCallback(
    (notification: NotificationPayload) => {
      store.dispatch(
        notificationsSlice.actions.onReceiveNotification(notification),
      );
      const toastMethod =
        notification.type === 'error' ? toast.error : toast.info;

      const toastContent = (
        <>
          {notification.context ? <p>{notification.context}</p> : null}
          <p>{notification.message}</p>
        </>
      );
      toastMethod(toastContent, {
        pauseOnHover: true,
      });
    },
    [store],
  );

  useNotificationEventListener(notificationCallback);

  if (!theme) {
    return <div>Loading</div>;
  }

  return (
    <ThemeProvider theme={theme} themeName={themeName}>
      <CssBaseline />
      <UnexpectedErrorBoundary>
        <Dashboard />
        <ToastContainer
          newestOnTop={true}
          limit={10}
          position={'top-right'}
          autoClose={10000}
          hideProgressBar={false}
          closeOnClick
          theme={themeName === 'light' ? 'light' : 'dark'}
        />
      </UnexpectedErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
