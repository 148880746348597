import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { Data } from '@OrigamiEnergyLtd/ui-utilities';

export type DataLog = {
  timestamp: number;
  data: Data<string>;
};

export enum TabValues {
  DATA = 'data',
  LOG = 'log',
}

export type DataLogBaseState = {
  isLoggingEnabled: boolean;
  isVisible: boolean;
  dataDrawerOpen: boolean;
  widgetDataWidgetId: string | undefined;
  datas: DataLog[]; // only store the last n datas here to prevent performance issues
  tab: TabValues;
  eventCounts: {
    [datasource: string]: number;
  };
  widgetDatasourceMap: {
    [widgetId: string]: {
      datasources: string[];
      dataOutputs: string[];
    };
  };
};

export const STORED_DATA_LIMIT = 100;
export const DATA_CHARACTER_LIMIT = 200;

export const dataLogAdapter = createEntityAdapter<DataLog>();

export const initialState: DataLogBaseState = {
  isLoggingEnabled: false,
  isVisible: false,
  dataDrawerOpen: false,
  widgetDataWidgetId: undefined,
  tab: TabValues.DATA,
  datas: [],
  eventCounts: {},
  widgetDatasourceMap: {},
};

export type DataLogState = typeof initialState;

const strigifyDataToLimit = (data: Data<any>): string => {
  if (!data.data) return 'undefined';
  const stringified = JSON.stringify(data.data);
  if (stringified.length > DATA_CHARACTER_LIMIT) {
    return stringified.substring(0, DATA_CHARACTER_LIMIT) + '...';
  }
  return stringified;
};

type OnReceiveDataPayload = {
  data: Data<any>;
  sourceWidgetId?: string;
};

type UpdateWidgetDatasourcesPayload = {
  widgetId: string;
  datasources: string[];
};

export const dataLogSlice = createSlice({
  name: 'dataLog',
  initialState,
  reducers: {
    setIsVisible: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
    setDataDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.dataDrawerOpen = action.payload;
    },
    setWidgetDataDisplayWidgetId: (state, action: PayloadAction<string>) => {
      state.widgetDataWidgetId = action.payload;
    },
    setTab: (state, action: PayloadAction<TabValues>) => {
      state.tab = action.payload;
    },
    setLoggingEnabled: (state, action: PayloadAction<boolean>) => {
      state.isLoggingEnabled = action.payload;
    },
    onReceiveData: (state, action: PayloadAction<OnReceiveDataPayload>) => {
      if (state.isLoggingEnabled) {
        // Always add new datas to the top of the array
        if (state.datas.length === STORED_DATA_LIMIT) {
          state.datas.pop();
        }

        state.datas.unshift({
          timestamp: new Date().getTime(),
          data: {
            datasource: action.payload.data.datasource,
            data: strigifyDataToLimit(action.payload.data),
          },
        });
      }
      const datasourceCountBefore: number =
        state.eventCounts[action.payload.data.datasource] ?? 0;
      state.eventCounts[action.payload.data.datasource] =
        datasourceCountBefore + 1;

      if (action.payload.sourceWidgetId) {
        if (state.widgetDatasourceMap[action.payload.sourceWidgetId]) {
          const widgetDataOutputs =
            state.widgetDatasourceMap[action.payload.sourceWidgetId]
              .dataOutputs;
          if (!widgetDataOutputs.includes(action.payload.data.datasource)) {
            state.widgetDatasourceMap[
              action.payload.sourceWidgetId
            ].dataOutputs.push(action.payload.data.datasource);
          }
        } else {
          state.widgetDatasourceMap = {
            ...state.widgetDatasourceMap,
            [action.payload.sourceWidgetId]: {
              datasources: [],
              dataOutputs: [action.payload.data.datasource],
            },
          };
        }
      }
    },
    setWidgetDatasources: (
      state,
      action: PayloadAction<UpdateWidgetDatasourcesPayload>,
    ) => {
      if (state.widgetDatasourceMap[action.payload.widgetId]) {
        state.widgetDatasourceMap[action.payload.widgetId].datasources =
          action.payload.datasources;
      } else {
        state.widgetDatasourceMap = {
          ...state.widgetDatasourceMap,
          [action.payload.widgetId]: {
            datasources: action.payload.datasources,
            dataOutputs: [],
          },
        };
      }
    },
    reset: (state) => {
      state.isLoggingEnabled = false;
      state.datas = [];
      state.eventCounts = {};
      // TODO: This probably needs to trigger something to get the known datasources and put them back in the map
      state.widgetDatasourceMap = {};
    },
  },
});

export default dataLogSlice;
