import { Node, Edge } from 'reactflow';
import { Widget } from '../store/widgetSlice';
import { FlowPosition } from '../store/flowSlice';
import { DashboardLayout } from '../layouts/layout';
import { DASHBOARD_LAYOUTS_MAP } from '../layouts';
import { WidgetNodeInput } from '../components/Flow/WidgetNode';

export const HEADER_HEIGHT = 60;
export const HANDLE_HEIGHT = 20;

export const trafficLight = [
  '#2dc937',
  '#99c140',
  '#e7b416',
  '#db7b2b',
  '#cc3232',
];

export const datasourceName = (value: any): string => {
  if (typeof value === 'string') {
    return value;
  }
  // For dynamicables (?)
  if (typeof value?.value === 'string') {
    return value.value;
  }
  console.error(
    'Tried to get datasource name from an object with unexpected structure',
    value,
  );
  return '';
};

export const distinct = (
  value: any,
  index: number,
  self: any[],
  extractProperty = (e: any) => e,
) => {
  return self.map(extractProperty).indexOf(extractProperty(value)) === index;
};

export const getTrafficLightColor = (count = 0) => {
  if (count <= 10) return trafficLight[0];
  if (count <= 20) return trafficLight[1];
  if (count <= 50) return trafficLight[2];
  if (count <= 99) return trafficLight[3];
  return trafficLight[4];
};

const getLayoutWidgets = (layoutConfigs: DashboardLayout | '') => {
  const widgets: Widget[] = [];

  if (layoutConfigs) {
    const {
      headers: { left, right, center },
      leftPanels,
      rightPanels,
    } = layoutConfigs;

    [...left, ...right, ...center].map((w) =>
      widgets.push({ id: w.type, type: w.type, config: w.config } as Widget),
    );

    [...leftPanels, ...rightPanels].map((w) =>
      widgets.push({
        id: w.id,
        type: w.widget.type,
        config: w.widget.config,
      } as Widget),
    );
  }

  return widgets;
};

export const layoutsToElements = (
  layout: string,
  positions: { [key: string]: FlowPosition } = {},
): Node[] => {
  const layoutConfigs = layout && DASHBOARD_LAYOUTS_MAP[layout];

  const out = getLayoutWidgets(layoutConfigs).map(
    ({ id, type, config }, _, self) => {
      const name = config?.header || type;

      const dataOutputs = config?.dataOutputs || [];
      const outputs: string[] = dataOutputs
        .map(datasourceName)
        .filter(Boolean)
        .filter(distinct);

      const { x, y } = positions[id] || { x: 0, y: 0 };
      const out: Node = {
        id,
        type: 'layoutWidgetNode',
        data: {
          widgetId: id,
          inputs: [],
          outputs,
          name,
          type,
          relatedNodesWidgetIds: [],
        },
        position: { x, y },
      };
      return out;
    },
  );
  return out;
};

export const widgetsToElements = (
  widgets: Widget[],
  positions: { [key: string]: FlowPosition } = {},
): Node[] =>
  widgets.map(({ id, type, config }, _, self) => {
    const name = config?.header || type;

    const datasources: string[] =
      config?.datasources
        ?.map(datasourceName)
        ?.filter(Boolean)
        ?.filter(distinct) || [];

    const _outputs: string[] =
      config?.dataOutputs
        ?.map(datasourceName)
        ?.filter(Boolean)
        ?.filter(distinct) || [];

    const getInputs = (): WidgetNodeInput[] => {
      switch (type) {
        case 'module-widget-hidden':
        case 'module-widget':
          // eslint-disable-next-line no-case-declarations
          try {
            const inputs: WidgetNodeInput[] =
              config?.inputDatasources?.map(
                (id: {
                  datasource: string;
                  alias: string;
                  defaultValue: any;
                }) => {
                  return {
                    title: id.datasource,
                    hasDefault: id.defaultValue !== undefined,
                  };
                },
              ) || ([] as WidgetNodeInput[]);
            return inputs.filter((inp) => inp.title);
          } catch (e) {
            console.error("Failure building flow out of module inputs'", {
              config,
            });
            return [];
          }
        case 'FakeDatasourceWidget':
          return [];
        default:
          return datasources.map((ds) => ({ title: ds, hasDefault: false }));
      }
    };

    const getOutputs = (): string[] => {
      /** FakeDatasourceWidget is incredibly legacy
       * and is the only widget which has outputs in "datasources" in the config
       */
      return type === 'FakeDatasourceWidget'
        ? [...datasources, ..._outputs].filter(distinct)
        : [..._outputs];
    };

    const [inputs, outputs]: [
      { title: string; hasDefault: boolean }[],
      string[],
    ] = [getInputs(), getOutputs()];

    const relatedNodesWidgetIds = inputs
      .flatMap((input) =>
        self
          .filter(
            ({ config }) =>
              (config?.dataOutputs || []).indexOf(input.title) > -1,
          )
          .map(({ id }) => id),
      )
      .concat(
        outputs.flatMap((output) =>
          self
            .filter(
              ({ config }) => (config?.datasources || []).indexOf(output) > -1,
            )
            .map(({ id }) => id),
        ),
      );
    const { x, y } = positions[id] || { x: 0, y: 0 };

    return {
      id,
      type: 'widgetNode',
      data: {
        widgetId: id,
        inputs,
        outputs,
        name,
        type,
        relatedNodesWidgetIds,
      },
      position: { x, y },
    };
  });

export const elementsToLinks = (elements: Node[]): Edge[] => {
  return elements
    .flatMap((e: Node) => {
      return e.data.inputs.flatMap(
        (input: { title: string; hasDefault: boolean }) => {
          return elements.flatMap((e2: Node) =>
            e2.data.outputs.map((output: string) => {
              if (input.title === output) {
                return {
                  id: `${e.id}_${input.title}_${e2.id}_${output}`,
                  source: e2.id,
                  target: e.id,
                  sourceHandle: `${e2.id}_${output}`,
                  targetHandle: `${e.id}_${input.title}`,
                  animated: true,
                  type: 'link',
                  data: { input: input.title },
                };
              }
            }),
          );
        },
      );
    })
    .filter(Boolean)
    .filter((value: any, index: number, self: any[]) =>
      distinct(value, index, self, (e) => e.id),
    );
};
