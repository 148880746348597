import React, { FC, ReactNode, useState } from 'react';
import { FILE_IMPORT_INPUT } from '../../util/testids';

export type FileImportProps = {
  children: ReactNode;
  onFileChange: (data: any) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const FileImport: FC<FileImportProps> = ({
  children,
  onFileChange,
}: FileImportProps) => {
  const [inputFile, setInputFile] = useState<HTMLInputElement | null>(null);
  const onChange = () => {
    if (inputFile === null || inputFile.files === null) return;
    if (inputFile.files.length > 0) {
      const fileName = inputFile.files[0].name;
      const reader = new FileReader(); // File reader to read the file

      // This event listener will happen when the reader has read the file
      reader.addEventListener('loadend', function () {
        try {
          const result = JSON.parse(reader.result as string); // Parse the result into an object
          onFileChange(result);
        } catch (e) {
          console.error('Cannot import file: ' + fileName, e);
        } finally {
          inputFile.files = null;
          inputFile.value = '';
        }
      });
      reader.readAsText(inputFile.files[0]); // Read the uploaded file
    }
  };

  return (
    <div onClick={() => inputFile?.click()}>
      {children}
      <input
        ref={setInputFile}
        type="file"
        onChange={onChange}
        style={{ display: 'none' }}
        data-testid={FILE_IMPORT_INPUT}
      />
    </div>
  );
};
