import {
  WidgetRegistration,
  WidgetRegistry,
} from '@OrigamiEnergyLtd/react-ui-components';
import {
  DataManager,
  DataManagerProvider,
} from '@OrigamiEnergyLtd/react-ui-components';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';
import { DashboardApiClient } from './ApiClient';
import App from './App';
import { WidgetRegistryProvider } from './components/WidgetsRegistryProvider';
import { rootSagas } from './sagas/sagas';
import { reducer } from './store';

import { AbilityProvider, getPermissions, ability } from './permissions';
import { routeDatasource } from './route/routeDatasource';
import { selectDashboardDatasourceListener } from './route/selectDashboardDatasourceListener';
import widgetSlice, { WidgetTypeRegistrationMap } from './store/widgetSlice';

export const configPromise = fetch('/config.json').then((r) => r.json());

export const renderApp = async (
  config: any,
  widgetRegistrations?: WidgetRegistration[],
  widgetRegistry?: WidgetRegistry,
) => {
  const dataManager = new DataManager();
  routeDatasource(dataManager);
  selectDashboardDatasourceListener(dataManager);

  const _widgetRegistrations: WidgetRegistration[] =
    widgetRegistrations ?? config.widgetRegistrations;
  const _widgetRegistry: WidgetRegistry =
    widgetRegistry ?? new WidgetRegistry(_widgetRegistrations);

  const client = await DashboardApiClient.build(_widgetRegistry, config.apiUrl);

  const sagaMiddleware = createSagaMiddleware({
    context: {
      apiClient: client,
      dataManager,
    },
    onError: (error, errorInfo) => {
      console.error('Unexpected saga error', error, errorInfo);
      alert('Unexpected error, click to reload the page.');
      window.location.reload();
    },
  });

  const preloadedState = await client.preload();

  const store = createStore(
    reducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
  );

  sagaMiddleware.run(rootSagas);

  store.dispatch(
    widgetSlice.actions.setWidgetTypes(
      _widgetRegistry.getItems().reduce((acc, cur) => {
        return {
          ...acc,
          [cur.key]: cur,
        };
      }, {} as WidgetTypeRegistrationMap),
    ),
  );

  const roles = client.getRoles();

  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <AbilityProvider value={ability(getPermissions(roles))}>
      <Provider store={store}>
        <DataManagerProvider value={dataManager}>
          <WidgetRegistryProvider registrations={_widgetRegistry}>
            <App />
          </WidgetRegistryProvider>
        </DataManagerProvider>
      </Provider>
    </AbilityProvider>,
  );
};
