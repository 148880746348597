import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Config, ModuleDTO } from '@OrigamiEnergyLtd/ui-node-services';
import { MetaData, DataPaths } from '@OrigamiEnergyLtd/react-ui-components';
import { WritableDraft } from 'immer/dist/internal';

type WidgetMetadataMap = {
  [widgetKey: string]: MetaData;
};

export type Organisation = { id: string; name: string };

export type ConfigEditorBaseState = {
  drawerOpen: boolean;
  widgetId: string | undefined;
  widgetMetadataMap: WidgetMetadataMap;
  unsavedChanges: boolean;
  pendingConfigClose?: PendingConfigEditEnd;
  module: ModuleDTO | undefined;
  organisations: Organisation[];
};

export type OpenConfigEditorPayload = {
  widgetId: string;
};

export type OpenConfigEditorRequestPayload = {
  widgetId: string;
};

export enum ConfigEndEditAction {
  NEW_CONFIG = 'new_config',
  NEW_DASHBOARD = 'new_dashboard',
  CLOSE = 'close',
  DISCARD = 'discard',
}

export type PendingConfigEditEndNewConfig = {
  action: ConfigEndEditAction.NEW_CONFIG;
  destinationId: string;
};

export type PendingConfigEditEndNewDashboard = {
  action: ConfigEndEditAction.NEW_DASHBOARD;
  destinationId: string;
};

type PendingConfigEditEndClose = {
  action: ConfigEndEditAction.CLOSE;
};

type PendingConfigEditEndDiscard = {
  action: ConfigEndEditAction.DISCARD;
};

export type PendingConfigEditEnd =
  | PendingConfigEditEndClose
  | PendingConfigEditEndNewConfig
  | PendingConfigEditEndNewDashboard
  | PendingConfigEditEndDiscard;

export const initialState: ConfigEditorBaseState = {
  drawerOpen: false,
  widgetId: undefined,
  widgetMetadataMap: {},
  unsavedChanges: false,
  module: undefined,
  organisations: [],
};

export type ConfigEditorState = typeof initialState;

export type SetWidgetMetadataPayload = {
  widgetType: string;
  metaData: MetaData;
};

export type SubmitConfigEditorPayload = {
  config: Config;
  dataPaths: DataPaths | undefined;
};

export const configEditorSlice = createSlice({
  name: 'configEditor',
  initialState,
  reducers: {
    openConfigRequest: (
      state,
      action: PayloadAction<OpenConfigEditorRequestPayload>,
    ) => {},
    openConfig: (
      state,
      action: PayloadAction<OpenConfigEditorRequestPayload>,
    ) => {},
    submitConfigEditor: (
      state,
      action: PayloadAction<SubmitConfigEditorPayload>,
    ) => {},
    open: (state, action: PayloadAction<OpenConfigEditorPayload>) => {
      state.drawerOpen = true;
      state.unsavedChanges = false;
      state.widgetId = action.payload.widgetId;
    },
    closeRequest: (state) => {},
    close: (state) => {
      state.drawerOpen = false;
      state.widgetId = undefined;
      state.unsavedChanges = false;
    },
    discardRequest: (state) => {},
    setMetadata: (state, action: PayloadAction<SetWidgetMetadataPayload>) => {
      // With the update to RJSF 5.x, the UiSchema (within MetaData) is readonly so has to be cast to WriteableDraft
      state.widgetMetadataMap[action.payload.widgetType] = action.payload
        .metaData as WritableDraft<MetaData>;
    },
    showUnsavedChangesWarning: (
      state,
      action: PayloadAction<PendingConfigEditEnd>,
    ) => {
      state.pendingConfigClose = action.payload;
    },
    clearPendingConfigClose: (state) => {
      state.pendingConfigClose = undefined;
    },
    setUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.unsavedChanges = action.payload;
    },
    loadConfigModule: (state) => {
      // do nothing
    },
    setModule: (state, action: PayloadAction<ModuleDTO | undefined>) => {
      state.module = action.payload;
    },
    setOrganisations: (state, action: PayloadAction<Organisation[]>) => {
      state.organisations = action.payload;
    },
  },
});

export default configEditorSlice;
