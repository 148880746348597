import { DynamicableSchema } from '@OrigamiEnergyLtd/react-ui-components';

export const dynamicableToRjsf = ({
  title,
  isDynamicDefault,
  required,
  pathTitle,
  staticControl,
}: DynamicableSchema) => {
  return {
    type: 'object',
    title: title,
    properties: {
      dynamic: {
        type: 'boolean',
        title: 'Dynamic',
        default: isDynamicDefault ? true : false,
      },
    },
    dependencies: {
      dynamic: {
        oneOf: [
          {
            required: ['datasource'],
            properties: {
              dynamic: {
                enum: [true],
              },
              datasource: {
                type: 'string',
                title: pathTitle,
              },
            },
          },
          {
            required: required ? ['value'] : [],
            properties: {
              dynamic: {
                enum: [false],
              },
              value: {
                ...staticControl,
              },
            },
          },
        ],
      },
    },
  };
};
