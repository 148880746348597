import React from 'react';
import { ObjectFieldTemplateProps } from '@rjsf/utils';

export const dynamicInput = (props: ObjectFieldTemplateProps) => {
  const [checkbox, input] = props.properties;

  return (
    <fieldset>
      <legend>{props.title}</legend>
      {props.description && <p>{props.description}</p>}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div
          className="property-wrapper dynamic-toggle"
          style={{
            flex: 0,
            flexBasis: 'auto',
            width: 'auto',
            paddingRight: 30,
          }}
        >
          {checkbox?.content}
        </div>
        <div
          style={{
            flex: 1,
            paddingLeft: 12,
          }}
          className="property-wrapper"
        >
          {input?.content}
        </div>
      </div>
    </fieldset>
  );
};
