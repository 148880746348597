import React from 'react';

export const CustomTemplate = (props: any) => {
  const {
    id,
    classNames,
    label,
    help,
    required,
    description,
    errors,
    children,
    hidden,
    displayLabel,
  } = props;
  if (hidden) {
    return <div className="hidden">{children}</div>;
  }

  return (
    <>
      {description ? description : null}
      <div className={classNames}>
        {displayLabel && (
          <label className="control-label" htmlFor={id}>
            {label}
            {required ? <span className="required">*</span> : null}
          </label>
        )}
        {children}
        {errors}
        {help}
      </div>
    </>
  );
};
