import { ConfirmDialogFullScreen } from '@OrigamiEnergyLtd/react-ui-components';
import React from 'react';
import { RootState } from '../../store';
import configEditorSlice, {
  ConfigEndEditAction,
  PendingConfigEditEnd,
  PendingConfigEditEndNewConfig,
  PendingConfigEditEndNewDashboard,
} from '../../store/configEditorSlice';
import {
  configEditorPendingCloseSelector,
  configEditorWidgetIdSelector,
} from '../../store/selectors';
import { createStructuredSelector } from 'reselect';
import dashboardSlice from '../../store/dashboardSlice';
import { connect, ConnectedProps } from 'react-redux';
import widgetSlice from '../../store/widgetSlice';

type UnsavedConfigWarningSelection = {
  closingAction: PendingConfigEditEnd | undefined;
  widgetId: string | undefined;
};

const mapState = createStructuredSelector<
  RootState,
  UnsavedConfigWarningSelection
>({
  closingAction: configEditorPendingCloseSelector,
  widgetId: configEditorWidgetIdSelector,
});

const mapDispatch = {
  closeConfigEditor: configEditorSlice.actions.close,
  loadNewConfig: configEditorSlice.actions.openConfig,
  clearWarning: configEditorSlice.actions.clearPendingConfigClose,
  showDashboard: dashboardSlice.actions.selectDashboard,
  discardChanges: widgetSlice.actions.resetWidgetConfig,
};

const connector = connect(mapState, mapDispatch);

type UnsavedConfigWarningProps = ConnectedProps<typeof connector>;

const UnsavedConfigWarning = ({
  closingAction,
  showDashboard,
  clearWarning,
  loadNewConfig,
  closeConfigEditor,
  discardChanges,
  widgetId,
}: UnsavedConfigWarningProps) => {
  const handleResult = (confirmed: boolean) => {
    if (confirmed && closingAction !== undefined) {
      switch (closingAction.action) {
        case ConfigEndEditAction.CLOSE:
          closeConfigEditor();
          break;
        case ConfigEndEditAction.NEW_CONFIG:
          loadNewConfig({
            widgetId: (closingAction as PendingConfigEditEndNewConfig)
              .destinationId,
          });
          break;
        case ConfigEndEditAction.NEW_DASHBOARD:
          closeConfigEditor();
          showDashboard(
            (closingAction as PendingConfigEditEndNewDashboard).destinationId,
          );
          break;
        case ConfigEndEditAction.DISCARD:
          discardChanges(widgetId!);
          break;
      }
    }
    clearWarning();
  };

  return (
    <ConfirmDialogFullScreen
      isOpen={Boolean(closingAction)}
      subLabel={'Unsaved config changes will be lost.'}
      onClick={(confirmed: boolean) => handleResult(confirmed)}
    />
  );
};

export default connector(UnsavedConfigWarning);
