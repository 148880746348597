import { Data } from '@OrigamiEnergyLtd/ui-utilities';
import { DataManager } from '@OrigamiEnergyLtd/react-ui-components';
import { Dashboard } from '../store/dashboardSlice';
import { updateDashboardAndParams } from './routeUtils';

export const SELECT_DASHBOARD_ID = 'SELECT_DASHBOARD_ID';
export const SELECT_DASHBOARD = 'SELECT_DASHBOARD';

export type DashboardIdSelection = {
  dashboardId: string;
  params?: {
    [key: string]: any;
  };
};

export type DashboardSelection = {
  dashboard: Dashboard;
  params?: {
    [key: string]: any;
  };
};

export const selectDashboardDatasourceListener = (dataManager: DataManager) => {
  dataManager.subscribe(
    [SELECT_DASHBOARD],
    ({ data: { dashboard, params } }: Data<DashboardSelection>) => {
      updateDashboardAndParams(dashboard, params);
    },
  );
};
