import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AnyAbility, defineAbility } from '@casl/ability';

export const AbilityContext = createContext<AnyAbility>(undefined!);
export const Can = createContextualCan(AbilityContext.Consumer);

export const AbilityProvider = AbilityContext.Provider;

export enum PermissionAction {
  UNLOCK = 'UNLOCK',
  DEBUG = 'DEBUG',
  PUBLISH = 'PUBLISH',
  ADMIN = 'ADMIN',
}

export enum PermissionSubject {
  DASHBOARD = 'Dashboard',
  MODULE = 'Module',
}

export type Permission = {
  action: PermissionAction;
  subject: PermissionSubject;
};

export const permissions = {
  UNLOCK_DASHBOARD: {
    action: PermissionAction.UNLOCK,
    subject: PermissionSubject.DASHBOARD,
  },
  DEBUG_DASHBOARD: {
    action: PermissionAction.DEBUG,
    subject: PermissionSubject.DASHBOARD,
  },
  ADMIN_DASHBOARD: {
    action: PermissionAction.ADMIN,
    subject: PermissionSubject.DASHBOARD,
  },
  PUBLISH_MODULE: {
    action: PermissionAction.PUBLISH,
    subject: PermissionSubject.MODULE,
  },
};

const ROLE_PERMISSION_MAP: { [role: string]: readonly Permission[] } = {
  editor: [permissions.UNLOCK_DASHBOARD, permissions.DEBUG_DASHBOARD],
  origami_admin: [permissions.UNLOCK_DASHBOARD, permissions.DEBUG_DASHBOARD],
  origami_fe_admin: [
    permissions.UNLOCK_DASHBOARD,
    permissions.DEBUG_DASHBOARD,
    permissions.ADMIN_DASHBOARD,
    permissions.PUBLISH_MODULE,
  ],
};

export const getPermissions = (roles: string[]): Permission[] => {
  return Array.from(
    new Set(roles.flatMap((role) => ROLE_PERMISSION_MAP[role])),
  ).filter(Boolean);
};

export const ability = (permissions: Permission[] = []) =>
  defineAbility((can) => {
    permissions.forEach(({ action, subject }) => {
      can(action, subject);
    });
  });
