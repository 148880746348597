import { defaultThemeName } from '@OrigamiEnergyLtd/design-tokens';
import { RootState } from '.';

export const emptyInitialState = () =>
  ({
    preferences: {
      theme: defaultThemeName,
      beta: false,
    },
    widgets: {
      ids: [],
      entities: {},
      maxHeightGrid: -1,
      widgetTypeMap: {},
      highlightWidgetId: '',
    },
    dashboards: {
      ids: [],
      entities: {},
      dashboardLocked: false,
      showList: false,
      dashboardReady: false,
      showWidgetList: false,
    },
    dataLog: {
      isLoggingEnabled: false,
      isVisible: false,
      tab: 'data',
      widgetDataWidgetId: undefined,
      dataDrawerOpen: false,
      datas: [],
      eventCounts: {},
      widgetDatasourceMap: {},
    },
    notifications: {
      notifications: [],
    },
    flow: {
      nodes: [],
      edges: [],
      positions: [],
      show: false,
    },
    configEditor: {
      drawerOpen: false,
      currentConfig: undefined,
      widgetId: undefined,
      widgetType: undefined,
      metaData: undefined,
      widgetMetadataMap: {},
      unsavedChanges: false,
      module: undefined,
      organisations: [],
    },
    modules: {
      ids: [],
      entities: {},
      formState: 'closed',
      errorToDisplay: undefined,
    },
  }) as RootState;
