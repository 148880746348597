import React, { FC } from 'react';
import {
  WidgetRegistry,
  WidgetRegistration,
} from '@OrigamiEnergyLtd/react-ui-components';

const WidgetRegistryContext = React.createContext<WidgetRegistry | undefined>(
  undefined,
);

export const useWidgetRegistry = () => {
  const context = React.useContext(WidgetRegistryContext);
  if (context === undefined) {
    throw new Error(
      'useWidgetRegistry must be used within a WidgetRegistryProvider',
    );
  }
  return context;
};

type IWidgetRegistryProps = {
  registrations?: WidgetRegistration[] | WidgetRegistry;
  children: React.ReactNode;
};

export const WidgetRegistryProvider: FC<IWidgetRegistryProps> = ({
  registrations,
  children,
}) => {
  const widgetRegistry =
    registrations instanceof WidgetRegistry
      ? registrations
      : new WidgetRegistry(registrations!);
  return (
    <WidgetRegistryContext.Provider value={widgetRegistry}>
      {children}
    </WidgetRegistryContext.Provider>
  );
};
