import { WidgetDefinition } from '../store/widgetSlice';

export type HeaderWidgetDefinition = WidgetDefinition & {
  maxWidth?: number;
};

export type SidePanel = {
  id: string;
  label?: string;
  icon?: string;
  widget: HeaderWidgetDefinition;
};

// We need to iterate over the possible layout types, so these 2 need to have the same value options
export const LAYOUT_TYPES = ['Standard', 'Experimental'];
export type LayoutType = 'Standard' | 'Experimental';

export type DashboardLayout = {
  id: string;
  label: string;
  headers: {
    left: HeaderWidgetDefinition[];
    center: HeaderWidgetDefinition[];
    right: HeaderWidgetDefinition[];
  };
  leftPanels: SidePanel[];
  rightPanels: SidePanel[];
  type: LayoutType;
};
