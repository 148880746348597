import { Button, WarningIcon } from '@OrigamiEnergyLtd/react-ui-components';
import React from 'react';
import ReactJson from 'react-json-view';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  DATASOURCE_NO_VALUE_EMITTED,
  useSubscribeToDatasources,
} from '../../../../events/useSubscribeToDatasources';
import { RootState } from '../../../../store';
import { dataLogWidgetSelector } from '../../../../store/selectors';
import { Widget } from '../../../../store/widgetSlice';
import {
  DATA_TAB_INPUTS_CONTAINER,
  DATA_TAB_OUTPUTS_CONTAINER,
} from '../../../../util/testids';

type WidgetDataDisplaySelector = {
  widget: Widget | undefined;
};

const mapState = createStructuredSelector<RootState, WidgetDataDisplaySelector>(
  {
    widget: dataLogWidgetSelector,
  },
);
const mapDispatch = {};
const connector = connect(mapState, mapDispatch);

type WidgetDataDisplayProps = ConnectedProps<typeof connector>;

type DataMap = {
  [datasource: string]: any;
};

const isNullData = (data: any) => {
  const nullUndefined = ['null', 'undefined'];
  return (
    data === DATASOURCE_NO_VALUE_EMITTED ||
    nullUndefined.indexOf(typeof data) > -1
  );
};

const Data = ({ name, data }: { name: string; data: any }) => {
  if (isNullData(data)) {
    return (
      <div
        data-testid={`data-value-${name}-undefined`}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <p style={{ margin: 0 }}>
          <code>undefined</code>
        </p>
        <WarningIcon size={16} color="red" style={{ marginLeft: 12 }} />
      </div>
    );
  }
  switch (typeof data) {
    case 'string':
      return (
        <p style={{ margin: 0 }} data-testid={`data-value-${name}`}>
          <code>string</code>: &quot;{data}&quot;
        </p>
      );
    case 'number':
    case 'boolean':
      return (
        <p style={{ margin: 0 }} data-testid={`data-value-${name}`}>
          <code>{typeof data}</code>: {JSON.stringify(data)}
        </p>
      );
    default:
      return (
        <ReactJson
          theme="monokai"
          src={data}
          collapsed={true}
          style={{ backgroundColor: '#1A1A1A' }}
        ></ReactJson>
      );
  }
};

const copyDataToClipboard = async (value: any) => {
  if (isNullData(value)) return;
  switch (typeof value) {
    case 'string':
      await navigator.clipboard.writeText(value);
      break;
    case 'boolean':
    case 'number':
      await navigator.clipboard.writeText(JSON.stringify(value));
      break;
    default:
      await navigator.clipboard.writeText(JSON.stringify(value, undefined, 2)); // Make sure to keep formatting of the copied array / object
  }
};

const DisplayDatas = ({ datas }: { datas: DataMap }) => {
  return (
    <div>
      {Object.entries(datas).map(([name, value]) => (
        <div key={name} data-testid={`data-item-${name}`}>
          <div
            style={{
              marginTop: 10,
              marginBottom: 6,
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                marginRight: 10,
              }}
            >
              <h3
                style={{
                  fontWeight: 200,
                  fontSize: 16,
                  color: '#A5A5A5',
                }}
              >
                {name}
              </h3>
            </div>
            <div style={{ display: 'inline-block' }}>
              <Button
                data-testid={`COPY_VALUE-${name}`}
                disabled={isNullData(value)}
                variant="secondary"
                onClick={async () => {
                  await copyDataToClipboard(value);
                }}
              >
                Copy data
              </Button>
            </div>
          </div>
          <Data name={name} data={value} />
        </div>
      ))}
    </div>
  );
};

const EMPTY_DATASOURCES: string[] = [];

const UnconnectedWidgetDataDisplay = ({ widget }: WidgetDataDisplayProps) => {
  const datasources: string[] =
    widget?.config?.datasources ?? EMPTY_DATASOURCES;
  const dataOutputs: string[] =
    widget?.config?.dataOutputs ?? EMPTY_DATASOURCES;

  const inputValues = useSubscribeToDatasources(datasources);
  const outputValues = useSubscribeToDatasources(dataOutputs);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 12,
      }}
    >
      {datasources.length > 0 && (
        <div
          data-testid={DATA_TAB_INPUTS_CONTAINER}
          style={{ flex: 1, margin: 6 }}
        >
          <h2
            style={{
              fontWeight: '600',
              fontSize: 14,
              letterSpacing: 0.8,
              margin: 0,
            }}
          >
            Inputs
          </h2>
          <DisplayDatas datas={inputValues} />
        </div>
      )}
      {dataOutputs.length > 0 && (
        <div
          data-testid={DATA_TAB_OUTPUTS_CONTAINER}
          style={{ flex: 1, margin: 6 }}
        >
          <h2
            style={{
              fontWeight: '600',
              fontSize: 14,
              letterSpacing: 0.8,
              margin: 0,
            }}
          >
            Outputs
          </h2>
          <DisplayDatas datas={outputValues} />
        </div>
      )}
    </div>
  );
};

export const WidgetDataDisplay = connector(UnconnectedWidgetDataDisplay);
