import { getTextWidthPx } from '@OrigamiEnergyLtd/ui-utilities';
import { Node, Edge } from 'reactflow';

import { HANDLE_HEIGHT, HEADER_HEIGHT } from './flowGraph';

const DEFAULT_WIDTH = 250;

const PADDING = 22;
const ICON_WIDTH = 30;

const BUTTON_WIDTH = ICON_WIDTH * 3;
const STATUS_WIDTH = ICON_WIDTH * 2;

const SPACE_X = 200 + PADDING;
const SPACE_Y = 50 + PADDING;

const defaultLayoutOptions = {
  'elk.direction': 'RIGHT',
  'elk.algorithm': 'layered',
  'elk.edgeRouting': 'SPLINES',
};

export const createGraphLayout = async (
  widgetNodes: Node[],
  edges: Edge[],
): Promise<Node[]> => {
  const Elk = (await import('elkjs')).default;
  const elk = new Elk({ defaultLayoutOptions });
  const newGraph = await elk.layout({
    id: 'root',
    children: widgetNodes.map(
      ({ id, data: { name, inputs, outputs, type } }) => {
        const headerWidth = getTextWidthPx(name, `bold 20px`);

        const inputWidth = inputs.reduce((acc: number, text: string) => {
          const width = getTextWidthPx(text, `normal 14px`);

          return Math.max(acc, width);
        }, 0);

        const outputWidth = outputs.reduce((acc: number, text: string) => {
          const width = getTextWidthPx(text, `normal 14px`);

          return Math.max(acc, width);
        }, 0);

        const width = Math.max(
          headerWidth + BUTTON_WIDTH,
          inputWidth + outputWidth + STATUS_WIDTH + PADDING,
          DEFAULT_WIDTH,
        );

        const height =
          Math.max(inputs.length, outputs.length) * HANDLE_HEIGHT +
          HEADER_HEIGHT;

        return {
          id,
          width: width + SPACE_X,
          height: height + SPACE_Y,
          type,
        };
      },
    ),
    edges: edges.map((e) => ({
      id: e.id,
      sources: [e.source],
      targets: [e.target],
    })),
  });

  return [
    ...widgetNodes.map((nodeState) => {
      const node = newGraph.children?.find((n) => n.id === nodeState.id);

      if (node?.x && node?.y && node?.width && node?.height) {
        const { width, height, x, y } = node;

        return {
          ...nodeState,
          data: {
            ...nodeState.data,
            width: width - SPACE_X,
            height: height - SPACE_Y,
          },
          position: {
            x: nodeState.position.x || x,
            y: nodeState.position.y || y,
          },
        };
      }

      return { ...nodeState };
    }),
  ];
};
