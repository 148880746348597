import React, { ChangeEvent, KeyboardEvent, FC, useState } from 'react';
import {
  DASHBOARD_LIST,
  DASHBOARD_TAB,
  DASHBOARD_TABS_LIST,
  DASHBOARD_ITEM_CLONE,
  DASHBOARD_ITEM_RENAME,
  DASHBOARD_ITEM_RENAME_INPUT,
  DASHBOARD_ITEM_REMOVE,
  NEW_DASHBOARD_BUTTON,
  DASHBOARD_ITEM_LAYOUTS,
  DASHBOARD_ITEM_LAYOUT,
  DASHBOARD_EXPORT_BUTTON,
} from '../../../util/testids';
import { Dashboard } from '../../../store/dashboardSlice';
import {
  ListItem,
  List,
  Divider,
  IconButton,
  ListItemText,
  Tooltip,
  Drawer,
  TextField,
  ListSubheader,
  ConfirmButton,
  Button,
  DropdownButton,
  ExportIcon,
  DeleteIcon,
  WidgetsIcon,
  AddIcon,
  EditIcon,
  CloneIcon,
  CheckIcon,
  DashboardIcon,
} from '@OrigamiEnergyLtd/react-ui-components';
import { DASHBOARD_LAYOUTS, DASHBOARD_DEFAULT_LAYOUT } from '../../../layouts';
import styled from 'styled-components';
import { LAYOUT_TYPES } from '../../../layouts/layout';
import { ModuleLabel, ModuleLabelWrapper } from '../ModuleLabel/ModuleLabel';

export type DashboardWithLabel = {
  id: string;
  label: string;
};

export type DashboardTabListProps = {
  userDashboards: Dashboard[];
  predefinedDashboards: Dashboard[];
  moduleDashboards: Dashboard[];
  activeId?: string;
  onDashboardSelect: (dashboardId: string) => void;
  onNewDashboard: (label: string) => void;
  onRenameDashboard: (renamePayload: DashboardWithLabel) => void;
  onRemoveDashboard: (id: string) => void;
  onClone: (id: string) => void;
  onSetLayout: (id: string, layout: string) => void;
  locked: boolean;
  visible: boolean;
  setVisible: (status: boolean) => void;
  onDashboardExport: (dashboardId: string) => void;
};

const LayoutItem = styled.div<{ $selected: boolean }>`
  background-color: ${({ $selected }) =>
    $selected ? 'rgba(128, 128, 128, 0.6)' : 'rgba(128, 128, 128, 0)'};
  cursor: pointer;
  border-radius: 0.25em;
  white-space: nowrap;
  padding: 0 0.125rem;
  &:hover {
    background-color: rgba(128, 128, 128, 0.3);
  }
`;

const DashboardTabList: FC<DashboardTabListProps> = ({
  activeId,
  userDashboards,
  predefinedDashboards,
  moduleDashboards,
  onDashboardSelect,
  onNewDashboard,
  onRenameDashboard,
  onRemoveDashboard,
  onClone,
  onSetLayout,
  locked,
  visible,
  setVisible,
  onDashboardExport,
}) => {
  const [rename, setRename] = useState<DashboardWithLabel | undefined>(
    undefined,
  );

  const onNameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setRename((prev) => ({ id: prev!.id, label: value }));

  const applyRename = () => {
    rename && onRenameDashboard(rename);
    setRename(undefined);
  };
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyRename();
    }
  };

  const toListItem = (
    { id, label, predefined, editable, layout, moduleMeta }: Dashboard,
    {
      showClone = true,
      showExport = true,
      showRename = (!predefined && !moduleMeta) || (moduleMeta && editable),
      showLayouts = (!predefined && !moduleMeta) || (moduleMeta && editable),
      showDelete = !predefined && !moduleMeta,
    } = {},
  ) => (
    <ListItem
      button
      key={id}
      id={id}
      data-testclass={DASHBOARD_TAB}
      data-testid={`${DASHBOARD_TAB}-${label.replace(' ', '')}`}
      data-testvalue={activeId === id}
      onClick={() => {
        onDashboardSelect(id);
        setRename(undefined);
        setVisible(false);
      }}
      selected={activeId === id}
    >
      <ListItemText>
        {rename?.id === id ? (
          <div style={{ display: 'flex' }} onClick={(e) => e.stopPropagation()}>
            <TextField
              data-testid={DASHBOARD_ITEM_RENAME_INPUT}
              value={rename.label}
              onChange={onNameChange}
              onFocus={(e: any) =>
                e.target.setSelectionRange(0, e.target.value.length)
              }
              autoFocus
              InputProps={{ onKeyDown }}
            ></TextField>
            <Button onClick={applyRename}>
              <CheckIcon size={16} />
            </Button>
          </div>
        ) : (
          <>
            {label}
            {moduleMeta?.label && (
              <ModuleLabelWrapper>
                <ModuleLabel>{moduleMeta.label}</ModuleLabel>
              </ModuleLabelWrapper>
            )}
          </>
        )}
      </ListItemText>
      {!locked && (
        <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
          {showExport && (
            <Tooltip title="Export dashboard">
              <Button
                style={{ minWidth: 0 }}
                onClick={() => onDashboardExport(id)}
                data-testid={DASHBOARD_EXPORT_BUTTON}
              >
                <ExportIcon size={16} />
              </Button>
            </Tooltip>
          )}
          {showRename && (
            <Tooltip title="Rename dashboard">
              <Button
                style={{ minWidth: 0 }}
                onClick={() => setRename({ id, label })}
                data-testid={DASHBOARD_ITEM_RENAME}
              >
                <EditIcon size={16} />
              </Button>
            </Tooltip>
          )}
          {showLayouts && (
            <DropdownButton
              data-testid={DASHBOARD_ITEM_LAYOUTS}
              label={<DashboardIcon size={16} />}
              singleClick
              showArrow={false}
            >
              <div style={{ padding: '0 0.5em' }}>
                {LAYOUT_TYPES.map((layoutType, ii) => {
                  const layouts = DASHBOARD_LAYOUTS.filter(
                    (l) => l.type === layoutType,
                  );
                  return (
                    <div
                      style={{
                        marginTop: '0.5em',
                        marginBottom:
                          ii === LAYOUT_TYPES.length - 1 ? '0.25em' : '0',
                      }}
                      key={`${layoutType}-layouts`}
                      data-testid={`${layoutType}-layouts`}
                    >
                      <h3
                        style={{
                          margin: '0 0 0.25rem 0', // Override the default h3 margin to avoid huge gaps in the list
                          lineHeight: 1, // Override default line height for h3 so we don't get the 'implicit padding' that creates
                        }}
                        data-testid={`${layoutType}-layouts-header`}
                      >
                        {layoutType}
                      </h3>
                      {layouts.map((_layout) => (
                        <LayoutItem
                          data-testid={DASHBOARD_ITEM_LAYOUT}
                          data-testvalue={_layout.id}
                          key={_layout.id}
                          $selected={
                            _layout.id ===
                            (layout || DASHBOARD_DEFAULT_LAYOUT.id)
                          }
                          onClick={() => onSetLayout(id, _layout.id)}
                        >
                          {_layout.label}
                        </LayoutItem>
                      ))}
                    </div>
                  );
                })}
              </div>
            </DropdownButton>
          )}
          {showClone && (
            <Tooltip title="Clone dashboard">
              <Button
                style={{ minWidth: 0 }}
                onClick={() => onClone(id)}
                data-testid={DASHBOARD_ITEM_CLONE}
              >
                <CloneIcon size={16} />
              </Button>
            </Tooltip>
          )}
          {showDelete && (
            <ConfirmButton
              label="Delete dashboard ?"
              noLabel="No"
              noVariant="secondary"
              yesVariant="danger"
              yesLabel="Yes"
              onClick={() => onRemoveDashboard(id)}
              data-testid={DASHBOARD_ITEM_REMOVE}
            >
              <Tooltip title="Delete dashboard">
                <span>
                  <DeleteIcon size={16} />
                </span>
              </Tooltip>
            </ConfirmButton>
          )}
        </div>
      )}
    </ListItem>
  );

  return (
    <React.Fragment>
      <div data-testid={DASHBOARD_TABS_LIST}>
        <Drawer
          anchor="left"
          open={visible}
          onClose={() => {
            setRename(undefined);
            setVisible(false);
          }}
        >
          <div data-testid={DASHBOARD_LIST} style={{ width: 500 }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                paddingLeft: 15,
                height: 48,
              }}
            >
              <WidgetsIcon size={24} />
              <div style={{ marginLeft: 15 }}>Dashboards</div>

              {!locked && (
                <Tooltip title="Add dashboard">
                  <IconButton
                    data-testid={NEW_DASHBOARD_BUTTON}
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                      onNewDashboard(
                        'Dashboard ' +
                          (userDashboards.length +
                            predefinedDashboards.length +
                            moduleDashboards.length +
                            1),
                      );
                      setVisible(false);
                    }}
                  >
                    <AddIcon size={24} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <Divider />
            <List>
              <ListSubheader disableSticky={true}>Shared</ListSubheader>
              {predefinedDashboards.length > 0 ? (
                predefinedDashboards.map((d) => toListItem(d))
              ) : (
                <ListItem
                  style={{
                    opacity: 0.6,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div>Nothing here</div>
                </ListItem>
              )}
              <ListSubheader disableSticky={true}>Modules</ListSubheader>
              {moduleDashboards.length > 0 ? (
                moduleDashboards.map((d) => toListItem(d))
              ) : (
                <ListItem
                  style={{
                    opacity: 0.6,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div>Nothing here</div>
                </ListItem>
              )}
              <ListSubheader disableSticky={true}>Personal</ListSubheader>
              {userDashboards.length > 0 ? (
                userDashboards.map((d) => toListItem(d))
              ) : (
                <ListItem
                  style={{
                    opacity: 0.6,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div>Nothing here</div>
                  <div>(click on + icon to add dashboard)</div>
                </ListItem>
              )}
            </List>
          </div>
        </Drawer>
      </div>
    </React.Fragment>
  );
};

export default React.memo(DashboardTabList);
