import { call, getContext, put, select } from 'redux-saga/effects';
import dashboardSlice, { Dashboard } from '../store/dashboardSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { EventChannel, eventChannel } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';
import { DataManager } from '@OrigamiEnergyLtd/react-ui-components';
import { URL_DASHBOARD } from '../route/routeDatasource';
import { updateDashboardUrl } from '../route/routeUtils';
import {
  DashboardIdSelection,
  SELECT_DASHBOARD,
  SELECT_DASHBOARD_ID,
} from '../route/selectDashboardDatasourceListener';
import configEditorSlice, {
  ConfigEndEditAction,
} from '../store/configEditorSlice';
import {
  configEditorUnsavedChangesSelector,
  dashboardByIdSelector,
  moduleConfigDrawerIsOpenSelector,
} from '../store/selectors';
import { moduleSlice } from '../store/moduleSlice';

export function* selectDashboardRequest({
  payload: dashboardId,
}: PayloadAction<string | undefined>) {
  const showConfigWarning: boolean = yield select(
    configEditorUnsavedChangesSelector,
  );
  const isPublishModuleOpen: boolean = yield select(
    moduleConfigDrawerIsOpenSelector,
  );
  if (isPublishModuleOpen) {
    yield put(moduleSlice.actions.requestDrawerDiscard());
  }
  if (showConfigWarning && dashboardId) {
    yield put(
      configEditorSlice.actions.showUnsavedChangesWarning({
        action: ConfigEndEditAction.NEW_DASHBOARD,
        destinationId: dashboardId,
      }),
    );
    return;
  }
  yield put(dashboardSlice.actions.selectDashboard(dashboardId));
}

export function* selectDashboard({
  payload: dashboardId,
}: PayloadAction<string | undefined>) {
  const dashboard: Dashboard =
    dashboardId !== undefined
      ? yield select(dashboardByIdSelector, dashboardId)
      : undefined;
  updateDashboardUrl(dashboard);
}

export function* addDashboard({
  payload: dashboard,
}: PayloadAction<Dashboard>) {
  const showConfigWarning: boolean = yield select(
    configEditorUnsavedChangesSelector,
  );
  if (showConfigWarning && dashboard.id) {
    yield put(dashboardSlice.actions.dashboardShowList(false));
    yield put(
      configEditorSlice.actions.showUnsavedChangesWarning({
        action: ConfigEndEditAction.NEW_DASHBOARD,
        destinationId: dashboard.id,
      }),
    );
    return;
  }
  updateDashboardUrl(dashboard);
}

export function* updateSelectedDashboard(dashboard: Dashboard) {
  yield put(configEditorSlice.actions.close());
  yield put(dashboardSlice.actions.updateActiveDashboard(dashboard.id));
}

export function* enrichSelectDashboardEvent({
  dashboardId,
  params,
}: DashboardIdSelection) {
  const dataManager = (yield getContext('dataManager')) as DataManager;

  const dashboard: Dashboard =
    dashboardId !== undefined
      ? yield select(dashboardByIdSelector, dashboardId)
      : undefined;

  dataManager.next({
    datasource: SELECT_DASHBOARD,
    data: { dashboard, params },
  });
}

const createDatasourceChannel = (
  dataManager: DataManager,
  datasources: string[],
) => {
  return eventChannel<Dashboard>((emit) => {
    const unsubscribe = dataManager.subscribe(datasources, (data) =>
      emit(data.data),
    );
    return unsubscribe;
  });
};

/* istanbul ignore next */
export default function* routingSagas() {
  const dataManager = (yield getContext('dataManager')) as DataManager;

  yield takeEvery(
    dashboardSlice.actions.selectDashboardRequest,
    selectDashboardRequest,
  );
  yield takeEvery(dashboardSlice.actions.selectDashboard, selectDashboard);
  yield takeEvery(dashboardSlice.actions.addDashboard, addDashboard);

  const datasourceDashboardChangeChannel: EventChannel<Dashboard> = yield call(
    createDatasourceChannel,
    dataManager,
    [URL_DASHBOARD],
  );
  yield takeEvery(datasourceDashboardChangeChannel, updateSelectedDashboard);

  const dashboardSelectionChannel: EventChannel<DashboardIdSelection> =
    yield call(createDatasourceChannel, dataManager, [SELECT_DASHBOARD_ID]);
  yield takeEvery(dashboardSelectionChannel, enrichSelectDashboardEvent);
}
