import {
  AddIcon,
  LockIcon,
  LockOpenIcon,
  DashboardToggleIcon,
  FlowIcon,
  LogoutIcon,
  ImportIcon,
  Paper,
  Button,
  Avatar,
  IconButton,
  Tooltip,
} from '@OrigamiEnergyLtd/react-ui-components';
import React, { FC, ReactNode, useContext, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTitle } from 'react-use';
import { createStructuredSelector } from 'reselect';
import { DASHBOARD_DEFAULT_LAYOUT, DASHBOARD_LAYOUTS_MAP } from '../../layouts';
import { SidePanel } from '../../layouts/layout';
import {
  AbilityContext,
  Can,
  PermissionAction,
  PermissionSubject,
} from '../../permissions';
import { extractDashboardDetailsFromUrl } from '../../route/routeUtils';
import { RootState } from '../../store';
import dashboardSlice, { Dashboard } from '../../store/dashboardSlice';
import dataLogSlice from '../../store/dataLogSlice';
import flowSlice from '../../store/flowSlice';
import preferenceSlice from '../../store/preferenceSlice';
import {
  activeDashboardIdSelector,
  activeDashboardLabelSelector,
  activeDashboardSelector,
  dashboardListVisibleSelector,
  dashboardLockedSelector,
  dashboardReadySelector,
  dashboardSidePanelIdSelector,
  orderedPredefinedDashboardSelector,
  orderedUserDashboardSelector,
  showFlowSelector,
  widgetListVisibleSelector,
  modulesSelector,
  orderedModuleDashboardSelector,
} from '../../store/selectors';
import {
  currentThemeSelector,
  betaModeSelector,
} from '../../store/selectors/preferenceSelectors';
import widgetSlice from '../../store/widgetSlice';
import { dataToExportedDashboard } from '../../util/conversionDTO';
import {
  DASHBOARD_IMPORT_BUTTON,
  DASHBOARD_LIST_BUTTON,
  DASHBOARD_LOCK_BUTTON,
  DASHBOARD_TABS_LIST,
  DASH_APP,
  LOGOUT_BUTTON,
  SHOW_WIDGET_LIST_BUTTON,
  SIDE_BAR,
  TOGGLE_FLOW_DASHBOARD,
} from '../../util/testids';
import { ConfigEditor } from '../ConfigEditor/ConfigEditor';
import { FileImport } from '../FileImport';
import DashboardContainerWrapper from './DashboardContainerWrapper';
import DashboardTabList from './DashboardTabList';
import DataTools from './DataTools';
import { DASHBOARD_HEADER_HEIGHT, HeaderWidgets } from './HeaderWidgets';
import SidePanelButton from './SidePanelButton';
import { SidePanelComponent } from './SidePanelComponent';
import WidgetTabList from './WidgetTabList';
import { Config, ModuleDTO } from '@OrigamiEnergyLtd/ui-node-services';
import { NotificationEvent } from '@OrigamiEnergyLtd/ui-utilities';
import { useWidgetRegistry } from '../WidgetsRegistryProvider';
import { ModuleLabel } from './ModuleLabel/ModuleLabel';
import { isDashboardEditable } from '../../util/dashboard';
import { TriggerModulePublishButton } from '../ModuleTools/TriggerModulePublishButton';
import { PublishModuleDrawer } from '../ModuleTools/PublishModuleDrawer';
import ThemeControl from './ThemeControl';
import { ThemeName } from '@OrigamiEnergyLtd/design-tokens';

type DashboardSelection = {
  activeDashboard?: Dashboard;
  activeDashboardId?: string;
  activeDashboardLabel?: string;
  userDashboards: Dashboard[];
  predefinedDashboards: Dashboard[];
  moduleDashboards: Dashboard[];
  locked: boolean;
  isDashboardListVisible: boolean;
  isWidgetListVisible: boolean;
  themeName: ThemeName;
  dashboardSidePanelId?: string;
  dashboardReady: boolean;
  flowShown: boolean;
  modules: ModuleDTO[];
  betaMode: boolean;
};

type FlowToggleButtonProps = {
  text: string;
  icon: ReactNode;
  onClick: () => void;
  active: boolean;
};
const FlowToggleButton = ({
  text,
  icon,
  onClick,
  active,
}: FlowToggleButtonProps) => {
  const style = { color: active ? 'white' : '#A5A5A5' };
  return (
    <button
      data-testid={`${TOGGLE_FLOW_DASHBOARD}-${text}`}
      style={{
        ...style,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        padding: '3px 16px',
        background: 'none',
        border: 0,
      }}
      onClick={onClick}
      type="button"
    >
      {icon}
      <span style={{ marginLeft: 6 }}>{text}</span>
    </button>
  );
};

const mapState = createStructuredSelector<RootState, DashboardSelection>({
  activeDashboardId: activeDashboardIdSelector,
  activeDashboard: activeDashboardSelector,
  activeDashboardLabel: activeDashboardLabelSelector,
  userDashboards: orderedUserDashboardSelector,
  predefinedDashboards: orderedPredefinedDashboardSelector,
  moduleDashboards: orderedModuleDashboardSelector,
  locked: dashboardLockedSelector,
  isDashboardListVisible: dashboardListVisibleSelector,
  isWidgetListVisible: widgetListVisibleSelector,
  themeName: currentThemeSelector,
  dashboardSidePanelId: dashboardSidePanelIdSelector,
  dashboardReady: dashboardReadySelector,
  flowShown: showFlowSelector,
  modules: modulesSelector,
  betaMode: betaModeSelector,
});
const mapDispatch = {
  importDashboard: dashboardSlice.actions.importDashboardRequest,
  onCloneDashboard: dashboardSlice.actions.cloneDashboardRequest,
  onDashboardSelect: dashboardSlice.actions.selectDashboardRequest,
  onNewDashboard: dashboardSlice.actions.addDashboardRequest,
  onUpdateDashboard: dashboardSlice.actions.updateDashboardRequest,
  onRemoveDashboard: dashboardSlice.actions.removeDashboardRequest,
  onLogout: dashboardSlice.actions.logout,
  setDashboardListVisible: dashboardSlice.actions.dashboardShowList,
  setWidgetListVisible: dashboardSlice.actions.widgetShowList,
  onSelectSidePanel: dashboardSlice.actions.selectSidePanel,
  addWidget: widgetSlice.actions.addWidgetRequest,
  toggleDashboardLock: dashboardSlice.actions.toggleDashboardLock,
  setDataLogPaneVisible: dataLogSlice.actions.setIsVisible,
  setShowFlow: flowSlice.actions.setShow,
  updateTheme: preferenceSlice.actions.updateThemeRequest,
  exportDashboard: dashboardSlice.actions.exportDashboard,
};
const connector = connect(mapState, mapDispatch);
type DashboardProps = ConnectedProps<typeof connector>;

const DashboardComponent: FC<DashboardProps> = ({
  exportDashboard,
  importDashboard,
  activeDashboard,
  activeDashboardId,
  activeDashboardLabel,
  userDashboards,
  predefinedDashboards,
  moduleDashboards,
  locked,
  isDashboardListVisible,
  isWidgetListVisible,
  onCloneDashboard,
  onDashboardSelect,
  onNewDashboard,
  onUpdateDashboard,
  onRemoveDashboard,
  onSelectSidePanel,
  onLogout,
  setDashboardListVisible,
  setShowFlow,
  setWidgetListVisible,
  addWidget,
  toggleDashboardLock,
  dashboardSidePanelId,
  dashboardReady,
  flowShown,
  modules,
  betaMode,
}) => {
  useTitle(`Dashboard ${activeDashboard?.label || ''}`);

  const widgetRegistry = useWidgetRegistry();

  const dashboardUrlDetails = extractDashboardDetailsFromUrl();
  const dashboardLayout =
    (activeDashboard?.layout &&
      DASHBOARD_LAYOUTS_MAP[activeDashboard?.layout]) ||
    DASHBOARD_DEFAULT_LAYOUT;

  const leftSidePanelSelected = dashboardLayout?.leftPanels.find(
    (sidePanel: SidePanel) => sidePanel.id === dashboardSidePanelId,
  );

  const leftPanels = dashboardLayout?.leftPanels.map((sidePanel: SidePanel) => (
    <SidePanelComponent
      key={sidePanel.id}
      widgetDefinition={sidePanel.widget}
      visible={sidePanel.id === leftSidePanelSelected?.id}
    />
  ));

  const ability = useContext(AbilityContext);

  useEffect(() => {
    setShowFlow(false);
  }, [activeDashboard]);

  const topRightButtons = (
    <>
      {betaMode && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 16px',
            // NB: This is a hack because the `SelectDropdown` component has a wrapping div with a margin-bottom of 0.5rem. Without countering this, it can't be vertically centered in the bar
            marginTop: '0.5rem',
          }}
        >
          <ThemeControl />
        </div>
      )}

      {/* Toggle between Flow and Dashboard view. */}
      <Can I={PermissionAction.DEBUG} a={PermissionSubject.DASHBOARD}>
        {dashboardReady &&
          (isDashboardEditable(activeDashboard) ||
            ability.can(
              PermissionAction.ADMIN,
              PermissionSubject.DASHBOARD,
            )) && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 16px',
              }}
            >
              <FlowToggleButton
                text="Dashboard"
                icon={<DashboardToggleIcon style={{ fontSize: 20 }} />}
                onClick={() => setShowFlow(false)}
                active={!flowShown}
              />
              <FlowToggleButton
                text="Flow"
                onClick={() => setShowFlow(true)}
                active={flowShown}
                icon={<FlowIcon style={{ fontSize: 20 }} />}
              />
            </div>
          )}
      </Can>

      {/* Publish Module Button. */}
      <Can I={PermissionAction.PUBLISH} a={PermissionSubject.MODULE}>
        {!locked && isDashboardEditable(activeDashboard) && (
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TriggerModulePublishButton />
          </div>
        )}
      </Can>

      {/* Add Widget Button */}
      {!locked && isDashboardEditable(activeDashboard) && (
        <Tooltip title="Add modules">
          <IconButton
            data-testid={SHOW_WIDGET_LIST_BUTTON}
            onClick={() => setWidgetListVisible(true)}
            size="small"
          >
            <AddIcon size={24} />
          </IconButton>
        </Tooltip>
      )}

      {/* "Import Dashboard" Button */}
      {!locked && (
        <Tooltip title="Import dashboard">
          <IconButton size="small" data-testid={DASHBOARD_IMPORT_BUTTON}>
            <FileImport
              onFileChange={(data: any) =>
                importDashboard(dataToExportedDashboard(data))
              }
            >
              <ImportIcon size={24} />
            </FileImport>
          </IconButton>
        </Tooltip>
      )}

      {/* Drawer that pops out which allows you to select widgets */}
      <WidgetTabList
        setVisible={setWidgetListVisible}
        visible={isWidgetListVisible}
        modules={modules}
        addWidget={(widgetType, hidden = false, initialConfig?: Config) => {
          if (hidden && !flowShown) {
            const widgetName = widgetRegistry.getItemByType(widgetType)?.label;
            document.dispatchEvent(
              new NotificationEvent({
                type: 'notification',
                message: `${
                  widgetName || widgetType
                } widget has been added but is not visible in Dashboard view. Please switch to Flow view to access.`,
              }),
            );
          }
          addWidget({
            dashboardId: activeDashboardId!,
            type: widgetType,
            initialConfig,
          });
          setWidgetListVisible(false);
        }}
      />

      <Can I={PermissionAction.UNLOCK} a={PermissionSubject.DASHBOARD}>
        <Tooltip title={locked ? 'Unlock dashboard' : 'Lock dashboard'}>
          <IconButton
            data-testid={DASHBOARD_LOCK_BUTTON}
            onClick={toggleDashboardLock}
            size="small"
            data-testvalue={locked}
          >
            {locked ? <LockIcon size={24} /> : <LockOpenIcon size={24} />}
          </IconButton>
        </Tooltip>
      </Can>
      <Tooltip title={'Log out'}>
        <IconButton data-testid={LOGOUT_BUTTON} onClick={onLogout} size="small">
          <LogoutIcon data-testid="logout-button-icon" size={24} />
        </IconButton>
      </Tooltip>
    </>
  );

  return (
    <div
      data-testid={DASH_APP}
      style={{ display: 'flex', flexDirection: 'column', maxHeight: '100vh' }}
    >
      <Paper
        data-testid={DASHBOARD_TABS_LIST}
        elevation={3}
        style={{
          height: DASHBOARD_HEADER_HEIGHT,
          display: 'flex',
          flex: '0 0 auto',
        }}
      >
        {/* LEFT */}
        <Avatar
          alt="Origami Logo"
          src="/OrigamiLogo.png"
          style={{ width: 20, height: 20, margin: 5 }}
        />

        <Tooltip title="Select a dashboard">
          <Button
            data-testid={DASHBOARD_LIST_BUTTON}
            onClick={() => setDashboardListVisible(true)}
            upper
            style={{ fontSize: '0.8125rem' }}
          >
            {activeDashboardLabel || 'Origami'}
            {activeDashboard?.moduleMeta !== undefined && (
              <ModuleLabel style={{ marginLeft: '5px' }}>
                {activeDashboard.moduleMeta.label}
              </ModuleLabel>
            )}
          </Button>
        </Tooltip>

        <DashboardTabList
          userDashboards={userDashboards}
          predefinedDashboards={predefinedDashboards}
          moduleDashboards={moduleDashboards}
          activeId={activeDashboardId}
          onDashboardSelect={(dashboardId) => onDashboardSelect(dashboardId)}
          onNewDashboard={onNewDashboard}
          onRenameDashboard={({ id, label }) =>
            onUpdateDashboard({ id, changes: { label } })
          }
          onSetLayout={(id, layout) => {
            onUpdateDashboard({ id, changes: { layout } });
          }}
          onClone={(id: string) => onCloneDashboard(id)}
          onRemoveDashboard={onRemoveDashboard}
          setVisible={setDashboardListVisible}
          locked={locked}
          visible={isDashboardListVisible}
          onDashboardExport={(id) => {
            exportDashboard(id);
          }}
        />

        <HeaderWidgets
          widgetDefinitions={dashboardLayout.headers.left}
          dashboardReady={dashboardReady}
        />

        {/* CENTER */}
        <div
          style={{
            flexGrow: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <HeaderWidgets
            widgetDefinitions={dashboardLayout.headers.center}
            dashboardReady={dashboardReady}
          />
        </div>

        {/* RIGHT */}
        <HeaderWidgets
          widgetDefinitions={dashboardLayout.headers.right}
          dashboardReady={dashboardReady}
        />

        {topRightButtons}
      </Paper>

      <div
        style={{
          flex: '1 1 100%',
          display: 'flex',
          flexDirection: 'row',
          overflow: 'auto',
        }}
      >
        <Paper
          elevation={3}
          data-testid={SIDE_BAR}
          style={{
            position: 'fixed',
            top: 42,
            left: 0,
            bottom: 0,
            right: 10,
            width: 40,
          }}
        >
          {dashboardLayout.leftPanels.map((sidePanel: SidePanel) => {
            return (
              <SidePanelButton
                key={sidePanel.id}
                onClick={() =>
                  onSelectSidePanel(
                    sidePanel.id === leftSidePanelSelected?.id
                      ? undefined
                      : sidePanel.id,
                  )
                }
                selected={sidePanel.id === leftSidePanelSelected?.id}
                label={sidePanel.label}
              />
            );
          })}
        </Paper>
        <div
          style={{
            flex: '0 1 100%',
            marginLeft: 40,
          }}
        >
          <div style={{ position: 'fixed', top: 40 }}>
            {dashboardReady ? leftPanels : undefined}
          </div>
          <div
            style={{
              marginLeft: leftSidePanelSelected?.widget.maxWidth || 0,
            }}
          >
            {activeDashboardId && <DashboardContainerWrapper />}
            {activeDashboardId && <ConfigEditor />}
            {activeDashboardId && <PublishModuleDrawer />}
            {activeDashboardId && !activeDashboard && (
              <div>
                {`Dashboard "${dashboardUrlDetails.label}" with id "${dashboardUrlDetails.id}" doesn't exist`}
              </div>
            )}
          </div>
        </div>
      </div>
      <DataTools />
    </div>
  );
};

export default connector(DashboardComponent);
