import { useDataManager } from '@OrigamiEnergyLtd/react-ui-components';
import { Data } from '@OrigamiEnergyLtd/ui-utilities';
import { useEffect, useState } from 'react';

// Reference to an empty datasource value
export const DATASOURCE_NO_VALUE_EMITTED = {};

export const useSubscribeToDatasources = (datasources: string[]) => {
  const [valueMap, setValueMap] = useState({});
  const dataManager = useDataManager();
  useEffect(() => {
    setValueMap(
      datasources.reduce(
        (acc, cur) => ({ ...acc, [cur]: DATASOURCE_NO_VALUE_EMITTED }),
        {},
      ),
    );
    const unsub = dataManager.subscribe(
      datasources,
      ({ data, datasource }: Data<any>) =>
        setValueMap((previous) => ({ ...previous, [datasource]: data })),
    );
    return () => unsub();
  }, [datasources, dataManager]);

  return valueMap;
};
