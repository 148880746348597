import { Theme, ThemeName } from '@OrigamiEnergyLtd/design-tokens';

const getTheme = async (themeName: ThemeName) => {
  let theme: Theme | undefined;

  switch (themeName) {
    default:
    case 'dark':
      theme = (await import('@OrigamiEnergyLtd/design-tokens/build/dark'))
        .default;
      break;

    case 'darkv2':
      theme = (await import('@OrigamiEnergyLtd/design-tokens/build/darkv2'))
        .default;
      break;

    case 'light':
      theme = (await import('@OrigamiEnergyLtd/design-tokens/build/light'))
        .default;
      break;
  }

  return theme;
};

export default getTheme;
