import styled from 'styled-components';
import Form from '@rjsf/core';

const ConfigForm = styled(Form)`
  min-inline-size: unset;
  fieldset {
    min-inline-size: unset;
  }

  #root {
    height: auto;
    width: auto;
    min-inline-size: unset;
  }

  // FIXME: Would like to hide form > #root__description here rather than this one, but that doesn't seem to work. Internal RJSF CSS fun perhaps?
  #root > #root__description {
    display: none;
  }

  .form-group.field {
    margin-bottom: 0.5rem;
  }

  .form-group.field.field-string,
  .form-group.field.field-number {
    display: flex;
  }

  .form-group.field input,
  .form-group.field select,
  .form-group.field textarea {
    padding: 2px;
  }

  // FIXME: No idea why we need the background color here, but without it the select seems to get a gray (?) fill...
  .form-group.field.has-error > input,
  .form-group.field.has-error > select,
  .form-group.field.has-error > textarea {
    border: 3px solid red;
    background-color: #ffffff;
  }

  fieldset > p {
    white-space: pre-wrap;
  }

  .form-control {
    flex-grow: 1;
  }

  .array-item-remove {
    background-color: ${({ theme }) => theme.palette.button.danger.color};
    border: none;
    color: ${({ theme }) => theme.palette.button.danger.textColor};

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.75rem;
  }

  .btn-add {
    background-color: ${({ theme }) => theme.palette.button.primary.color};
    border: none;
    color: ${({ theme }) => theme.palette.button.primary.textColor};
    padding: 0.25rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.75rem;
    border-radius: 0.125rem;
  }
  button:focus {
    outline: 0;
  }

  .btn-add::after {
    content: '+ Add';
  }
  .array-item-move-up::after {
    content: 'Move Up';
  }
  .array-item-move-down::after {
    content: 'Move Down';
  }
  .array-item-remove::after {
    content: 'Remove';
  }

  label.control-label {
    display: inline-block;
    min-width: 115px;
  }

  .field-array .form-group.field-object fieldset {
    border: none;
  }

  .array-item {
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 0.5rem;
  }

  p.array-item-add {
    margin-block-end: 0px;
  }

  input.form-control {
    color: #000;
  }
`;

export const StyledForm = ConfigForm;
