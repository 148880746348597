import { DashboardLayout } from './layout';

export const fakeAssetsDateLayout: DashboardLayout = {
  id: 'fakeAssetsDateLayout',
  label: 'Date and multi-select assets (custom)',
  headers: {
    center: [
      {
        type: 'DatetimeRangeWidget',
        config: {
          datasources: ['U_range'],
          dataOutputs: ['dateFrom', 'dateTo', 'range', 'granularity'],
          dataUrlOutputs: ['range'],
          useInlineInputs: true,
          autoUpdate: true,
          updateSecond: 1,
          startOfDayRelativeToMidnightHours: 0,
          datasourceFrom: 'dateFrom',
          datasourceTo: 'dateTo',
          dataOutputTimeRange: 'range',
          dataOutputGranularity: 'granularity',
          header: 'Date Picker',
          defaultRange: {
            range: 'today',
          },
        },
        maxWidth: 850,
      },
    ],
    left: [],
    right: [],
  },
  leftPanels: [
    {
      id: 'assetsSelector',
      label: 'Assets',
      widget: {
        type: 'AssetListWidget',
        maxWidth: 250,
        config: {
          header: 'Asset list panel',
          datasource: 'assets',
          datasourceOptions: {
            keyPath: 'id',
            labelPath: 'name',
            parentPath: 'parent',
          },
          dataOutput: 'selectedAssets',
          dataOutputs: ['selectedAssets'],
          detailOutput: 'selectedAssetDetails',
          datasources: [],
          multiSelectMode: true,
          default: 'all',
        },
      },
    },
  ],
  rightPanels: [],
  type: 'Experimental',
};
