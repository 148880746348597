import validator from '@rjsf/validator-ajv8';
import { produce } from 'immer';
import React, { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  moduleSlice,
  PublishModuleActionPayload,
} from '../../store/moduleSlice';
import {
  allWidgetsDatasourceSelector,
  allWidgetsDataOutputSelector,
  moduleConfigDrawerErrorToDisplaySelector,
  moduleConfigDrawerIsSubmittingSelector,
  allWidgetSelector,
  moduleByIdSelector,
  activeDashboardSelector,
  organisationsSelector,
} from '../../store/selectors';
import {
  PUBLISH_MODULE_EDITOR_ERROR_FIELD,
  PUBLISH_MODULE_EDITOR_FORM,
} from '../../util/testids';
import { ConfigEditorHeader } from '../ConfigEditor/ConfigEditorHeader';
import { StyledForm } from '../ConfigEditorControls/StyledForm';
import {
  createModuleConfigRjsfSchema,
  moduleConfigUiSchema,
} from './ModuleConfigRjsfSchema';
import moduleToPublishPayload from './moduleToPublishPayload';

const FormSubmitErrorDisplay = () => {
  const errorToDisplay = useSelector(moduleConfigDrawerErrorToDisplaySelector);
  if (!errorToDisplay) return <></>;
  return (
    <div
      data-testid={PUBLISH_MODULE_EDITOR_ERROR_FIELD}
      style={{ width: '100%', margin: '10px 40px 10px 10px' }}
    >
      Error Submitting Request: {errorToDisplay}
    </div>
  );
};

// Oh God this is horrible... I'm so sorry. Please kill this with fire... please?
// RJSF v5.x appears to have an issue whereby defaults aren't being applied to form data in some circumstances
// In these cases we may need to apply relevant defaults manually on form change to ensure the schema continues to work properly...
export const setPublishModuleFormDataDefaults = (formData: any): any => {
  const updateFormData: (draft: any) => void = (draft) => {
    draft.config?.datasources?.forEach((ds: any) => {
      if (ds.configType === 'user') {
        if (
          ds.defaultValueOptions?.inputType === 'dropdown' &&
          ds.defaultValueOptions?.isMultiSelect === undefined
        ) {
          ds.defaultValueOptions.isMultiSelect = false;
        }
      }
    });
  };

  const newFormData = produce<any>(formData, updateFormData);
  return newFormData;
};

export const PublishModuleForm = () => {
  const internalDatasources = useSelector(allWidgetsDatasourceSelector);
  const internalDataOutputs = useSelector(allWidgetsDataOutputSelector);
  const allWidgets = useSelector(allWidgetSelector);

  const dashboard = useSelector(activeDashboardSelector);
  const module = useSelector((state: RootState) =>
    moduleByIdSelector(state, dashboard?.moduleMeta?.id ?? ''),
  );
  const organisations = useSelector(organisationsSelector);

  const moduleConfigRjsfSchema = createModuleConfigRjsfSchema(
    internalDatasources,
    internalDataOutputs,
    allWidgets,
    organisations,
  );

  const isDisabled = useSelector(moduleConfigDrawerIsSubmittingSelector);
  const dispatch = useDispatch();

  const defaultPayload = useMemo(
    () => moduleToPublishPayload(module, [...allWidgets.map((w) => w.id)]),
    [module, allWidgets],
  );

  const [formData, setFormData] =
    useState<Partial<PublishModuleActionPayload['moduleInfo']>>(defaultPayload);

  const onSubmit = (formData: any) => {
    const fd = produce(
      formData as PublishModuleActionPayload['moduleInfo'],
      (draft: PublishModuleActionPayload['moduleInfo']) => {
        if (draft?.readPermission?.isGlobal) {
          draft.readPermission.organisationIds = [];
        }
      },
    );

    dispatch(moduleSlice.actions.publishModule({ moduleInfo: fd }));
  };

  const onDiscard = () => {
    setFormData({});
    dispatch(moduleSlice.actions.requestDrawerDiscard());
  };

  const submitRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <ConfigEditorHeader
        label={'Export Dashboard as Module'}
        onClose={onDiscard}
        canSave={!isDisabled}
        handleSave={() => {
          if (submitRef.current) {
            submitRef.current.click();
          } else {
            onSubmit(formData);
          }
        }}
        handleDiscard={onDiscard}
      />
      <div
        data-testid={PUBLISH_MODULE_EDITOR_FORM}
        style={{
          height: 'calc(100% - 40px)',
          overflow: 'auto',
          padding: '15px',
        }}
      >
        <StyledForm
          schema={moduleConfigRjsfSchema}
          uiSchema={moduleConfigUiSchema}
          disabled={isDisabled}
          validator={validator}
          onChange={(evt) => {
            const newFormData = setPublishModuleFormDataDefaults(evt.formData);
            setFormData(newFormData);
          }}
          formData={formData}
          onSubmit={(e: any) => onSubmit(e.formData)}
        >
          <button style={{ display: 'none' }} type="submit" ref={submitRef} />
        </StyledForm>
        <FormSubmitErrorDisplay />
      </div>
    </>
  );
};
