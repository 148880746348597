import { DataPaths } from '@OrigamiEnergyLtd/react-ui-components';
import { JSONPath } from 'jsonpath-plus';
import { processDataPaths } from './processDataPaths';

export const renameDataOutputs = (
  config: any,
  dataPaths: DataPaths,
  suffix = '-copy',
) => {
  const paths: string[][] = [];

  const transform = (_: unknown, __: unknown, { path }: { path: string }) => {
    paths.push(JSONPath.toPathArray(path));
  };

  dataPaths.dataOutputs.flatMap((path) =>
    JSONPath<string[]>({ path, json: config, callback: transform }),
  );

  paths.forEach((path) => {
    path.reduce((acc, p, i) => {
      if (p === '$') return acc;
      if (i + 1 === path.length) {
        acc[p] = `${acc[p]}${suffix}`;
      }
      return acc[p];
    }, config);
  });

  return processDataPaths(config, dataPaths);
};
