import { Dashboard } from '../store/dashboardSlice';

export const URL_PARAM_PREFIX = 'U_';

export const URL_UPDATE = 'urlUpdate';

export const extractDashboardDetailsFromUrl = () => {
  const regex = /\/dashboard\/(.*)\/(.*?)(\?.*)?$/;
  const regexResult = regex.exec(window.location.hash);
  const label = regexResult?.[1];
  const id = regexResult?.[2];
  return { id, label };
};

const sanitizeDashboardLabel = (label = '') =>
  label.replace(/[&/\\#,+$~%.'":*?<>{}\s]/g, '_');

const computeDashboardUrl = (dashboard?: Dashboard): string | undefined => {
  const dashboardDetails = extractDashboardDetailsFromUrl();
  const urlFriendlyLabel = sanitizeDashboardLabel(dashboard?.label);

  if (
    dashboardDetails.id === dashboard?.id &&
    dashboardDetails.label === urlFriendlyLabel
  ) {
    return;
  }
  if (dashboard === undefined) {
    return '#';
  }
  return `#/dashboard/${urlFriendlyLabel}/${dashboard.id}`;
};

const hasUrlChanged = (newUrl: string) => {
  return window.location.hash !== newUrl;
};

export const updateDashboardUrl = (dashboard?: Dashboard) => {
  if (!dashboard) {
    clearUrl();
    return;
  }
  const url = computeDashboardUrl(dashboard);
  if (url) {
    if (!hasUrlChanged(url)) {
      return;
    }
    history.pushState(null, '', url);
    const event = new Event(URL_UPDATE);
    window.dispatchEvent(event);
  }
};
export const clearUrl = () => {
  if (!hasUrlChanged('#')) {
    return;
  }
  history.pushState(null, '', '#');
};

export const extractParamsFromUrl = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const paramsMapArray = Array.from(urlSearchParams.entries()).reduce(
    (acc, [key, value]) => {
      try {
        return { ...acc, [key]: JSON.parse(value) };
      } catch (e) {
        console.warn('cannot parse param', key, value, e);
        return acc;
      }
    },
    {} as { [key: string]: any },
  );

  return paramsMapArray;
};

export const updateUrlParam = (key: string, value: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (value === undefined) urlSearchParams.delete(key);
  else urlSearchParams.set(key, JSON.stringify(value));
  const url = new URL(window.location.href);
  url.search = urlSearchParams.toString();

  if (url.toString() !== window.location.href) {
    history.pushState(null, '', url.toString());
    const event = new Event(URL_UPDATE);
    window.dispatchEvent(event);
  }
};

export const updateDashboardAndParams = (
  dashboard: Dashboard,
  params?: {
    [key: string]: any;
  },
) => {
  const origHref = window.location.href;

  const url = new URL(window.location.href);

  if (params) {
    const urlSearchParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([param, value]) => {
      if (value === undefined) urlSearchParams.delete(param);
      else urlSearchParams.set(param, JSON.stringify(value));
    });
    url.search = urlSearchParams.toString();
  }
  const dashboardUrl = computeDashboardUrl(dashboard);
  if (dashboardUrl) {
    url.hash = dashboardUrl;
  }

  if (url.toString() !== origHref) {
    history.pushState(null, '', url.toString());
    const event = new Event(URL_UPDATE);
    window.dispatchEvent(event);
  }
};
