import { Button } from '@OrigamiEnergyLtd/react-ui-components';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { moduleSlice } from '../../store/moduleSlice';

export type PublishModuleButtonProps = Record<string, unknown>;
export const TriggerModulePublishButton = (props: PublishModuleButtonProps) => {
  const dispatch = useDispatch();
  const action = moduleSlice.actions.requestDrawerOpen();
  const onClick = useCallback(() => dispatch(action), []);
  const out = (
    <Button variant={'primary'} onClick={onClick}>
      Publish Module
    </Button>
  );
  return out;
};
