import { emptyLayout } from './empty';
import { assetsDateLayout } from './assetsDateLayout';
import { dateLayout } from './dateLayout';
import { assetsLayout } from './assetsLayout';
import { fakeAssetsDateLayout } from './fakeAssetsDateLayout';
import { fakeSingleAssetDateLayout } from './fakeSingleAssetDateLayout';
import { DashboardLayout } from './layout';
import { multiAssetsDateLayout } from './multiAssetsDateLayout';
import { fakeAssetsLayout } from './fakeAssetsLayout';
import { dateLayoutLast8Next4Hr } from './dateLayoutLast8Next4Hr';
import { dateLayoutLast16Next8Hr } from './dateLayoutLast16Next8Hr';
import { dateLayoutLastYesterdayTodayTomorrow } from './dateLayoutLastYesterdayTodayTomorrow';
import { multiAssetsLayout } from './multiAssetLayout';

export const DASHBOARD_DEFAULT_LAYOUT = emptyLayout;

export const DASHBOARD_LAYOUTS = [
  emptyLayout,
  dateLayout,
  dateLayoutLast8Next4Hr,
  dateLayoutLast16Next8Hr,
  dateLayoutLastYesterdayTodayTomorrow,
  assetsLayout,
  assetsDateLayout,
  multiAssetsLayout,
  multiAssetsDateLayout,
  fakeAssetsLayout,
  fakeSingleAssetDateLayout,
  fakeAssetsDateLayout,
];
export const DASHBOARD_LAYOUTS_MAP: {
  [key: string]: DashboardLayout;
} = DASHBOARD_LAYOUTS.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
