import SizeDrawer from '../SizeDrawer/SizeDrawer';
import React from 'react';
import { PublishModuleForm } from './PublishModuleForm';
import { useSelector } from 'react-redux';
import { moduleConfigDrawerIsOpenSelector } from '../../store/selectors';
import { PUBLISH_MODULE_EDITOR_DRAWER } from '../../util/testids';

type PublishModuleDrawerProps = {
  isOpen: boolean;
};

export const PublishModuleDrawer = () => {
  const isOpen = useSelector(moduleConfigDrawerIsOpenSelector);
  const props = { isOpen };
  return <UnconnectedPublishModuleDrawer {...props} />;
};

const InnerElement = (props: PublishModuleDrawerProps) => {
  // A bit of a hack to clear data in the form between opening and closing
  if (!props.isOpen) return <></>;
  return <PublishModuleForm />;
};

const UnconnectedPublishModuleDrawer = (props: PublishModuleDrawerProps) => {
  const out = (
    <SizeDrawer
      data-testid={PUBLISH_MODULE_EDITOR_DRAWER}
      defaultSize={750}
      open={props.isOpen}
      anchor={'right'}
    >
      <InnerElement isOpen={props.isOpen} />
    </SizeDrawer>
  );

  return out;
};
