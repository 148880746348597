import { WidgetDTO } from '@OrigamiEnergyLtd/ui-node-services';
import { Dashboard } from '../store/dashboardSlice';
import { FlowPosition } from '../store/flowSlice';

export const exportDashboardToJson = (
  dashboard: Dashboard,
  widgets: WidgetDTO[],
  flow: FlowPosition[] = [],
) => {
  const exportedData = { version: '2', dashboard, widgets, flow };

  const a = document.createElement('a');
  const file = new Blob([JSON.stringify(exportedData, null, 2)], {
    type: 'text/plain',
  });
  a.href = URL.createObjectURL(file);
  a.download = dashboard.label + '.json';
  a.click();
  a.remove();
};
