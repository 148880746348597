import { WidgetCategory } from '@OrigamiEnergyLtd/react-ui-components';
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ArrowDownIcon,
  ArrowUpIcon,
  WidgetsIcon,
  ModulesIcon,
} from '@OrigamiEnergyLtd/react-ui-components';
import { Config, ModuleDTO } from '@OrigamiEnergyLtd/ui-node-services';
import React, { FC, ReactNode, useMemo, useState } from 'react';
import {
  WIDGET_TABS_LIST,
  WIDGET_TAB_CATEGORY_ITEMS_SUFFIX,
  WIDGET_TAB_CATEGORY_ITEM_SUFFIX,
  WIDGET_TAB_CATEGORY_TOGGLE_SUFFIX,
  WIDGET_TAB_LIST_SUFFIX,
  WIDGET_TAB_MODULE_PREFIX,
  WIDGET_TAB_PARENT_TOGGLE_SUFFIX,
  WIDGET_TAB_WIDGET_PREFIX,
} from '../../../util/testids';
import { useWidgetRegistry } from '../../WidgetsRegistryProvider';
type AddWidgetCallback = (
  widgetType: string,
  hidden: boolean,
  initialConfig?: Config,
) => void;

export interface IWidgetTabListProps {
  visible: boolean;
  setVisible: (status: boolean) => void;
  addWidget: AddWidgetCallback;
  modules: ModuleDTO[];
}

const WIDGET_CATEGORY_ORDERED: WidgetCategory[] = [
  'Visualisation',
  'Controls',
  'Data Ingestion',
  'Data Transformation',
  'Legacy (do not use)',
];

type Category<T> = {
  category: string;
  items: T[];
};

type CategoryItem = { category: WidgetCategory | string; label: string };

const categoriseItems = <Item extends CategoryItem>(
  categories: { category: string; items: Item[] }[],
  item: Item,
) => {
  const currentCategoryIndex = categories
    .map(({ category }) => category)
    .indexOf(item?.category ?? 'Other');
  if (currentCategoryIndex === -1) {
    categories.push({ category: item?.category ?? 'Other', items: [item] });
    return categories;
  }
  categories[currentCategoryIndex].items.push(item);
  return categories;
};

const GroupedList = <Item extends CategoryItem>({
  items,
  addWidget,
  title,
  categorySort,
  icon,
}: {
  title: 'Modules' | 'Widgets';
  items: Item[];
  addWidget: (item: Item) => void;
  categorySort: (a: Category<Item>, b: Category<Item>) => number;
  icon: ReactNode;
}) => {
  const PREFIX =
    title === 'Modules' ? WIDGET_TAB_MODULE_PREFIX : WIDGET_TAB_WIDGET_PREFIX;
  const [expanded, setExpanded] = useState(true);
  const groupedItems = useMemo(
    () =>
      [...items]
        .sort((a, b) => a.label.localeCompare(b.label))
        .reduce<Category<Item>[]>(
          (acc, curr) => categoriseItems<Item>(acc, curr),
          [],
        )
        .sort(categorySort),
    [items],
  );
  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <ListItem
        button
        onClick={() => setExpanded(!expanded)}
        data-testid={`${PREFIX}-${WIDGET_TAB_LIST_SUFFIX}`}
      >
        {icon}
        <ListItemText primary={title} />
        {expanded ? <ArrowUpIcon size={24} /> : <ArrowDownIcon size={24} />}
      </ListItem>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        data-testid={`${PREFIX}-${WIDGET_TAB_PARENT_TOGGLE_SUFFIX}`}
      >
        <div style={{ paddingLeft: 16 }}>
          {groupedItems.map((group) => (
            <ListGroup
              group={group}
              addWidget={addWidget}
              key={group.category}
              ID_PREFIX={PREFIX}
            />
          ))}
        </div>
      </Collapse>
      <Divider />
    </>
  );
};

const ListGroup = <Item extends CategoryItem>({
  group,
  addWidget,
  ID_PREFIX,
}: {
  ID_PREFIX: string;
  group: Category<Item>;
  addWidget: (item: Item) => void;
}) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <>
      <ListItem
        button
        onClick={() => setExpanded(!expanded)}
        data-testid={`${ID_PREFIX}-${WIDGET_TAB_CATEGORY_TOGGLE_SUFFIX}-${group.category}`}
      >
        <ListItemText primary={group.category} />
        {expanded ? <ArrowUpIcon size={24} /> : <ArrowDownIcon size={24} />}
      </ListItem>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        data-testid={`${ID_PREFIX}-${WIDGET_TAB_CATEGORY_ITEMS_SUFFIX}-${group.category}`}
        key={`${ID_PREFIX}-${WIDGET_TAB_CATEGORY_ITEMS_SUFFIX}-${group.category}`}
      >
        <div style={{ paddingLeft: 16 }}>
          {group.items.map((item, ii) => {
            // need to prefix the key with the index because we don't always have Ids
            const replacedLabel = item.label.replace(/\s+/g, '');
            const key = `${replacedLabel}-${ii}`;
            return (
              <ListItem
                data-testid={`${ID_PREFIX}-${WIDGET_TAB_CATEGORY_ITEM_SUFFIX}-${replacedLabel}`}
                button
                onClick={() => addWidget(item)}
                key={key}
              >
                {item.label}
              </ListItem>
            );
          })}
        </div>
      </Collapse>
    </>
  );
};

const WidgetTabList: FC<IWidgetTabListProps> = ({
  visible,
  setVisible,
  addWidget,
  modules,
}) => {
  const widgets = useWidgetRegistry()
    .getItems()
    .filter((w) => WIDGET_CATEGORY_ORDERED.includes(w.category));

  const widgetIcon = <WidgetsIcon size={24} style={{ marginRight: 15 }} />;
  const modulesIcon = <ModulesIcon size={24} style={{ marginRight: 15 }} />;
  return (
    <div data-testid={WIDGET_TABS_LIST}>
      <Drawer anchor="right" open={visible} onClose={() => setVisible(false)}>
        <div style={{ width: 250 }}>
          <List>
            <GroupedList
              title="Modules"
              categorySort={(a, b) =>
                a.category > b.category ? 1 : a.category < b.category ? -1 : 0
              }
              items={modules}
              icon={modulesIcon}
              addWidget={({
                config: { initialModuleWidgetConfig, hidden = false },
                label,
              }) => {
                const type = hidden ? 'module-widget-hidden' : 'module-widget';
                const config = {
                  header: label,
                  ...initialModuleWidgetConfig,
                };
                addWidget(type, hidden, config);
              }}
            />
            <GroupedList
              title="Widgets"
              categorySort={(a, b) =>
                WIDGET_CATEGORY_ORDERED.indexOf(a.category as WidgetCategory) -
                WIDGET_CATEGORY_ORDERED.indexOf(b.category as WidgetCategory)
              }
              icon={widgetIcon}
              items={widgets}
              addWidget={(item) => addWidget(item.key, item.visible === false)}
            />
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default WidgetTabList;
