import React, { FC, useState, useMemo, useEffect } from 'react';

import {
  DATA_LOG_CONTAINER,
  DATA_LOG_EVENT_LIST,
  DATA_LOG_EVENT_LIST_HEADER,
  DATA_LOG_EVENT_LIST_ITEM,
  DATA_LOG_EVENT_COUNT_LIST,
  DATA_LOG_EVENT_COUNT_LIST_HEADER,
  DATA_LOG_EVENT_COUNT_LIST_ALLITEMS,
  DATA_LOG_EVENT_COUNT_LIST_ITEM,
  DATA_LOG_TOGGLE_LOGGING_BUTTON,
  DATA_LOG_DATASOURCE_FILTER_BUTTON,
  DATA_LOG_DATASOURCE_FILTER_OPTION,
  DATA_LOG_WIDGET_FILTER_OPTION,
  DATA_LOG_WIDGET_FILTER_BUTTON,
} from '../../../util/testids';
import { DataLog } from '../../../store/dataLogSlice';
import {
  ListItem,
  List,
  Divider,
  ListItemText,
  ListSubheader,
  Button,
  DropdownButton,
} from '@OrigamiEnergyLtd/react-ui-components';
import { dateTime } from '@OrigamiEnergyLtd/ui-utilities';
import styled from 'styled-components';
import { DatalogFilters, DEFAULT_FILTERS } from './filterstate';
import { WidgetDatasourceMapping } from '../../../store/widgetSlice';

const noEventsDetectedListItem = (
  <ListItem
    style={{
      opacity: 0.6,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }}
  >
    <div>No events detected</div>
  </ListItem>
);

const DropdownOption = styled.div`
  &:hover {
    background-color: rgba(128, 128, 128, 0.2);
  }
  padding: 0.25rem;
`;

const eventCountDataToListItems = (counts: {
  [datasource: string]: number;
}) => {
  const keys = Object.keys(counts);
  return keys.length > 0 ? (
    <ListItem
      key={DATA_LOG_EVENT_COUNT_LIST_ALLITEMS}
      style={{
        opacity: 0.6,
        display: 'flex',
        alignItems: 'left',
        flexDirection: 'column',
      }}
    >
      <div
        key="eventCountList"
        style={{
          width: '100%',
          height: '100%',
          alignItems: 'left',
        }}
      >
        {keys.map((key) => (
          <div
            key={`eventCountList_${key}`}
            style={{
              maxWidth: '20rem',
              display: 'inline-block',
              paddingLeft: 5,
              paddingRight: 5,
            }}
            data-testid={DATA_LOG_EVENT_COUNT_LIST_ITEM}
          >
            <ListItemText>{`${key}: ${counts[key]};`}</ListItemText>
          </div>
        ))}
      </div>
    </ListItem>
  ) : (
    noEventsDetectedListItem
  );
};

export type DataLogContentPassProps = {
  events: DataLog[];
  eventCounts: { [datasource: string]: number };
  isLoggingEnabled: boolean;
  setLoggingEnabled: (status: boolean) => void;
  widgetDatasourceMappings: WidgetDatasourceMapping[];
};

const DataLogContent: FC<DataLogContentPassProps> = ({
  events,
  eventCounts,
  isLoggingEnabled,
  setLoggingEnabled,
  widgetDatasourceMappings,
}) => {
  const [filters, setFilters] = useState<DatalogFilters>(DEFAULT_FILTERS);
  const [datasources, setDatasources] = useState<string[]>([]);

  const eventDatasources: string[] = useMemo(() => {
    return Array.from(
      new Set(
        Object.keys(eventCounts).concat(
          ...events.map((e) => e.data.datasource),
        ),
      ),
    ).sort();
  }, [events, eventCounts]);

  useEffect(() => {
    setDatasources(eventDatasources);
  }, [eventDatasources, setDatasources]);

  const dataForDisplay: {
    selectedDatasourceEvents: DataLog[];
    selectedDatasourceCounts: { [datasource: string]: number };
  } = useMemo(() => {
    const selectedDatasourceEvents = filters.filterDataLog(events);
    const selectedDatasourceCounts = filters.filterCounts(eventCounts);
    return {
      selectedDatasourceEvents,
      selectedDatasourceCounts,
    };
  }, [events, eventCounts, filters]);

  const datasourceFilter = (
    <DropdownButton
      data-testid={DATA_LOG_DATASOURCE_FILTER_BUTTON}
      style={{ marginRight: 15 }}
      variant="secondary"
      singleClick={true}
      label={filters.datasource ?? 'Datasources'}
      buttonVisibilitypopupThreshold={0}
    >
      <div style={{ maxHeight: 400, overflowY: 'auto' }}>
        <DropdownOption
          key={'all'}
          data-testid={DATA_LOG_DATASOURCE_FILTER_OPTION}
          data-testvalue={`${DATA_LOG_DATASOURCE_FILTER_OPTION}-all`}
          onClick={() => {
            setFilters((f) => f.nextFromDatasource(undefined));
          }}
        >
          All
        </DropdownOption>
        {datasources.map((ds) => (
          <DropdownOption
            key={ds}
            onClick={() => setFilters((f) => f.nextFromDatasource(ds))}
            data-testid={DATA_LOG_DATASOURCE_FILTER_OPTION}
            data-testvalue={`${DATA_LOG_DATASOURCE_FILTER_OPTION}-${ds}`}
          >
            {ds}
          </DropdownOption>
        ))}
      </div>
    </DropdownButton>
  );

  const widgetMappingToListLabel = (w: WidgetDatasourceMapping) => {
    const checkedHeader = w.header ?? '## NO HEADER ##';
    const checkedType = w.type ?? '## MISSING TYPE ##';
    const labelText = `${checkedHeader} (${checkedType})`;

    return labelText;
  };

  const widgetMappingToListItem = (w: WidgetDatasourceMapping) => {
    const labelText = widgetMappingToListLabel(w);

    const out = (
      <DropdownOption
        key={w.id}
        onClick={() => setFilters((f) => f.nextFromWidget(w))}
        data-testid={DATA_LOG_WIDGET_FILTER_OPTION}
        data-testvalue={`${DATA_LOG_WIDGET_FILTER_OPTION}-${w.id}`}
      >
        {labelText}
      </DropdownOption>
    );

    return out;
  };

  const widgetFilter = (
    <DropdownButton
      data-testid={DATA_LOG_WIDGET_FILTER_BUTTON}
      style={{ marginRight: 15 }}
      variant="secondary"
      singleClick={true}
      label={
        filters.widget ? widgetMappingToListLabel(filters.widget) : 'Widgets'
      }
    >
      <div style={{ maxHeight: 400, overflowY: 'auto' }}>
        <DropdownOption
          key={'all'}
          data-testid={DATA_LOG_WIDGET_FILTER_OPTION}
          data-testvalue={`${DATA_LOG_WIDGET_FILTER_OPTION}-all`}
          onClick={() => {
            setFilters((f) => f.nextFromWidget(undefined));
          }}
        >
          All
        </DropdownOption>
        {widgetDatasourceMappings.map(widgetMappingToListItem)}
      </div>
    </DropdownButton>
  );

  const toEventListItem = ({ timestamp, data }: DataLog) => (
    <ListItem
      button
      key={`${timestamp}-${data.datasource}`}
      id={`${timestamp}-${data.datasource}`}
      data-testvalue={`${DATA_LOG_EVENT_LIST_ITEM}-${timestamp}-${data.datasource}`}
      data-testid={DATA_LOG_EVENT_LIST_ITEM}
    >
      <ListItemText>
        {`${dateTime(timestamp).format(
          'HH:mm:ss',
        )}: Received event on datasource ${data.datasource}. Data: ${
          data.data
        }`}
      </ListItemText>
    </ListItem>
  );

  return (
    <div
      style={{
        width: '100%',
        height: '460px',
        maxHeight: '460px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        padding: 12,
      }}
    >
      <div style={{ display: 'flex', flex: '0 0 auto' }}>
        <Button
          data-testid={DATA_LOG_TOGGLE_LOGGING_BUTTON}
          style={{ marginRight: 15 }}
          variant={isLoggingEnabled ? 'primary' : 'danger'}
          onClick={() => setLoggingEnabled(!isLoggingEnabled)}
        >
          <div style={{ margin: 'auto' }}>
            {isLoggingEnabled ? 'Disable' : 'Enable'}
          </div>
        </Button>
        {datasourceFilter}
        {widgetFilter}
      </div>
      <div
        data-testid={DATA_LOG_CONTAINER}
        className="datalog-container"
        style={{ flex: '0 1 100%', display: 'flex', flexDirection: 'column' }}
      >
        <Divider />
        <List
          data-testid={DATA_LOG_EVENT_COUNT_LIST}
          style={{ display: 'flex', flexDirection: 'column', flex: '0 1 auto' }}
        >
          <ListSubheader
            data-testid={DATA_LOG_EVENT_COUNT_LIST_HEADER}
            disableSticky={true}
          >
            Event Counts
          </ListSubheader>
          {eventCountDataToListItems(dataForDisplay.selectedDatasourceCounts)}
        </List>
        <Divider />
        <List
          data-testid={DATA_LOG_EVENT_LIST}
          style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}
        >
          <ListSubheader
            data-testid={DATA_LOG_EVENT_LIST_HEADER}
            disableSticky={true}
          >
            Recent Events
          </ListSubheader>
          {dataForDisplay.selectedDatasourceEvents.length > 0
            ? dataForDisplay.selectedDatasourceEvents.map(toEventListItem)
            : noEventsDetectedListItem}
        </List>
      </div>
    </div>
  );
};

export default React.memo(DataLogContent);
