import {
  Node,
  Edge,
  applyNodeChanges,
  NodeChange,
  applyEdgeChanges,
  EdgeChange,
} from 'reactflow';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FlowPosition = {
  widgetId: string;
  x: number;
  y: number;
};

export const getFlowPositions = ({
  id,
  position: { x, y },
}: Node): FlowPosition => ({ widgetId: id, x, y });

export type FlowState = {
  show: boolean;
  nodes: Node[];
  edges: Edge[];
  positions: FlowPosition[];
};

export const initialState: FlowState = {
  show: false,
  nodes: [],
  edges: [],
  positions: [],
};

export const flowSlice = createSlice({
  name: 'flow',
  initialState,
  reducers: {
    setShow: (state, { payload }: PayloadAction<boolean>) => {
      state.show = payload;
    },
    onNodesChange: (
      state,
      { payload: changes }: PayloadAction<NodeChange[]>,
    ) => {
      const nodes: Node[] = applyNodeChanges(changes, state.nodes);
      const positions = nodes.map(getFlowPositions);

      return {
        ...state,
        nodes,
        positions,
      };
    },

    onEdgesChange: (
      state,
      { payload: changes }: PayloadAction<EdgeChange[]>,
    ) => ({
      ...state,
      edges: applyEdgeChanges(changes, state.edges),
    }),

    setFlow: (state, { payload: positions }: PayloadAction<FlowPosition[]>) => {
      return {
        ...initialState,
        positions,
      };
    },

    resetPositions: () => {
      // do nothing
    },

    setGraph: (state, { payload }: PayloadAction<Partial<FlowState>>) => ({
      ...state,
      ...payload,
    }),
  },
});

export default flowSlice;
