import { combineReducers } from 'redux';
import widgetSlice from './widgetSlice';
import flowSlice from './flowSlice';
import dashboardSlice from './dashboardSlice';
import dataLogSlice from './dataLogSlice';
import preferenceSlice from './preferenceSlice';
import notificationsSlice from './notificationsSlice';
import configEditorSlice from './configEditorSlice';
import { moduleSlice } from './moduleSlice';

export const reducer = combineReducers({
  preferences: preferenceSlice.reducer,
  widgets: widgetSlice.reducer,
  flow: flowSlice.reducer,
  dashboards: dashboardSlice.reducer,
  dataLog: dataLogSlice.reducer,
  notifications: notificationsSlice.reducer,
  configEditor: configEditorSlice.reducer,
  modules: moduleSlice.reducer,
});

export type RootState = ReturnType<typeof reducer>;
