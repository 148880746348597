import { Config } from '@OrigamiEnergyLtd/ui-node-services';
import { DataPaths } from '@OrigamiEnergyLtd/react-ui-components';
import { JSONPath } from 'jsonpath-plus';
import { getDatasourcesFromConfig } from './utils/getDatasourcesFromConfig';

export const processDataPaths = (formData: any, dataPaths: DataPaths) => {
  const dynamicableDataSources = getDatasourcesFromConfig(formData, true);
  const config: Config = Object.entries<string[]>(dataPaths as any).reduce(
    (acc, [key, paths]) => {
      // extract values from data paths and append to existing array if existent
      acc[key] = paths
        .flatMap((path) => JSONPath<string[]>({ path, json: formData }))
        .filter(
          (value) => value !== undefined && value !== null && value !== '',
        );
      return acc;
    },
    formData,
  );

  config.dataOutputs = Array.from(new Set(config.dataOutputs));

  config.datasources = Array.from(
    new Set(
      // make sure we properly deal with values that resolve to a dot-notated string (e.g. foo.bar). The datasource is only the first part!
      (
        config.datasources
          ?.filter((ds) => typeof ds === 'string')
          .map((ds: string) => ds?.split('.')[0]) ?? ([] as string[])
      ).concat(dynamicableDataSources),
    ),
  );
  // All URL outputs should be prefixed with "U_"
  config.dataUrlOutputs = Array.from(
    new Set(config.dataUrlOutputs?.map((value: string) => `U_${value}`)),
  );

  return config;
};
