/*
 * When trim and dedupe is set to true it will trim everything after (and including) the first dot of each datasource then deduplicate them
 */
export const getDatasourcesFromConfig = (
  config: any,
  trimAndDedupe = false,
): string[] => {
  const datasources = Object.keys(config).reduce((acc: string[], key) => {
    if (Array.isArray(config[key])) {
      return acc.concat(
        ...config[key].map((item: any) => getDatasourcesFromConfig(item)),
      );
    }
    if (typeof config[key] === 'object' && config[key] !== null) {
      const subject: any = config[key];
      if (isDynamicable(subject)) {
        acc.push(subject.datasource);
        return acc;
      }
      return acc.concat(getDatasourcesFromConfig(subject));
    }
    return acc;
  }, []);
  if (trimAndDedupe) {
    return Array.from(
      new Set(
        datasources
          .map((ds) => ds.split('.')[0])
          .filter((ds) => ds !== undefined),
      ),
    );
  }
  return datasources;
};

const isDynamicable = (subject: any) => {
  return (
    Object.prototype.hasOwnProperty.call(subject, 'dynamic') &&
    subject.dynamic === true &&
    Object.prototype.hasOwnProperty.call(subject, 'datasource')
  );
};
