import { DashboardLayout } from './layout';

export const fakeAssetsLayout: DashboardLayout = {
  id: 'fakeAssetsLayout',
  label: 'Assets only (custom)',
  headers: {
    center: [],
    left: [],
    right: [],
  },
  leftPanels: [
    {
      id: 'assetsSelector',
      label: 'Assets',
      widget: {
        type: 'AssetListWidget',
        maxWidth: 250,
        config: {
          header: 'Asset list panel',
          datasource: 'assets',
          datasourceOptions: {
            keyPath: 'id',
            labelPath: 'name',
            parentPath: 'parent',
          },
          dataOutput: 'selectedAssets',
          dataOutputs: ['selectedAssets'],
          detailOutput: 'selectedAssetDetails',
          dataUrlOutputs: ['selectedAssets'],
          datasources: ['U_selectedAssets'],
          datasourceSelection: 'U_selectedAssets',
          multiSelectMode: false,
          default: 'first',
        },
      },
    },
  ],
  rightPanels: [],
  type: 'Experimental',
};
