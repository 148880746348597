import {
  themes,
  Theme,
  ThemeName,
  defaultThemeName,
} from '@OrigamiEnergyLtd/design-tokens';
import {
  DropdownSelect,
  LabelledComponent,
} from '@OrigamiEnergyLtd/react-ui-components';
import React from 'react';
import { useSelector } from 'react-redux';
import preferenceSlice from '../../../store/preferenceSlice';
import { currentThemeSelector } from '../../../store/selectors/preferenceSelectors';
import { useDispatch } from 'react-redux';
import { ThemeChangeEvent } from '@OrigamiEnergyLtd/ui-utilities';
import { THEME_DROPDOWN } from '../../../util/testids';
import getTheme from '../../../theme';

const ThemeControl = () => {
  const dispatch = useDispatch();
  const currentTheme = useSelector(currentThemeSelector);

  const onUpdateTheme = (themeName: ThemeName = defaultThemeName) => {
    dispatch(preferenceSlice.actions.updateThemeRequest(themeName));

    getTheme(themeName).then((theme) => {
      window.dispatchEvent(new ThemeChangeEvent<Theme>(theme));
    });
  };

  return (
    <LabelledComponent labelFor={'themeSelect'} label={'Theme'}>
      <DropdownSelect
        data-testid={THEME_DROPDOWN}
        options={themes.map(({ theme, displayName }) => ({
          key: theme,
          value: theme,
          displayText: displayName,
        }))}
        onChange={(evt) => {
          onUpdateTheme(evt.target.value as ThemeName);
        }}
        defaultValue={currentTheme ?? defaultThemeName}
        name={'themeSelect'}
      />
    </LabelledComponent>
  );
};

export default ThemeControl;
