import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { NotificationPayload } from '@OrigamiEnergyLtd/ui-utilities';

export type Notification = {
  timestamp: number;
  details: Omit<NotificationPayload, 'info'>;
};

export type NotificationsBaseState = {
  notifications: Notification[]; // only store the last 200 notifications to prevent performance issues
};

export const STORED_NOTIFICATION_LIMIT = 200;

export const notificationAdapter = createEntityAdapter<Notification>();

export const initialState: NotificationsBaseState = {
  notifications: [],
};

export type NotificationsState = typeof initialState;

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    onReceiveNotification: (
      state,
      action: PayloadAction<NotificationPayload>,
    ) => {
      // Always add new notifications to the top of the array
      if (state.notifications.length === STORED_NOTIFICATION_LIMIT) {
        state.notifications.pop();
      }

      state.notifications.unshift({
        timestamp: new Date().getTime(),
        // Deliberately don't log action.payload.info, as these may be anything (e.g. stack traces) and could get quite significant.
        details: {
          type: action.payload.type,
          message: action.payload.message,
          context: action.payload.context,
        },
      });
    },
    reset: (state) => {
      state.notifications = [];
    },
  },
});

export default notificationsSlice;
