import {
  NotificationEvent,
  NotificationPayload,
} from '@OrigamiEnergyLtd/ui-utilities';
import { useEffect } from 'react';

export const useNotificationEventListener = (
  listenerFn: (notificationPayload: NotificationPayload) => void,
) => {
  useEffect(() => {
    const eventListener = ((notificationEvent: NotificationEvent) => {
      if (!notificationEvent.bubbles) return;
      listenerFn(notificationEvent.detail.payload);
    }) as EventListener;

    document.addEventListener(NotificationEvent.EVENT_TYPE, eventListener);
    return () =>
      document.removeEventListener(NotificationEvent.EVENT_TYPE, eventListener);
  }, [listenerFn]);
};
