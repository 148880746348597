import { DashboardDTO } from '@OrigamiEnergyLtd/ui-node-services';
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  Update,
} from '@reduxjs/toolkit';
import { FlowPosition } from './flowSlice';
import { Widget } from './widgetSlice';

export type Dashboard = Omit<DashboardDTO, 'widgets' | 'flow'>;

export type DashboardsBaseState = {
  dashboardReady: boolean;
  dashboardLocked: boolean;
  activeDashboard?: string;
  showList: boolean;
  showWidgetList: boolean;
  sidePanel?: string;
};

export type ExportedDashboard = {
  dashboard: Dashboard;
  widgets: Widget[];
  flow?: FlowPosition[];
};

export const dashboardAdapter = createEntityAdapter<Dashboard>({
  selectId: (dashboard) => dashboard.id,
});

export type SelectDashboardActionType = 'LOAD' | 'HISTORY';
export type SelectDashboardPayload = {
  dashboardId: string;
  actionType: SelectDashboardActionType;
};

export const initialState = dashboardAdapter.getInitialState({
  dashboardReady: false,
  dashboardLocked: false,
  showList: false,
  showWidgetList: false,
} as DashboardsBaseState);

export type DashboardState = typeof initialState;

export const dashboardSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    importDashboardRequest: (
      state,
      action: PayloadAction<ExportedDashboard>,
    ) => {
      // do nothing
    },
    addDashboardRequest: (state, action: PayloadAction<string>) => {
      state.activeDashboard = undefined;
    },
    addDashboard: (state, action: PayloadAction<Dashboard>) => {
      dashboardAdapter.upsertOne(state, action);
    },
    cloneDashboardRequest: (state, action: PayloadAction<string>) => {
      // do nothing
    },
    cloneDashboard: (state, action: PayloadAction<Dashboard>) => {
      dashboardAdapter.addOne(state, action.payload);
    },
    updateDashboardRequest: (
      state,
      action: PayloadAction<Update<Dashboard>>,
    ) => {
      // do nothing
    },
    updateDashboard: (state, { payload }: PayloadAction<Update<Dashboard>>) => {
      dashboardAdapter.updateOne(state, payload);
    },
    removeDashboardRequest: (state, action: PayloadAction<string>) => {
      // do nothing
    },
    removeDashboard: (state, action: PayloadAction<string>) => {
      dashboardAdapter.removeOne(state, action);

      if (state.activeDashboard === action.payload) {
        delete state.activeDashboard;
      }
    },
    dashboardShowList: (state, action: PayloadAction<boolean>) => {
      state.showList = action.payload;
    },
    widgetShowList: (state, action: PayloadAction<boolean>) => {
      state.showWidgetList = !!state.activeDashboard && action.payload;
    },
    toggleDashboardLock: (state) => {
      state.dashboardLocked = !state.dashboardLocked;
    },
    setDashboardReady: (state, action: PayloadAction<boolean>) => {
      state.dashboardReady = action.payload;
    },
    selectDashboard: (
      state,
      { payload: dashboardId }: PayloadAction<string | undefined>,
    ) => {},
    updateActiveDashboard: (
      state,
      { payload: dashboardId }: PayloadAction<string | undefined>,
    ) => {
      state.activeDashboard = dashboardId;
    },
    selectDashboardRequest: (
      state,
      { payload: dashboardId }: PayloadAction<string | undefined>,
    ) => {
      // do nothing
    },
    selectSidePanel: (state, action: PayloadAction<string | undefined>) => {
      state.sidePanel = action.payload;
    },
    logout: (state) => {
      // do nothing
    },
    exportDashboard: (state, action: PayloadAction<string>) => {
      // do nothing
    },
  },
});

export default dashboardSlice;
