export const DASH_APP = 'dash-app';
export const DASH_BOARD = 'dash-board';
export const NEW_DASHBOARD_BUTTON = 'new-dashboard-button';
export const DASHBOARD_DATA_BUTTON = 'dashboard-data-button';
export const DASHBOARD_LIST_BUTTON = 'dashboard-list-button';
export const DASHBOARD_LOCK_BUTTON = 'dashboard-lock-button';
export const DASHBOARD_DATA_LOG_BUTTON = 'dashboard-data-button-icon';
export const DASHBOARD_EXPORT_BUTTON = 'dashboard-export-button';
export const DASHBOARD_IMPORT_BUTTON = 'dashboard-import-button';
export const DASHBOARD_TABS_LIST = 'dashboard-tabs-list';
export const DASHBOARD_LIST = 'dashboard-list';
export const DASHBOARD_WRAPPER = 'dashboard-wrapper';
export const DASHBOARD_TAB = 'dashboard-tab';
export const DASHBOARD_ITEM_RENAME = 'dashboard-item-rename';
export const DASHBOARD_ITEM_RENAME_INPUT = 'dashboard-item-rename-input';
export const DASHBOARD_ITEM_LAYOUTS = 'dashboard-item-layouts';
export const DASHBOARD_ITEM_LAYOUT = 'dashboard-item-layout';
export const DASHBOARD_ITEM_CLONE = 'dashboard-item-clone';
export const DASHBOARD_ITEM_REMOVE = 'dashboard-item-remove';
export const DASHBOARD_GRID = 'dashboard-grid';

export const DATA_TABS_DATA = 'data-tabs-data';
export const DATA_TABS_LOG = 'data-tabs-log';
export const DATA_TABS_DATA_NO_WIDGET = 'data-tabs-data-no-widget';
export const DATA_TABS_DATA_WIDGET = 'data-tabs-data-widget';

export const DATA_LOG_CONTAINER = 'data-log-container';
export const DATA_LOG_BUTTON = 'data-log-button';
export const DATA_LOG_ENABLE_TOGGLE_BUTTON = 'data-log-enable-toggle';
export const DATA_LOG_EVENT_COUNT_LIST = 'data-log-event-count-list';
export const DATA_LOG_EVENT_COUNT_LIST_HEADER =
  'data-log-event-count-list-header';
export const DATA_LOG_EVENT_COUNT_LIST_ALLITEMS =
  'data-log-event-count-list-allitems';
export const DATA_LOG_EVENT_COUNT_LIST_ITEM = 'data-log-event-count-list-item';
export const DATA_LOG_EVENT_LIST = 'data-log-event-list';
export const DATA_LOG_EVENT_LIST_HEADER = 'data-log-event-list-header';
export const DATA_LOG_EVENT_LIST_ITEM = 'data-log-event-list-item';
export const DATA_LOG_TOGGLE_LOGGING_BUTTON = 'data-log-toggle-logging-button';
export const DATA_LOG_DATASOURCE_FILTER_BUTTON =
  'data-log-datasource-filter-button';
export const DATA_LOG_DATASOURCE_FILTER_OPTION =
  'data-log-datasource-filter-option';
export const DATA_LOG_WIDGET_FILTER_BUTTON = 'data-log-widget-filter-button';
export const DATA_LOG_WIDGET_FILTER_OPTION = 'data-log-widget-filter-option';

export const DATA_TOOLS_TOGGLE_BUTTON = 'data-tools-toggle-button';
export const DATA_TOOLS_CONTAINER = 'data-tools-container';

export const WIDGET_CONTAINER = 'widget-container';
export const DASHBOARD_SWITCH_THEME_BUTTON = 'dashboard-switch-theme-button';
export const LOGOUT_BUTTON = 'logout-button';
export const WIDGET = 'widget';
export const WIDGET_PLACEHOLDER = 'widget-placeholder';

export const WIDGET_TABS_LIST = 'widget-tabs-list';
export const WIDGET_TAB_WIDGET_PREFIX = 'widget-tabs-widget';
export const WIDGET_TAB_MODULE_PREFIX = 'widget-tabs-module';

export const WIDGET_TAB_LIST_SUFFIX = `list`;
export const WIDGET_TAB_PARENT_TOGGLE_SUFFIX = `parent-toggle`;
export const WIDGET_TAB_CATEGORY_TOGGLE_SUFFIX = `category-toggle`;
export const WIDGET_TAB_CATEGORY_ITEMS_SUFFIX = `category-items`;
export const WIDGET_TAB_CATEGORY_ITEM_SUFFIX = `category-item`;

export const WIDGET_NOT_FOUND = 'widget-not-found';
export const WIDGET_WEBCOMPONENT_WRAPPER = 'widget-webcomponent-wrapper';
export const WIDGET_WEBCOMPONENT = 'widget-webcomponent';
export const SHOW_WIDGET_LIST_BUTTON = 'show-widget-list-button';
export const REMOVE_WIDGET_BUTTON = 'remove-widget-button';
export const DUPLICATE_WIDGET_BUTTON = 'duplicate-widget-button';
export const WIDGET_HEADER_LABEL = 'widget-header-label';
export const WIDGET_HEADER_TOGGLEVIEW_BUTTON =
  'widget-header-toggleview-button';
export const TOGGLE_FLOW_DASHBOARD = 'toggle-flow-dashboard';

export const FLOW_CONTAINER = 'flow-container';
export const FLOW_BODY = 'rf__wrapper';
export const FLOW_NODE_WIDGET_TYPE = 'flow-node-widget-type';
export const FLOW_NODE = 'flow-node';
export const FLOW_NODE_WIDGET_NAME = 'flow-node-widget-name';
export const FLOW_HANDLE = 'flow-handle';
export const FLOW_HANDLE_INPUT_ICON = 'flow-handle-input-icon';
export const FLOW_HANDLE_OUTPUT_ICON = 'flow-handle-output-icon';
export const FLOW_HANDLE_OK_ICON = 'flow-handle-ok-icon';
export const FLOW_HANDLE_WARNING_ICON = 'flow-handle-warning-icon';
export const FLOW_HANDLE_ALERT_ICON = 'flow-handle-alert-icon';
export const FLOW_NODE_DUPLICATE = 'flow-node-duplicate';
export const FLOW_NODE_CONFIG = 'flow-node-config';
export const FLOW_RESET = 'flow-reset';

export const FILE_IMPORT_INPUT = 'file-import-input';

export const SIDE_PANEL = 'side-panel';
export const SIDE_BAR = 'side-bar';
export const HEADER_WIDGET = 'header-widget';

export const SIDE_PANEL_BUTTON = 'side-panel-button';
export const SIDE_PANEL_BUTTON_LABEL = 'side-panel-button-label';

export const CONFIG_EDITOR = 'config-editor';
export const EDITOR_CONFIG_HEADER = 'config-editor-header';
export const EDITOR_CONFIG_HEADER_LABEL = 'config-editor-header-label';
export const CONFIG_EDITOR_SUBMIT = 'config-editor-submit';
export const CONFIG_EDITOR_DISCARD = 'config-editor-discard';

export const EDITOR_JSON_CONFIG_CONTAINER =
  'config-editor-json-config-container';
export const EDITOR_JSON_CONFIG_SAVE = 'config-editor-json-config-save';
export const EDITOR_CONFIG_JSON_TOGGLE = 'config-editor-toggle-json';
export const EDITOR_CONFIG_CLOSE = 'config-editor-close';

export const SIZE_DRAWER = 'size-drawer';
export const SIZE_DRAWER_DRAGGER = 'size-drawer-dragger';

export const CONFIRM_POPUP = 'CONFIRM_POPUP';

export const BUTTON_CONFIRMATION_YES = 'BUTTON_CONFIRMATION_YES';
export const BUTTON_CONFIRMATION_NO = 'BUTTON_CONFIRMATION_NO';

export const DATA_TAB_INPUTS_CONTAINER = 'DATA_TAB_INPUTS_CONTAINER';
export const DATA_TAB_OUTPUTS_CONTAINER = 'DATA_TAB_OUTPUTS_CONTAINER';

export const PUBLISH_MODULE_EDITOR_DRAWER = 'PUBLISH_CONFIG_EDITOR_DRAWER';
export const PUBLISH_MODULE_EDITOR_FORM = 'PUBLISH_CONFIG_EDITOR_FORM';
export const PUBLISH_MODULE_EDITOR_ERROR_FIELD =
  'PUBLISH_CONFIG_EDITOR_ERROR_FIELD';

export const THEME_DROPDOWN = 'THEME_DROPDOWN';
