import {
  Button,
  DashboardContainer,
  widgetToWidgetDTO,
} from '@OrigamiEnergyLtd/react-ui-components';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { Layouts } from 'react-grid-layout';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { updateUrlParam, URL_PARAM_PREFIX } from '../../../route/routeUtils';
import { RootState } from '../../../store';
import configEditorSlice from '../../../store/configEditorSlice';
import { Dashboard } from '../../../store/dashboardSlice';
import dataLogSlice from '../../../store/dataLogSlice';
import {
  activeDashboardIdSelector,
  activeDashboardSelector,
  allWidgetSelector,
  dashboardLockedSelector,
  dashboardReadySelector,
  highlightedWidgetIdSelector,
  layoutsSelector,
  showFlowSelector,
} from '../../../store/selectors';
import widgetSlice, { rowHeight, Widget } from '../../../store/widgetSlice';
import { useWidgetRegistry } from '../../WidgetsRegistryProvider';
import Flow from '../../Flow/Flow';
import { DASHBOARD_WRAPPER, FLOW_RESET } from '../../../util/testids';
import flowSlice from '../../../store/flowSlice';
import { isDashboardEditable } from '../../../util/dashboard';

type DashboardContainerSelection = {
  dashboardId?: string;
  dashboard?: Dashboard;
  widgets: Widget[];
  locked: boolean;
  layouts: Layouts;
  dashboardReady: boolean;
  highlightedId: string;
  showFlow: boolean;
};

const mapState = createStructuredSelector<
  RootState,
  DashboardContainerSelection
>({
  dashboardId: activeDashboardIdSelector,
  dashboard: activeDashboardSelector,
  widgets: allWidgetSelector,
  locked: dashboardLockedSelector,
  layouts: layoutsSelector,
  dashboardReady: dashboardReadySelector,
  highlightedId: highlightedWidgetIdSelector,
  showFlow: showFlowSelector,
});
const mapDispatch = {
  handleRemove: widgetSlice.actions.removeWidgetRequest,
  handleDuplicate: widgetSlice.actions.duplicateWidgetRequest,
  updateConfig: widgetSlice.actions.updateConfigRequest,
  updateMaxHeightGrid: widgetSlice.actions.updateMaxHeightGrid,
  onLayoutChange: widgetSlice.actions.updateLayoutsRequest,
  openConfigEditorRequest: configEditorSlice.actions.openConfigRequest,
  onReceiveData: dataLogSlice.actions.onReceiveData,
  onWidgetDatasourceUpdated: dataLogSlice.actions.setWidgetDatasources,
  resetFlow: flowSlice.actions.resetPositions,
};
const connector = connect(mapState, mapDispatch);
type DashboardContainerWrapperProps = ConnectedProps<typeof connector>;

const HIDDEN_DIV_STYLE: CSSProperties = {
  visibility: 'hidden',
  overflow: 'hidden',
  height: 0,
  width: 0,
};

const useRenderedFlow = (
  dashboardId: string | undefined,
  showFlow: boolean,
) => {
  const [flowRendered, setFlowRendered] = useState(false);
  useEffect(() => {
    setFlowRendered(false);
  }, [dashboardId]);
  useEffect(() => {
    if (!flowRendered && showFlow) {
      setFlowRendered(true);
    }
  }, [showFlow]);
  return flowRendered;
};

const DashboardContainerWrapper: FC<DashboardContainerWrapperProps> = ({
  dashboardId,
  dashboard,
  widgets,
  locked,
  layouts,
  handleRemove,
  handleDuplicate,
  updateConfig,
  onLayoutChange,
  updateMaxHeightGrid,
  dashboardReady,
  openConfigEditorRequest,
  highlightedId,
  onReceiveData,
  onWidgetDatasourceUpdated,
  showFlow,
  resetFlow,
}) => {
  const flowRendered = useRenderedFlow(dashboardId, showFlow);
  const widgetRegistry = useWidgetRegistry();
  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <div
        style={showFlow ? HIDDEN_DIV_STYLE : undefined}
        data-testid={DASHBOARD_WRAPPER}
      >
        <DashboardContainer
          dashboardId={dashboardId}
          dashboard={dashboard}
          widgetRegistry={widgetRegistry}
          widgets={widgets.map(widgetToWidgetDTO)}
          locked={locked}
          dashboardReady={dashboardReady}
          layouts={layouts}
          onRemoveWidget={handleRemove}
          updateWidgetConfig={(id, config) =>
            updateConfig({ id, config, persist: false })
          }
          updateMaxHeight={updateMaxHeightGrid}
          onConfigButtonClicked={(widgetId) =>
            openConfigEditorRequest({ widgetId })
          }
          rowHeight={rowHeight}
          highlightedId={highlightedId}
          handleDuplicate={handleDuplicate}
          onLayoutChange={(layouts) => onLayoutChange({ layouts })}
          onReceiveData={(data, sourceWidgetId) =>
            onReceiveData({ data, sourceWidgetId })
          }
          onUrlParamUpdate={(datasource, data) => {
            updateUrlParam(URL_PARAM_PREFIX + datasource, data);
          }}
          onWidgetDatasourceUpdate={(datasources, widgetId) =>
            onWidgetDatasourceUpdated({ widgetId, datasources })
          }
        />
      </div>
      {flowRendered && (
        <div
          style={
            showFlow
              ? { height: '100%', width: '100%', position: 'relative' }
              : HIDDEN_DIV_STYLE
          }
        >
          {!locked && isDashboardEditable(dashboard) && (
            <div
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                zIndex: 10,
              }}
            >
              <Button
                data-testid={FLOW_RESET}
                variant="secondary"
                onClick={resetFlow}
                aria-label="Reset Positions"
              >
                Reset
              </Button>
            </div>
          )}
          <Flow />
        </div>
      )}
    </div>
  );
};

export default connector(DashboardContainerWrapper);
