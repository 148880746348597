import React, { ErrorInfo } from 'react';

export default class UnexpectedErrorBoundary extends React.Component<
  { children: any },
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Unexpected error', error, errorInfo);
    alert('Unexpected error, click to reload the page.');
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
