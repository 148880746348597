import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityId,
} from '@reduxjs/toolkit';
import { Layouts } from 'react-grid-layout';
import { WidgetRegistration } from '@OrigamiEnergyLtd/react-ui-components';
import { Config } from '@OrigamiEnergyLtd/ui-node-services';

export type WidgetDefinition = {
  config?: Config;
  type: string;
};

export type Widget = {
  id: string;
  dashboardId: string;
  layouts: Layouts;
  subType?: string;
} & WidgetDefinition;

export type WidgetTypeRegistrationMap = {
  [key: string]: WidgetRegistration;
};

export type AddWidgetPayload = {
  dashboardId: string;
  type: string;
  initialConfig?: Config;
};

export type DuplicateWidgetPayload = string;

export type WidgetConfigPayload = {
  id: string;
  config: any;
  persist: boolean;
};

export type UpdateLayoutsRequestPayload = {
  layouts: Layouts;
};

export type WidgetDatasourceMapping = {
  id: string;
  type: string;
  header?: string;
  datasources: string[];
  dataOutputs: string[];
};

export const widgetAdapter = createEntityAdapter<Widget>({
  selectId: (widget) => widget.id,
});

export const initialState = {
  ...widgetAdapter.getInitialState(),
  maxHeightGrid: -1,
  widgetTypeMap: {} as WidgetTypeRegistrationMap,
  highlightWidgetId: '',
};

export const widgetSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    highlightWidget: (state, action: PayloadAction<string>) => {
      state.highlightWidgetId = action.payload;
    },
    addWidgetRequest: (state, action: PayloadAction<AddWidgetPayload>) => {
      // do nothing
    },
    addWidget: (state, action: PayloadAction<Widget>) => {
      widgetAdapter.addOne(state, action.payload);
    },
    duplicateWidgetRequest: (
      state,
      action: PayloadAction<DuplicateWidgetPayload>,
    ) => {
      // do nothing
    },
    updateWidget: (state, { payload: widget }: PayloadAction<Widget>) => {
      widgetAdapter.updateOne(state, { id: widget.id, changes: widget });
    },
    resetWidgetConfig: (state, action: PayloadAction<EntityId>) => {
      const widget = state.entities[action.payload];
      if (widget) {
        widgetAdapter.updateOne(state, {
          id: action.payload,
          changes: { config: { ...widget.config } },
        });
      }
    },
    updateWidgets: widgetAdapter.updateMany,
    setWidgets: widgetAdapter.setAll,
    removeWidgetRequest: (state, action: PayloadAction<string>) => {
      // do nothing
    },
    removeWidget: widgetAdapter.removeOne,
    removeWidgets: widgetAdapter.removeMany,
    updateLayoutsRequest: (
      state,
      action: PayloadAction<UpdateLayoutsRequestPayload>,
    ) => {
      // do nothing
    },
    updateMaxHeightGrid: (state, action: PayloadAction<number>) => {
      state.maxHeightGrid = action.payload;
    },
    updateConfigRequest: (
      state,
      action: PayloadAction<WidgetConfigPayload>,
    ) => {
      // do nothing
    },
    setWidgetTypes: (
      state,
      action: PayloadAction<WidgetTypeRegistrationMap>,
    ) => {
      state.widgetTypeMap = action.payload;
    },
  },
});

export const rowHeight = 10;

export const getLayoutHeight = (widget: Widget) => {
  const widgetHeaderHeight = 10;
  const realRowHeight = rowHeight + 6;
  // TODO: make it responsive according to SM LG layout
  return (
    rowHeight +
    realRowHeight * (widget.layouts.lg[0].h - 1) -
    widgetHeaderHeight
  );
};

export const getMaxFitHeight = (windowHeight: number) => {
  const navBarHeightPx = 42;
  const realRowHeight = rowHeight + 6;

  return Math.floor((windowHeight - navBarHeightPx) / realRowHeight);
};

export default widgetSlice;
