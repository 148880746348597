import { Tab, Tabs } from '@OrigamiEnergyLtd/react-ui-components';
import React, { FC } from 'react';
import { DataLog, TabValues } from '../../../store/dataLogSlice';
import { Widget, WidgetDatasourceMapping } from '../../../store/widgetSlice';
import {
  DATA_TABS_DATA,
  DATA_TABS_DATA_NO_WIDGET,
  DATA_TABS_DATA_WIDGET,
  DATA_TABS_LOG,
} from '../../../util/testids';
import DataToolsBar from '../DataTools/DataToolsBar';
import DataLogContent from './DataLog';
import { WidgetDataDisplay } from './WidgetDataDisplay/WidgetDataDisplay';

export type DataTabsPassProps = {
  events: DataLog[];
  eventCounts: { [datasource: string]: number };
  isLoggingEnabled: boolean;
  setLoggingEnabled: (status: boolean) => void;
  widgetDatasourceMappings: WidgetDatasourceMapping[];
  widgetDataWidget: Widget | undefined;
};

export type DataTabsProps = DataTabsPassProps & {
  show: boolean;
  toggleShow: () => void;
  currentTab: TabValues;
  updateDataTab: (newTab: TabValues) => void;
};

const WidgetDataWithFallback = (props: {
  widgetDataWidget: Widget | undefined;
}) => {
  if (props.widgetDataWidget === undefined)
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <p data-testid={DATA_TABS_DATA_NO_WIDGET}>
          Please select a widget to view its data inputs and outputs.
        </p>
      </div>
    );

  return (
    <div
      data-testid={DATA_TABS_DATA_WIDGET}
      style={{ flex: 1, height: '100%', overflow: 'auto' }}
    >
      <WidgetDataDisplay />
    </div>
  );
};

const DataTabs: FC<DataTabsProps> = ({
  show,
  toggleShow,
  currentTab,
  updateDataTab,
  widgetDataWidget,
  ...dataLogProps
}) => {
  const openAndSetCurrentTab = (tab: TabValues) => {
    if (!show) toggleShow();
    updateDataTab(tab);
  };

  return (
    <div
      style={{
        width: '100%',
        height: show ? '100%' : undefined,
        display: 'flex',
        flexDirection: 'column',
        background: '#1A1A1A',
      }}
    >
      <DataToolsBar show={show} setShow={toggleShow} title="">
        <Tabs
          value={currentTab}
          onChange={(evt, value) => openAndSetCurrentTab(value)}
          indicatorColor="primary"
        >
          <Tab
            data-testid={DATA_TABS_DATA}
            label="Widget Data"
            value={TabValues.DATA}
          />
          <Tab
            data-testid={DATA_TABS_LOG}
            label="Data Log"
            value={TabValues.LOG}
          />
        </Tabs>
      </DataToolsBar>
      {show && currentTab === TabValues.LOG && (
        <DataLogContent {...dataLogProps} />
      )}
      {show && currentTab === TabValues.DATA && (
        <WidgetDataWithFallback widgetDataWidget={widgetDataWidget} />
      )}
    </div>
  );
};

export default React.memo(DataTabs);
