import { configPromise, renderApp } from './renderApp';

import * as serviceWorker from './serviceWorker';

declare global {
  interface ImportMeta {
    env?: { [key: string]: string };
  }
}

configPromise.then(async (config) => {
  await renderApp(config);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
