import { ThemeName, defaultThemeName } from '@OrigamiEnergyLtd/design-tokens';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PreferenceState = {
  theme: ThemeName;
  beta: boolean;
};

const initialState: PreferenceState = {
  theme: defaultThemeName,
  beta: false,
};

const preferenceSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    updateThemeRequest: (state, action: PayloadAction<ThemeName>) => {
      // Triggers saga
    },
    updateTheme: (state, action: PayloadAction<ThemeName>) => {
      state.theme = action.payload;
    },
    toggleBeta: (state) => {
      state.beta = !state.beta;
    },
  },
});

export default preferenceSlice;
