import validator from '@rjsf/validator-ajv8';
import { MetaData } from '@OrigamiEnergyLtd/react-ui-components';
import {
  Config,
  ModuleDataInput,
  ModuleDataOutput,
  ModuleDTO,
} from '@OrigamiEnergyLtd/ui-node-services';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { CONFIG_EDITOR } from '../../util/testids';
import SizeDrawer from '../SizeDrawer/SizeDrawer';
import {
  ConfigJsonEditor,
  CustomEditor,
  CustomTemplate,
  StyledForm,
  useParseMetaData,
} from '../ConfigEditorControls';
import { ConfigEditorHeader } from './ConfigEditorHeader';
import {
  configEditorConfigSelector,
  configEditorMetaDataSelector,
  configDrawerOpenSelector,
  configEditorUnsavedChangesSelector,
  configEditorModuleSelector,
} from '../../store/selectors';
import configEditorSlice from '../../store/configEditorSlice';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store';
import { createStructuredSelector } from 'reselect';
import UnsavedConfigWarning from './UnsavedConfigWarning';
import { RegistryWidgetsType } from '@rjsf/utils';

type DashboardConfigEditorInnerProps = {
  metaData?: MetaData;
  config: any;
  module?: ModuleDTO;
  onSavePressed: (formData: any) => void;
  onClose: () => void;
  onDiscard: () => void;
  onChange: (changed: boolean) => void;
  unsavedChanges: boolean;
};

const widgets: RegistryWidgetsType = {
  customEditor: CustomEditor,
};

type ConfigEditorSelector = {
  metaData: MetaData | undefined;
  config: Config | undefined;
  module: ModuleDTO | undefined;
  open: boolean;
  unsavedChanges: boolean;
};

const mapState = createStructuredSelector<RootState, ConfigEditorSelector>({
  metaData: configEditorMetaDataSelector,
  config: configEditorConfigSelector,
  module: configEditorModuleSelector,
  open: configDrawerOpenSelector,
  unsavedChanges: configEditorUnsavedChangesSelector,
});
const mapDispatch = {
  onClose: configEditorSlice.actions.closeRequest,
  onDiscard: configEditorSlice.actions.discardRequest,
  onSubmit: configEditorSlice.actions.submitConfigEditor,
  setUnsavedChanges: configEditorSlice.actions.setUnsavedChanges,
  loadConfigModule: configEditorSlice.actions.loadConfigModule,
};
const connector = connect(mapState, mapDispatch);

export type ConfigEditorProps = ConnectedProps<typeof connector>;

const useSetUnsavedChages = (
  setter: (changed: boolean) => void,
  config: Config | undefined,
  originalConfig: Config,
) => {
  const hasChanged = useMemo(() => {
    return JSON.stringify(config) !== JSON.stringify(originalConfig);
  }, [config, originalConfig]);

  useEffect(() => {
    setter(hasChanged);
  }, [setter, hasChanged]);
};

const ConfigEditorUnconnected = ({
  metaData,
  config,
  module,
  loadConfigModule,
  open,
  onSubmit,
  onDiscard,
  onClose,
  setUnsavedChanges,
  unsavedChanges,
}: ConfigEditorProps) => {
  useEffect(() => {
    if (config?.moduleId) loadConfigModule();
  }, [config?.moduleId, loadConfigModule]);

  const configInner = open && (
    <ConfigEditorInner
      metaData={metaData}
      config={config}
      module={module}
      onSavePressed={(result) => {
        onSubmit({ config: result, dataPaths: metaData?.dataPaths });
      }}
      onChange={(changed: boolean) => {
        setUnsavedChanges(changed);
      }}
      onClose={onClose}
      unsavedChanges={unsavedChanges}
      onDiscard={onDiscard}
    />
  );

  return (
    <SizeDrawer anchor="right" open={open} defaultSize={750}>
      {configInner}
      <UnsavedConfigWarning />
    </SizeDrawer>
  );
};

const ConfigEditorInner: FC<DashboardConfigEditorInnerProps> = ({
  metaData,
  config: _config,
  module,
  onSavePressed,
  onClose,
  onDiscard,
  onChange,
  unsavedChanges,
}) => {
  const [showEditor, setShowEditor] = useState(false);
  const [config, setConfig] = useState(undefined);
  const [jsonValid, setJsonValid] = useState(false);

  useSetUnsavedChages(onChange, config, _config);

  const handleChange = (newConfig: any) => {
    setConfig(newConfig);
  };

  const getDatasourceValue = (
    datasource: string | ModuleDataInput | ModuleDataOutput,
  ) => {
    if (typeof datasource === 'string') {
      return { value: datasource, description: '' };
    }

    return {
      ...datasource,
      value: datasource.datasource,
      description: datasource.description ?? '',
    };
  };

  const auxiliaryData: { [key: string]: any } = useMemo(() => {
    if (module) {
      const { consumedDatasources, exposedDataOutputs } = module.config;

      return {
        description: module.description,
        consumedDatasources: consumedDatasources.map(getDatasourceValue),
        exposedDataOutputs: exposedDataOutputs.map(getDatasourceValue),
      };
    }

    return {};
  }, [module]);

  const processedMetaData = useParseMetaData(metaData, auxiliaryData);

  useEffect(() => {
    if (_config) setConfig({ ..._config });
  }, [_config, processedMetaData]);

  const formContext = useRef<{ errors: { [key: string]: string[] } }>({
    errors: {},
  });

  const submitRef = useRef<HTMLButtonElement>(null);

  const setValueFromPath = (data: any, path: string[], value: string[]) => {
    const [actual, ...rest] = path;
    if (rest.length === 0) {
      value.forEach((v) => data[actual].addError(v));
      return;
    }
    setValueFromPath(data[actual], rest, value);
  };

  const validate = (formData: any, errors: any) => {
    Object.entries(formContext.current.errors).forEach(([key, error]) => {
      setValueFromPath(errors, key.substring(5).split('_'), error);
    });

    return errors;
  };

  const canSave = unsavedChanges && (showEditor ? jsonValid : true);

  return (
    <>
      <ConfigEditorHeader
        label={processedMetaData.JSONSchema?.title}
        onClose={onClose}
        canSave={canSave}
        showEditor={showEditor}
        toggleShowEditor={() => setShowEditor((previous) => !previous)}
        handleSave={() => {
          if (submitRef.current) {
            submitRef.current.click();
          } else if (canSave) {
            onSavePressed(config);
          }
        }}
        handleDiscard={onDiscard}
      />
      <div
        data-testid={CONFIG_EDITOR}
        style={{
          height: 'calc(100% - 40px)',
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: 1,
            overflow: 'auto',
            padding: showEditor ? '0px' : '15px',
          }}
        >
          {showEditor ? (
            <ConfigJsonEditor
              config={config}
              JSONSchema={processedMetaData.JSONSchema}
              onSave={onSavePressed}
              onChange={handleChange}
              showSubmit={false}
              onValidation={setJsonValid}
            />
          ) : (
            <StyledForm
              schema={processedMetaData.JSONSchema}
              uiSchema={processedMetaData.UISchema}
              onChange={(e: any) => handleChange(e.formData)}
              onSubmit={(e: any) => onSavePressed(e.formData)}
              formData={config}
              widgets={widgets}
              formContext={formContext.current}
              customValidate={validate}
              validator={validator}
              templates={{ FieldTemplate: CustomTemplate }}
            >
              <button
                style={{ display: 'none' }}
                type="submit"
                ref={submitRef}
              />
            </StyledForm>
          )}
        </div>
      </div>
    </>
  );
};

export const ConfigEditor = connector(ConfigEditorUnconnected);
