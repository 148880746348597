import React, { FC, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { RootState } from '../../../store';
import dataLogSlice, { DataLog, TabValues } from '../../../store/dataLogSlice';
import {
  allDataEventsSelector,
  dashboardLockedSelector,
  dataDrawerVisibleSelector,
  dataEventCountsSelector,
  dataLogCurrentTabSelector,
  dataLoggingEnabledSelector,
  dataLogWidgetSelector,
  dataPaneVisibleSelector,
  widgetDatasourceSelector,
} from '../../../store/selectors';
import { Widget, WidgetDatasourceMapping } from '../../../store/widgetSlice';
import SizeDrawer from '../../SizeDrawer/SizeDrawer';
import DataTabs, { DataTabsPassProps } from '../DataTabs/DataTabs';

const mapDispatch = {
  setLoggingEnabled: dataLogSlice.actions.setLoggingEnabled,
  setDataTab: dataLogSlice.actions.setTab,
  setShowDataLog: dataLogSlice.actions.setDataDrawerOpen,
};

type DataLoggingSelection = {
  events: DataLog[];
  eventCounts: { [datasource: string]: number };
  isDataPaneVisible: boolean;
  isLoggingEnabled: boolean;
  widgetDatasourceMappings: WidgetDatasourceMapping[];
  locked: boolean;
  showDataLog: boolean;
  datalogCurrentTab: TabValues;
  widgetDataWidget: Widget | undefined;
};

const mapState = createStructuredSelector<RootState, DataLoggingSelection>({
  events: allDataEventsSelector,
  eventCounts: dataEventCountsSelector,
  isDataPaneVisible: dataPaneVisibleSelector,
  isLoggingEnabled: dataLoggingEnabledSelector,
  widgetDatasourceMappings: widgetDatasourceSelector,
  locked: dashboardLockedSelector,
  showDataLog: dataDrawerVisibleSelector,
  datalogCurrentTab: dataLogCurrentTabSelector,
  widgetDataWidget: dataLogWidgetSelector,
});

const connector = connect(mapState, mapDispatch);

export type DataToolsProps = {
  locked: boolean;
  onClose: () => void;
} & DataTabsPassProps;

type ConnectedDataToolsProps = ConnectedProps<typeof connector>;

const DataTools: FC<ConnectedDataToolsProps> = (props) => {
  const {
    locked,
    setDataTab,
    datalogCurrentTab,
    widgetDataWidget,
    showDataLog,
    setShowDataLog,
    ...other
  } = props;

  const containerStyle = useMemo(() => {
    if (locked) return { flex: `0 0 0` };
    if (showDataLog) return { flex: `0 0 100%` };
    return { flex: `0 0 49px` };
  }, [locked, showDataLog]);

  return (
    <div style={containerStyle}>
      <SizeDrawer
        anchor="bottom"
        open={!locked}
        defaultSize={showDataLog ? 200 : 49}
        fixed={!showDataLog}
      >
        <DataTabs
          {...other}
          currentTab={datalogCurrentTab}
          updateDataTab={setDataTab}
          show={showDataLog}
          widgetDataWidget={widgetDataWidget}
          toggleShow={() => setShowDataLog(!showDataLog)}
        />
      </SizeDrawer>
    </div>
  );
};

export default connector(DataTools);
