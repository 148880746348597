import { DashboardLayout } from './layout';

export const dateLayout: DashboardLayout = {
  id: 'dateOnly',
  label: 'Date only',
  headers: {
    center: [
      {
        type: 'DatetimeRangeWidget',
        config: {
          datasources: ['U_range'],
          dataOutputs: ['dateFrom', 'dateTo', 'range', 'granularity'],
          dataUrlOutputs: ['range'],
          useInlineInputs: true,
          autoUpdate: true,
          updateSecond: 1,
          startOfDayRelativeToMidnightHours: 0,
          datasourceFrom: 'dateFrom',
          datasourceTo: 'dateTo',
          dataOutputTimeRange: 'range',
          dataOutputGranularity: 'granularity',
          header: 'Date Picker',
          defaultRange: {
            range: 'today',
          },
        },
        maxWidth: 850,
      },
    ],
    left: [],
    right: [],
  },
  leftPanels: [],
  rightPanels: [],
  type: 'Standard',
};
