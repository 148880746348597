import isEqual from 'lodash/isEqual';
import { DataManager } from '@OrigamiEnergyLtd/react-ui-components';
import {
  extractDashboardDetailsFromUrl,
  extractParamsFromUrl,
  URL_UPDATE,
} from './routeUtils';

const getUrlInfo = () => ({
  dashboard: extractDashboardDetailsFromUrl(),
  params: extractParamsFromUrl(),
});

export const URL_DASHBOARD = 'URL_DASHBOARD';

export const routeDatasource = (dataManager: DataManager) => {
  let urlInfo: ReturnType<typeof getUrlInfo> = {
    dashboard: { id: undefined, label: undefined },
    params: {},
  };
  const urlDatasourceListener = () => {
    const oldUrlInfo = urlInfo;
    urlInfo = getUrlInfo();
    if (!isEqual(urlInfo.dashboard, oldUrlInfo.dashboard)) {
      dataManager.next({
        datasource: URL_DASHBOARD,
        data: urlInfo.dashboard,
      });
    }
    Object.entries(urlInfo.params).forEach(([key, value]) => {
      // TODO: check if we need to emit data events on params removal

      if (!isEqual(urlInfo.params[key], oldUrlInfo.params[key])) {
        dataManager.next({
          datasource: key,
          data: value,
        });
      }
    });
  };
  window.addEventListener('popstate', urlDatasourceListener);
  window.addEventListener(URL_UPDATE, urlDatasourceListener);

  const event = new Event(URL_UPDATE);
  window.dispatchEvent(event);

  return () => {
    window.removeEventListener('popstate', urlDatasourceListener);
    window.removeEventListener(URL_UPDATE, urlDatasourceListener);
  };
};
