import React from 'react';
import { HANDLE_HEIGHT, HEADER_HEIGHT } from '../../util/flowGraph';
import {
  FLOW_NODE,
  FLOW_NODE_WIDGET_NAME,
  FLOW_NODE_WIDGET_TYPE,
} from '../../util/testids';
import WidgetNodeHandle from './WidgetNodeHandle';

type LayoutNodeProps = {
  data: {
    widgetId: string;
    name: string;
    outputs: string[];
    width: number;
    height: number;
    showData: () => void;
    dataStatus: { [key: string]: boolean };
  };
};

const LayoutNode: React.FC<LayoutNodeProps> = ({
  data: { widgetId, name, outputs, width, height, showData, dataStatus },
}) => (
  <div
    style={{ height, minWidth: width }}
    onClick={() => showData()}
    data-testid={`${FLOW_NODE}-${widgetId}`}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '28px',
      }}
    >
      <h3 data-testid={FLOW_NODE_WIDGET_NAME}>{name}</h3>
    </div>
    <h4 data-testid={FLOW_NODE_WIDGET_TYPE}>Dashboard Layout</h4>
    {outputs.map((title: string, i: number) => (
      <WidgetNodeHandle
        key={`${widgetId}_${title}`}
        top={HANDLE_HEIGHT * (i + 1) + HEADER_HEIGHT}
        type="source"
        title={title}
        widgetId={widgetId}
        icon={dataStatus[title] ? 'ok' : 'alert'}
      />
    ))}
  </div>
);

export default React.memo(LayoutNode);
