import {
  WidgetRegistry,
  MetaData,
} from '@OrigamiEnergyLtd/react-ui-components';
import { defaultThemeName, ThemeName } from '@OrigamiEnergyLtd/design-tokens';
import { ApiClient, IApiClient } from '@OrigamiEnergyLtd/ui-utilities';
import {
  Config,
  DashboardDTO,
  DashboardRouter,
  PublishModuleBody,
  ModuleDTO,
  UpdateLayoutsBody,
  AddWidgetBody,
} from '@OrigamiEnergyLtd/ui-node-services';
import { createTRPCProxyClient, httpLink } from '@trpc/client';
import { AxiosInstance } from 'axios';
import { extractDashboardDetailsFromUrl } from './route/routeUtils';
import { RootState } from './store';
import { Organisation } from './store/configEditorSlice';
import { Dashboard, ExportedDashboard } from './store/dashboardSlice';
import { FlowPosition } from './store/flowSlice';
import { emptyInitialState } from './store/initialState';
import { Widget } from './store/widgetSlice';
import {
  layoutToLayoutDTO,
  widgetDTOToWidget,
  widgetToWidgetDTO,
} from './util/conversionDTO';

export interface IDashboardApiClient extends IApiClient {
  preload: (
    currentPredefinedIds: string[],
    oldPredefinedIds: string[],
  ) => Promise<RootState>;
  getDashboards: () => Promise<DashboardDTO[]>;
  getDashboard: (id: string) => Promise<ExportedDashboard>;
  getModules: () => Promise<ModuleDTO[]>;
  addDashboard: (
    dashboard: Omit<Dashboard, 'id'>,
    widgets?: Widget[],
    flow?: FlowPosition[],
  ) => Promise<{
    dashboard: Dashboard;
    widgets: Widget[];
    flow: FlowPosition[];
  }>;
  cloneDashboard: (
    dashboardId: string,
  ) => Promise<{ dashboard: Dashboard; widgets: Widget[] }>;
  updateFlowCollections: (id: string, flow: FlowPosition[]) => void;
  updateDashboard: (id: string, dashboardUpdate: Partial<Dashboard>) => void;
  deleteDashboard: (dashboardId: string) => void;
  addWidget: (
    dashboardId: string,
    widgetType: string,
    initialConfig?: Config,
    maxHeightGrid?: number,
  ) => Promise<Widget>;
  removeWidget: (dashboardId: string, widgetId: string) => void;
  updateWidget: (widgetUpdate: Widget) => void;
  duplicateWidget: (widget: Omit<Widget, 'id'>) => Promise<Widget>;
  updateDashboardLayout: (payload: UpdateLayoutsBody) => void;
  logout: () => Promise<void>;
  getMetaData: (metaDataUrl: string) => Promise<MetaData>;
  publishModule: (module: PublishModuleBody) => Promise<ModuleDTO>;
  getModule: (id: string) => Promise<ModuleDTO | undefined>;
  getOrganisations: () => Promise<Organisation[]>;
}

// A compare function that can be used to determine the ordering of two dashboards
// Can be used as the compareFunction in an `array.sort(compareFunction)` method
export function dashboardCompare(a: Dashboard, b: Dashboard) {
  if (a.predefined === b.predefined) return a.label.localeCompare(b.label);
  else {
    if (a.predefined) return -1;
    if (b.predefined) return 1;
  }

  // both a and b are not predefined, therefore compare labels
  return a.label.localeCompare(b.label);
}

// Since we are only getting the first dashboard, we can do an O(n)
// pass of the array rather than an O(nlogn) sort.
export function getFirstDashboard(list: Dashboard[]): Dashboard | undefined {
  if (list.length === 0) return undefined; // Mimic old functionality where if dashboard array is empty then active dashboard is undefined

  return list.reduce((prev, curr) => {
    if (dashboardCompare(prev, curr) <= 0) return prev;
    else return curr;
  });
}

export class DashboardApiClient implements IDashboardApiClient {
  private axiosInstance: AxiosInstance;
  private trpcInstance;
  private constructor(
    private widgetRegistry: WidgetRegistry,
    private apiClient: ApiClient,
    baseApi: string,
  ) {
    this.axiosInstance = apiClient.axiosInstance;
    this.trpcInstance = createTRPCProxyClient<DashboardRouter>({
      links: [
        httpLink({
          url: `${baseApi}/dashboards/v1`,
          fetch: this.apiClient.getTrpcFetchInstance() as typeof fetch,
        }),
      ],
    });
  }

  public static async build(widgetRegistry: WidgetRegistry, baseApi: string) {
    const apiClient = await ApiClient.build(baseApi);
    return new DashboardApiClient(widgetRegistry, apiClient, baseApi);
  }

  public cleanup() {
    return this.apiClient.cleanup();
  }

  public async logout() {
    return await this.apiClient.logout().catch((error) => {
      console.error({ error });
    });
  }

  preload = async () => {
    const dashboards: Dashboard[] = await this.getDashboards();

    const state = emptyInitialState();
    state.preferences.theme =
      (localStorage.getItem('theme') as ThemeName) ?? defaultThemeName;

    state.dashboards.dashboardLocked = true;
    dashboards?.forEach((d) => {
      state.dashboards.entities[d.id] = d;
      state.dashboards.ids.push(d.id);
    });

    state.dashboards.activeDashboard =
      extractDashboardDetailsFromUrl()?.id ||
      (getFirstDashboard(dashboards)?.id as string);

    try {
      const modules = await this.getModules();
      for (const module of modules) {
        state.modules.entities[module.id] = module;
        state.modules.ids.push(module.id);
      }
    } catch (e) {
      console.log('error getting modules', e);
    }

    return state;
  };

  getModule = (id: string) =>
    this.trpcInstance.getModule.query({ id }).catch(() => undefined);

  getDashboards = () => this.trpcInstance.getDashboards.query();

  getDashboard = (dashboardId: string) =>
    this.trpcInstance.getDashboardWithWidgets
      .query({ id: dashboardId })
      .then(({ widgets = [], flow = [], ...dashboard }) => {
        const exported: ExportedDashboard = {
          dashboard,
          widgets: widgets.map((w) => widgetDTOToWidget(w, dashboard.id)),
          flow,
        };
        return exported;
      })
      .catch(() => ({}) as ExportedDashboard);

  getModules = () => this.trpcInstance.getModules.query();

  publishModule = (module: any) =>
    this.trpcInstance.publishModule.mutate(module);

  addDashboard = (
    dashboard: Omit<Dashboard, 'id'>,
    widgets: Widget[] = [],
    flow: FlowPosition[] = [],
  ) =>
    this.trpcInstance.addDashboard
      .mutate({
        ...dashboard,
        widgets: widgets.map(widgetToWidgetDTO),
        flow,
      })
      .then(({ widgets = [], flow = [], ...dashboard }) => ({
        dashboard,
        flow,
        widgets: widgets.map((w) => widgetDTOToWidget(w, dashboard.id)),
      }));

  cloneDashboard = (dashboardId: string) =>
    this.trpcInstance.cloneDashboard
      .mutate({ id: dashboardId })
      .then(({ widgets = [], flow = [], ...dashboard }) => ({
        dashboard,
        flow,
        widgets: widgets.map((w) => widgetDTOToWidget(w, dashboard.id)),
      }));

  updateFlowCollections = (id: string, flow: FlowPosition[]) =>
    this.trpcInstance.updateDashboardFlow
      .mutate({ id, flow })
      .catch(() => undefined);

  updateDashboard = (id: string, dashboardUpdate: Partial<Dashboard>) =>
    this.trpcInstance.updateDashboard.mutate({ id, ...dashboardUpdate });

  deleteDashboard = (id: string) =>
    this.trpcInstance.deleteDashboard.mutate({ id });

  addWidget = (
    dashboardId: string,
    type: string,
    initialConfig?: Config,
    maxHeightGrid = 0,
  ) => {
    const { layout, visible } = this.widgetRegistry.getItemByType(type)!;

    const layouts = {
      sm: layoutToLayoutDTO(layout),
      lg: layoutToLayoutDTO(layout),
    };

    if (visible !== false) {
      Object.values(layouts)
        .flatMap((layouts) => layouts)
        .forEach((layout) => {
          layout.w = layout.w || 6;
          layout.h = layout.h || 20;
          layout.minH = layout.minH || 3;

          if (maxHeightGrid > 0) {
            layout.h = Math.min(maxHeightGrid, layout.h);
          }
        });
    }

    const addWidgetBody: AddWidgetBody = {
      dashboardId,
      widget: { type, layouts },
    };

    if (
      initialConfig &&
      typeof initialConfig === 'object' &&
      Object.keys(initialConfig).length > 0
    ) {
      addWidgetBody.widget.config = { ...initialConfig };
    }

    return this.trpcInstance.addWidget
      .mutate(addWidgetBody)
      .then((r) => widgetDTOToWidget(r, dashboardId));
  };

  removeWidget = (dashboardId: string, widgetId: string) =>
    this.trpcInstance.deleteWidget.mutate({ id: widgetId, dashboardId });

  updateWidget = (widget: Widget, dashboardId = widget.dashboardId) =>
    this.trpcInstance.updateWidget.mutate({
      dashboardId,
      widget: { ...widgetToWidgetDTO(widget) },
    });

  duplicateWidget = (
    widget: Omit<Widget, 'id'>,
    dashboardId = widget.dashboardId,
  ) =>
    this.trpcInstance.addWidget
      .mutate({
        dashboardId,
        widget: { ...widgetToWidgetDTO(widget as Widget) },
      })
      .then((r) => widgetDTOToWidget(r, dashboardId));

  updateDashboardLayout = (payload: UpdateLayoutsBody) =>
    this.trpcInstance.updateDashboardLayout
      .mutate(payload)
      .catch(() => undefined);

  getTokenAttribute = (attributeName: string): unknown | undefined =>
    this.apiClient.getTokenAttribute(attributeName);

  getRoles = () => this.apiClient.getRoles();

  getOrganisations = () =>
    this.axiosInstance
      .get(`/v1/organisations`)
      .then((r) => {
        const out: Organisation[] =
          r.data?.content?.map((d: any) => {
            const organisation: Organisation = {
              id: d.id ?? '',
              name: d.resource?.name ?? '',
            };
            return organisation;
          }) ?? ([] as Organisation[]);
        return out;
      })
      .catch((e) => {
        console.error('Unable to get organisations', e);
        return [] as Organisation[];
      });

  getMetaData = (metaDataUrl: string) =>
    this.axiosInstance
      .get(metaDataUrl, { baseURL: undefined, withCredentials: false })
      .then((r) => r.data)
      .catch((e) => {
        console.error('Unable to get metadata', e);
        return undefined;
      });

  getTrpcFetchInstance = () => {
    return this.apiClient.getTrpcFetchInstance() as typeof fetch;
  };

  getTRPC() {
    return this.trpcInstance;
  }
}
