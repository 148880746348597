import { JSONSchema7 } from 'json-schema';
import { UiSchema } from '@rjsf/utils';
import { Widget } from '../../store/widgetSlice';
import { Organisation } from '../../store/configEditorSlice';

const getUserConfigDropdownSchema = (valueType: 'string' | 'number') => ({
  required: [
    'inputType',
    'valueType',
    'isMultiSelect',
    'enumOptions',
    'defaultValue',
  ],
  properties: {
    isMultiSelect: {
      type: 'boolean',
      title: 'Allow Multi-select',
      default: false,
    },
    enumOptions: {
      type: 'array',
      title: 'Dropdown options',
      items: {
        type: valueType,
      },
      minItems: 1,
    },
  },
  /** LEVEL 3
   * If isMultiSelect, then `defaultValues` should be an array of values of type `valueType`
   * Otherwise it should be a single value of type `valueType`
   */
  allOf: [
    {
      if: {
        properties: {
          isMultiSelect: { const: true },
        },
      },
      then: {
        properties: {
          defaultValue: {
            type: 'array',
            title: 'Default values',
            items: {
              type: valueType,
            },
            minItems: 1,
          },
        },
      },
    },
    {
      if: {
        properties: {
          isMultiSelect: { const: false },
        },
      },
      then: {
        properties: {
          defaultValue: {
            type: valueType,
            title: 'Default value',
          },
        },
      },
    },
  ],
});

const datasourceUserConfigSchema = {
  type: 'object',
  title: 'User configuration options',
  required: ['inputType', 'valueType', 'defaultValue'],
  properties: {
    valueType: {
      type: 'string',
      title: 'Value type',
      enum: ['string', 'number'],
      default: 'string',
    },
    inputType: {
      title: 'Input type',
      type: 'string',
      oneOf: [
        { enum: ['free'], title: 'Free text' },
        { enum: ['dropdown'], title: 'Dropdown' },
      ],
      default: 'free',
    },
  },
  /** LEVEL 2
   * If inputType == free, then we want the defaultValue to be a string / number based on the valueType
   * If inputType == dropdown, then we need to make sure options are provided (via 'enumOptions')
   */
  allOf: [
    {
      if: {
        properties: {
          inputType: { const: 'free' },
          valueType: { const: 'string' },
        },
      },
      then: {
        required: ['inputType', 'valueType', 'defaultValue'],
        properties: {
          defaultValue: {
            title: 'Default value',
            type: 'string',
          },
        },
      },
    },
    {
      if: {
        properties: {
          inputType: { const: 'free' },
          valueType: { const: 'number' },
        },
      },
      then: {
        required: ['inputType', 'valueType', 'defaultValue'],
        properties: {
          defaultValue: {
            title: 'Default value',
            type: 'number',
          },
        },
      },
    },
    // string dropdown
    {
      if: {
        properties: {
          inputType: { const: 'dropdown' },
          valueType: { const: 'string' },
        },
      },
      then: {
        ...getUserConfigDropdownSchema('string'),
      },
    },
    // number dropdown
    {
      if: {
        properties: {
          inputType: { const: 'dropdown' },
          valueType: { const: 'number' },
        },
      },
      then: {
        ...getUserConfigDropdownSchema('number'),
      },
    },
  ],
};

export const moduleConfigUiSchema: UiSchema = {
  'ui:order': [
    'label',
    'description',
    'moduleName',
    'category',
    'readPermission',
    'config',
  ],
  description: { 'ui:widget': 'textarea' },
  config: {
    datasources: {
      items: {
        'ui:order': ['alias', 'description', '*'],
        description: { 'ui:widget': 'textarea', 'ui:emptyValue': '' },
      },
    },
    dataOutputs: {
      items: {
        'ui:order': ['datasource', 'description', 'alias'],
        description: { 'ui:widget': 'textarea' },
      },
    },
  },
};

export const createModuleConfigRjsfSchema: (
  datasources: string[],
  dataOutputs: string[],
  widgets: Widget[],
  organisations: Organisation[],
) => JSONSchema7 = (datasources, dataOutputs, widgets, organisations) => {
  return {
    title: 'Publish Module',
    description: 'Module details...',
    type: 'object',
    required: ['label', 'description', 'category', 'readPermission', 'config'],
    properties: {
      label: {
        type: 'string',
        title: 'Module Name',
        minLength: 1,
      },
      description: {
        type: 'string',
        title: 'Description',
      },
      category: {
        type: 'string',
        title: 'Category',
        minLength: 1,
      },
      readPermission: {
        type: 'object',
        title: 'Read Permissions',
        required: ['isGlobal'],
        properties: {
          isGlobal: {
            type: 'boolean',
            title: 'Globally Visible?',
            default: false,
          },
        },
        dependencies: {
          isGlobal: {
            oneOf: [
              {
                properties: {
                  isGlobal: {
                    type: 'boolean',
                    enum: [true],
                  },
                },
              },
              {
                required: ['isGlobal', 'organisationIds'],
                properties: {
                  isGlobal: {
                    type: 'boolean',
                    enum: [false],
                  },
                  organisationIds: {
                    type: 'array',
                    title: 'Organisations with Read Permissions',
                    items: {
                      type: 'string',
                      oneOf: organisations.map((org) => ({
                        enum: [org.id],
                        title: org.name,
                      })),
                    },
                    default: [],
                  },
                },
              },
            ],
          },
        },
      },
      config: {
        type: 'object',
        title: 'Config',
        required: ['hidden'],
        properties: {
          datasources: {
            type: 'array',
            title: 'Module Inputs',
            items: {
              type: 'object',
              required: ['alias', 'configType'],
              properties: {
                alias: {
                  type: 'string',
                  title: 'Data Input',
                  enum: datasources.length ? [...datasources] : [''],
                },
                description: {
                  type: 'string',
                  title: 'Description',
                  default: '',
                },
                configType: {
                  type: 'string',
                  title: 'Configuration type',
                  oneOf: [
                    {
                      enum: ['datasource'],
                      title: 'Use datasource',
                    },
                    {
                      enum: ['user'],
                      title: 'User configured value',
                    },
                  ],
                  default: 'datasource',
                },
              },
              /** WELCOME, to RJSF dependency / conditional hell! **/
              /** LEVEL 1 **/
              /** If we've specified that this is only a datasource input, then just set the datasource and avoid the other circles of hell
               * Otherwise we need to define user configuration
               */
              dependencies: {
                configType: {
                  oneOf: [
                    {
                      required: ['alias', 'configType'],
                      properties: {
                        configType: { const: 'datasource' },
                        datasource: {
                          type: 'string',
                          title: 'Default datasource',
                        },
                      },
                    },
                    {
                      required: ['configType', 'alias', 'defaultValueOptions'],
                      properties: {
                        configType: { const: 'user' },
                        defaultValueOptions: { ...datasourceUserConfigSchema },
                        datasource: {
                          type: 'string',
                          title: 'Default datasource',
                        },
                      },
                    },
                  ],
                },
              },
            },
            default: [],
          },
          dataOutputs: {
            type: 'array',
            title: 'Module Outputs',
            items: {
              type: 'object',
              required: ['datasource'],
              properties: {
                datasource: {
                  type: 'string',
                  title: 'Data Output',
                  enum: dataOutputs.length ? [...dataOutputs] : [''],
                },
                description: {
                  type: 'string',
                  title: 'Description',
                  default: '',
                },
                alias: {
                  type: 'string',
                  title: 'Default Datasource',
                },
              },
            },
            default: [],
          },
          hidden: {
            type: 'boolean',
            title: 'Hide on Dashboard',
            default: false,
            description:
              'If ticked, this module will only be visible in the flow view when added to a dashboard',
          },
          excludedWidgetIds: {
            type: 'array',
            title: 'Exclude Widgets from Module',
            items: {
              type: 'string',
              oneOf: widgets.map((w) => ({
                enum: [w.id],
                title: `${w.config?.header || 'Unnamed'} (${w.type})`,
              })),
            },
            default: [],
          },
        },
      },
    },
  } as JSONSchema7;
};
