import { createSelector } from 'reselect';

import { RootState } from '.';
import {
  Dashboard,
  dashboardAdapter,
  ExportedDashboard,
} from './dashboardSlice';
import { widgetAdapter, WidgetDatasourceMapping } from './widgetSlice';

import { MetaData } from '@OrigamiEnergyLtd/react-ui-components';
import { Layouts } from 'react-grid-layout';
import { WidgetTypeLabelMap } from '../components/Flow/Flow';
import {
  moduleAdapter,
  moduleFormIsOpen,
  moduleFormIsSubmitting,
} from './moduleSlice';

const dashboardSelectors = dashboardAdapter.getSelectors<RootState>(
  (state) => state.dashboards,
);

const dashboardEntitySelector = (state: RootState) => state.dashboards.entities;

const widgetSelectors = widgetAdapter.getSelectors<RootState>(
  (state) => state.widgets,
);

export const dashboardByIdSelector = dashboardSelectors.selectById;

export const currentThemeSelector = (state: RootState) =>
  state.preferences.theme;

export const betaModeSelector = (state: RootState) => state.preferences.beta;

const byLabel = (a: Dashboard, b: Dashboard): number => {
  const out = a.label.localeCompare(b.label);
  return out;
};

const modulesStateSelector = (state: RootState) => state.modules;

const moduleSelectors = moduleAdapter.getSelectors<RootState>(
  (state) => state.modules,
);

export const modulesSelector = createSelector(
  moduleSelectors.selectAll,
  (modules) => [...modules],
);

export const moduleByIdSelector = moduleSelectors.selectById;

const isModule = ({ moduleMeta }: Dashboard) => moduleMeta !== undefined;
const isNotModule = ({ moduleMeta }: Dashboard) => moduleMeta === undefined;

export const orderedModuleDashboardSelector = (state: RootState) => {
  return dashboardSelectors
    .selectAll(state)
    .slice()
    .filter(isModule)
    .sort(byLabel);
};

const isPredefined = ({ predefined }: Dashboard) => predefined;

export const orderedPredefinedDashboardSelector = (state: RootState) => {
  return dashboardSelectors
    .selectAll(state)
    .slice()
    .filter(isNotModule)
    .filter(isPredefined)
    .sort(byLabel);
};

const isUser = ({ predefined }: Dashboard) => !predefined;

export const orderedUserDashboardSelector = (state: RootState) => {
  return dashboardSelectors
    .selectAll(state)
    .slice()
    .filter(isNotModule)
    .filter(isUser)
    .sort(byLabel);
};

export const activeDashboardIdSelector = (state: RootState) =>
  state.dashboards.activeDashboard;

export const dashboardSidePanelIdSelector = (state: RootState) =>
  state.dashboards.sidePanel;

export const activeDashboardSelector = createSelector(
  activeDashboardIdSelector,
  dashboardEntitySelector,
  (activeDashboardId, dashboards) =>
    activeDashboardId ? dashboards[activeDashboardId] : undefined,
);

export const activeDashboardLabelSelector = createSelector(
  activeDashboardSelector,
  (dashboard) => dashboard?.label,
);

export const activeDashboardLayoutSelector = createSelector(
  activeDashboardSelector,
  (dashboard) => dashboard?.layout,
);

export const dashboardLockedSelector = (state: RootState) =>
  state.dashboards.dashboardLocked;

export const dashboardReadySelector = (state: RootState) =>
  state.dashboards.dashboardReady;

export const dashboardListVisibleSelector = (state: RootState) =>
  state.dashboards.showList;

export const allWidgetSelector = createSelector(
  widgetSelectors.selectAll,
  (widgets) => [...widgets],
);

export const allWidgetsDatasourceSelector = createSelector(
  allWidgetSelector,
  (widgets) =>
    [
      ...widgets.reduce((set, w) => {
        w.config?.datasources?.forEach((d: string) => set.add(d));
        return set;
      }, new Set()),
    ] as string[],
);

export const allWidgetsDataOutputSelector = createSelector(
  allWidgetSelector,
  (widgets) =>
    [
      ...widgets.reduce((set, w) => {
        w.config?.dataOutputs?.forEach((d: string) => set.add(d));
        return set;
      }, new Set()),
    ] as string[],
);

export const allWidgetByIdSelector = (state: RootState) =>
  widgetSelectors.selectEntities(state);

export const widgetListVisibleSelector = (state: RootState) =>
  state.dashboards.showWidgetList;

export const widgetById = widgetSelectors.selectById;
export const widgetByIds = widgetSelectors.selectIds;

const widgetRegistrationMapSelector = (state: RootState) =>
  state.widgets.widgetTypeMap;

export const widgetsByDashboardIdSelector = (dashboardIdFilter: string) =>
  createSelector(widgetSelectors.selectAll, (widgets) =>
    widgets.filter(({ dashboardId }) => dashboardId === dashboardIdFilter),
  );

export const widgetTypeLabelMapSelector = createSelector(
  widgetRegistrationMapSelector,
  (widgetTypeMap): WidgetTypeLabelMap =>
    Object.entries(widgetTypeMap).reduce(
      (acc, [type, registration]) => ({ ...acc, [type]: registration.label }),
      {},
    ),
);

export const widgetComponontUrlSelector = (
  state: RootState,
  widgetKey: string,
): string | undefined =>
  state.widgets.widgetTypeMap[widgetKey]?.webComponent?.componontUrl;

export const widgetMetadataSelector = (
  state: RootState,
  widgetKey: string,
): MetaData | undefined => state.configEditor.widgetMetadataMap[widgetKey];

export const layoutsSelector = createSelector(
  allWidgetSelector,
  widgetRegistrationMapSelector,
  (widgets, widgetTypeMap) => {
    return widgets.reduce((previous, { layouts, type }) => {
      const isVisible = widgetTypeMap[type]?.visible !== false;
      const next: Layouts = {};

      Object.keys(layouts).forEach((key) => {
        const previousValue = previous[key] || [];
        let newValue = layouts[key][0];

        if (!isVisible) {
          newValue = { ...newValue, h: 0, minH: 0, w: 0, x: 0, y: 0 };
        }

        next[key] = [...previousValue, newValue];
      });

      return next;
    }, {} as Layouts);
  },
);

export const highlightedWidgetIdSelector = (state: RootState) =>
  state.widgets.highlightWidgetId;

export const maxHeightGridSelector = (state: RootState) =>
  state.widgets.maxHeightGrid;

export const exportedDashboardSelector = (state: RootState) => (id: string) =>
  ({
    widgets: widgetSelectors
      .selectAll(state)
      .filter(({ dashboardId }) => dashboardId === id),
    dashboard: dashboardSelectors.selectById(state, id),
  }) as ExportedDashboard;

export const dataLogSelector = (state: RootState) => state.dataLog;

export const allDataEventsSelector = createSelector(
  dataLogSelector,
  (state) => state.datas,
);

export const dataEventCountsSelector = createSelector(
  dataLogSelector,
  (state) => state.eventCounts,
);

export const dataLoggingEnabledSelector = createSelector(
  dataLogSelector,
  (state) => state.isLoggingEnabled,
);

export const dataPaneVisibleSelector = createSelector(
  dataLogSelector,
  (state) => state.isVisible,
);

export const dataDrawerVisibleSelector = createSelector(
  dataLogSelector,
  (state) => state.dataDrawerOpen,
);

export const dataLogCurrentTabSelector = createSelector(
  dataLogSelector,
  (state) => {
    return state.tab;
  },
);

export const dataLogWidgetDataSelector = createSelector(
  dataLogSelector,
  (state) => state.widgetDataWidgetId,
);

export const dataLogWidgetSelector = createSelector(
  dataLogSelector,
  allWidgetByIdSelector,
  (state, widgets) =>
    state.widgetDataWidgetId ? widgets[state.widgetDataWidgetId] : undefined,
);

export const widgetDatasourceSelector = createSelector(
  dataLogSelector,
  allWidgetSelector,
  (dataLogState, widgets) => {
    return widgets.map((w): WidgetDatasourceMapping => {
      return {
        id: w.id,
        type: w.type,
        header: w.config?.header,
        datasources: dataLogState.widgetDatasourceMap[w.id]?.datasources ?? [],
        dataOutputs: dataLogState.widgetDatasourceMap[w.id]?.dataOutputs ?? [],
      };
    });
  },
);

export const notificationsSelector = (state: RootState) => state.notifications;

export const allNotificationsSelector = createSelector(
  notificationsSelector,
  (state) => state.notifications,
);

export const flowSelector = (state: RootState) => state.flow;

export const flowPositionByIdSelector = createSelector(
  flowSelector,
  ({ positions }) =>
    (positions || []).reduce(
      (acc, position) => ({ ...acc, [position.widgetId]: position }),
      {},
    ),
);

export const showFlowSelector = (state: RootState) => state.flow.show;

export const configDrawerOpenSelector = (state: RootState) =>
  state.configEditor.drawerOpen;

export const configEditorWidgetIdSelector = (state: RootState) =>
  state.configEditor.widgetId;

export const currentWidgetSelector = createSelector(
  allWidgetByIdSelector,
  configEditorWidgetIdSelector,
  (widgets, currentWidget) =>
    currentWidget ? widgets[currentWidget] : undefined,
);

export const configEditorConfigSelector = createSelector(
  allWidgetByIdSelector,
  configEditorWidgetIdSelector,
  (widgets, currentWidgetId) =>
    currentWidgetId ? widgets[currentWidgetId]?.config : undefined,
);

const configEditorMetadataMapSelector = (state: RootState) =>
  state.configEditor.widgetMetadataMap;

export const configEditorMetaDataSelector = createSelector(
  currentWidgetSelector,
  configEditorMetadataMapSelector,
  (widget, metaDatas): MetaData | undefined =>
    widget && widget.type ? metaDatas[widget.type] : undefined,
);

export const configEditorModuleSelector = (state: RootState) =>
  state.configEditor.module;

export const configEditorUnsavedChangesSelector = (state: RootState) =>
  state.configEditor.unsavedChanges && state.configEditor.drawerOpen;

export const configEditorPendingCloseSelector = (state: RootState) =>
  state.configEditor.pendingConfigClose;

export const organisationsSelector = (state: RootState) =>
  state.configEditor.organisations;

export const moduleConfigDrawerIsOpenSelector = createSelector(
  modulesStateSelector,
  (state) => moduleFormIsOpen(state),
);

export const moduleConfigDrawerIsSubmittingSelector = createSelector(
  modulesStateSelector,
  (state) => moduleFormIsSubmitting(state),
);

export const moduleConfigDrawerErrorToDisplaySelector = createSelector(
  modulesStateSelector,
  (state) => state.errorToDisplay,
);
