import Editor, { useMonaco } from '@monaco-editor/react';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { currentThemeSelector } from '../../store/selectors';
import { language as sqlLanguage, conf as sqlConf } from './languages/sql';
import { WidgetProps } from '@rjsf/utils';

const registerLanguage = (
  monaco: any,
  id: string,
  language: any,
  conf: any,
) => {
  monaco.languages.register({ id });
  monaco.languages.setMonarchTokensProvider(id, language);
  monaco.languages.setLanguageConfiguration(id, conf);
};

export const CustomEditor: FC<WidgetProps> = ({
  id,
  formContext,
  onChange,
  value,
  options: { language },
}: WidgetProps) => {
  const monaco = useMonaco();
  const themeName = useSelector(currentThemeSelector);

  useEffect(() => {
    if (!monaco) return;

    registerLanguage(monaco, 'sql', sqlLanguage, sqlConf);
  }, [monaco]);

  return (
    <div
      style={{
        width: '100%',
        height: (value?.split('\n').length || 1) * 18,
        minHeight: 50,
        maxHeight: '50vh',
        overflowX: 'auto',
      }}
    >
      <Editor
        theme={themeName === 'light' ? 'light' : 'vs-dark'}
        defaultLanguage={String(language)}
        value={value}
        onChange={(value) => {
          onChange(value);
        }}
        onValidate={(markers) => {
          formContext.errors[id] = markers.map((marker) => marker.message);
        }}
      />
    </div>
  );
};
