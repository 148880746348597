import { ModuleDTO } from '@OrigamiEnergyLtd/ui-node-services';
import {
  PublishModuleActionPayload,
  PublishModuleDatasourcePayload,
  PublishModuleInputDatasourcePayload,
} from '../../store/moduleSlice';

const moduleToPublishPayload = (
  module: ModuleDTO | undefined,
  widgetIds: string[],
) => {
  let payload: Partial<PublishModuleActionPayload['moduleInfo']> = {};
  if (module) {
    const {
      initialModuleWidgetConfig: {
        outputDatasources = [],
        inputDatasources = [],
      },
      exposedDataOutputs,
      consumedDatasources,
    } = module.config;

    const allOutputs: string[] = [];
    const dataOutputs: PublishModuleDatasourcePayload[] = outputDatasources.map(
      (d) => {
        allOutputs.push(d.datasource);
        return { ...d };
      },
    );

    exposedDataOutputs.forEach(({ datasource, description }) => {
      if (!allOutputs.includes(datasource)) {
        dataOutputs.push({ datasource: datasource, alias: '', description });
      } else {
        dataOutputs.find((d) => d.datasource === datasource)!.description =
          description;
      }
    });

    const allInputs: string[] = [];
    const datasources: PublishModuleInputDatasourcePayload[] =
      inputDatasources.map((d) => {
        allInputs.push(d.alias);
        return { ...d };
      });

    consumedDatasources.forEach(
      ({ datasource, description, configType, defaultValueOptions }) => {
        if (!allInputs.includes(datasource)) {
          datasources.push({
            datasource: '',
            alias: datasource,
            description,
            configType: configType ?? 'datasource',
            defaultValueOptions,
          });
        } else {
          const ds = datasources.find((d) => d.alias === datasource);
          ds!.description = description;
          ds!.configType = configType ?? 'datasource';
          ds!.defaultValueOptions = defaultValueOptions;
        }
      },
    );

    payload = {
      label: module.label,
      description: module.description,
      category: module.category,
      readPermission: module.readPermission,
      config: {
        hidden: module.config.hidden || false,
        excludedWidgetIds: [
          ...(module.config.excludedWidgetIds || []).filter((id) =>
            widgetIds.includes(id),
          ),
        ],
        dataOutputs,
        datasources,
      },
    };
  }

  return payload;
};

export default moduleToPublishPayload;
