import { Widget } from '../store/widgetSlice';
import { Layout } from 'react-grid-layout';
import { ExportedDashboard, Dashboard } from '../store/dashboardSlice';
import { FlowPosition } from '../store/flowSlice';
import { LayoutDTO, WidgetDTO } from '@OrigamiEnergyLtd/ui-node-services';

export const layoutToLayoutDTO = (layout: Partial<Layout> = {}): LayoutDTO => ({
  w: layout.w || 0,
  h: layout.h || 0,
  x: layout.x || 0,
  y: layout.y || 0,
  minH: layout.minH || 0,
});

export const layoutDTOToLayout = (
  layoutDTO: LayoutDTO,
  widgetId: string,
): Layout => ({
  ...layoutDTO,
  i: widgetId,
});

export const widgetDTOToWidget = (
  widgetDTO: WidgetDTO,
  dashboardId: string,
): Widget => {
  const layouts = Object.entries(widgetDTO.layouts).reduce(
    (acc, [size, layoutDTO]) => ({
      ...acc,
      [size]: [layoutDTOToLayout(layoutDTO, widgetDTO.id)],
    }),
    {},
  );
  return { ...widgetDTO, layouts, dashboardId };
};

export const widgetToWidgetDTO = ({
  dashboardId, // ignore dashboard ID in DTO
  ...widget
}: Widget): WidgetDTO => {
  const layouts: any = Object.entries(widget.layouts).reduce(
    (acc, [size, layouts]) => ({
      ...acc,
      [size]: layoutToLayoutDTO(layouts[0]),
    }),
    {},
  );
  return { ...widget, layouts };
};

export const dataToExportedDashboard = (data: any): ExportedDashboard => {
  if (data.version === '2') {
    const exportedDashboardDTO = data as {
      dashboard: Dashboard;
      widgets: WidgetDTO[];
      flow?: FlowPosition[];
    };

    if (exportedDashboardDTO.dashboard.layout === null) {
      exportedDashboardDTO.dashboard.layout = undefined;
    }

    return {
      ...exportedDashboardDTO,
      widgets: exportedDashboardDTO.widgets.map((w) =>
        widgetDTOToWidget(w, exportedDashboardDTO.dashboard.id),
      ),
    };
  }

  const { dashboard, widgets } = data as ExportedDashboard;
  const _widgets = widgets.map((w) => ({
    ...w,
    type: w.subType ? w.subType : w.type,
  }));

  return { dashboard, widgets: _widgets };
};
