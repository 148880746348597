import { DashboardLayout } from './layout';

export const emptyLayout: DashboardLayout = {
  id: 'empty',
  label: 'No controls',
  headers: {
    center: [],
    left: [],
    right: [],
  },
  leftPanels: [],
  rightPanels: [],
  type: 'Standard',
};
