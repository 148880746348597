import { DataLog } from '../../../store/dataLogSlice';
import { WidgetDatasourceMapping } from '../../../store/widgetSlice';

type DatasourceCounts = { [datasource: string]: number };

const widgetConnectsToDatasource = (
  widgetMapping: WidgetDatasourceMapping,
  datasource: string,
) => {
  const isInInputs = !!widgetMapping.datasources.find(
    (ds) => ds === datasource,
  );
  const isInOutputs = !!widgetMapping.dataOutputs.find(
    (ds) => ds === datasource,
  );
  return isInInputs || isInOutputs;
};

export class DatalogFilters {
  constructor(
    readonly datasource: string | undefined,
    readonly widget: WidgetDatasourceMapping | undefined,
  ) {
    if (!!widget && !!datasource) {
      throw new Error('Only one filter may be specified at a time.');
    }
  }

  nextFromDatasource(nextDatasource: string | undefined): DatalogFilters {
    const nextWidget = nextDatasource ? undefined : this.widget;
    return new DatalogFilters(nextDatasource, nextWidget);
  }

  nextFromWidget(
    nextWidget: WidgetDatasourceMapping | undefined,
  ): DatalogFilters {
    const nextDatasource = nextWidget ? undefined : this.datasource;
    return new DatalogFilters(nextDatasource, nextWidget);
  }

  filterDataLog(dataLog: DataLog[]): DataLog[] {
    let filteredDataLog = dataLog;

    if (this.datasource) {
      filteredDataLog = filteredDataLog.filter(
        (dl) => dl.data.datasource === this.datasource,
      );
    }

    if (this.widget !== undefined) {
      // My TypeScript gives me a "possibly undefined" error if I don't create
      // an intermediary variable. ¯\_(ツ)_/¯
      const widget = this.widget;
      filteredDataLog = filteredDataLog.filter((dl) =>
        widgetConnectsToDatasource(widget, dl.data.datasource),
      );
    }

    return filteredDataLog;
  }

  filterCounts(counts: DatasourceCounts): DatasourceCounts {
    if (!(this.datasource || this.widget)) return counts;

    const objectEntries = Object.entries(counts).filter((entry) => {
      const [datasource] = entry;

      const matchesDatasourceFilter =
        !this.datasource || this.datasource === datasource;

      const matchesWidgetFilter =
        !this.widget || widgetConnectsToDatasource(this.widget, datasource);

      return matchesDatasourceFilter && matchesWidgetFilter;
    });

    const out = Object.fromEntries(objectEntries);

    return out;
  }
}

export const DEFAULT_FILTERS: DatalogFilters = new DatalogFilters(
  undefined,
  undefined,
);
