import React, { FC } from 'react';
import {
  SIDE_PANEL_BUTTON,
  SIDE_PANEL_BUTTON_LABEL,
} from '../../../util/testids';
import styled from 'styled-components';

export type SidePanelButtonProps = {
  onClick: () => void;
  label?: string;
  selected: boolean;
};

const getBackgroundColor = (isSelected: boolean, isHover: boolean): string => {
  if (!isSelected && !isHover) return 'transparent';
  const alpha =
    isSelected && !isHover ? 0.2 : isSelected && isHover ? 0.28 : 0.08;
  return `rgba(255, 255, 255, ${alpha.toString()})`;
};

const SideButton = styled.div<{ $selected: boolean }>`
  display: flex;
  width: 100%;
  height: 6rem;
  float: top;
  text-wrap: noWrap;
  overflow: hidden;
  align-items: center;
  margin-top: 5px;
  justify-content: center;
  background-color: ${({ $selected }) => getBackgroundColor($selected, false)};
  &:hover {
    background-color: ${({ $selected }) => getBackgroundColor($selected, true)};
  }
`;

// Overflow: visible needed on this one to make sure the text all renders before rotation and therefore is properly visible in the parent after rotation
const StyledLabel = styled.div`
  overflow: visible;
  text-align: center;
  transform: rotate(-90deg);
  transform-origin: center;
  user-select: none;
  font-size: 0.8125rem;
  letter-spacing: 0.02857em;
  font-weight: 500;
`;

const SidePanelButton: FC<SidePanelButtonProps> = ({
  onClick,
  label,
  selected,
}) => {
  return (
    <SideButton
      data-testid={SIDE_PANEL_BUTTON}
      $selected={selected}
      onClick={onClick}
    >
      <StyledLabel data-testid={SIDE_PANEL_BUTTON_LABEL}>
        {label?.toLocaleUpperCase()}
      </StyledLabel>
    </SideButton>
  );
};

export default SidePanelButton;
