import {
  MetaData,
  RJSFCompatibleMetaData,
} from '@OrigamiEnergyLtd/react-ui-components';
import { useEffect, useState } from 'react';
import { parseMetaData } from './parseMetaData';

export const useParseMetaData = (
  metaData: MetaData | undefined,
  auxiliaryData: { [key: string]: any },
) => {
  const defaultMetaData = { JSONSchema: {}, UISchema: {} };
  const [processedMetaData, setLocalMetaData] =
    useState<RJSFCompatibleMetaData>(defaultMetaData);

  useEffect(() => {
    if (metaData) {
      const newMetaData = parseMetaData(metaData, auxiliaryData);
      setLocalMetaData(newMetaData);
    }
  }, [metaData, setLocalMetaData, auxiliaryData]);

  return processedMetaData;
};
