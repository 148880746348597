import {
  IconButton,
  Tooltip,
  CloseIcon,
  EditIcon,
  CloneIcon,
  ConfirmButton,
} from '@OrigamiEnergyLtd/react-ui-components';
import React from 'react';
import { HANDLE_HEIGHT, HEADER_HEIGHT } from '../../util/flowGraph';
import {
  FLOW_NODE,
  FLOW_NODE_CONFIG,
  FLOW_NODE_DUPLICATE,
  FLOW_NODE_WIDGET_NAME,
  FLOW_NODE_WIDGET_TYPE,
} from '../../util/testids';
import { NodeSelectedStatus } from './Flow';
import WidgetNodeHandle from './WidgetNodeHandle';

const getIconStyle = (selectedStatus: NodeSelectedStatus) => {
  return {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    padding: 0,
    color: [NodeSelectedStatus.SELECTED, NodeSelectedStatus.RELATED].includes(
      selectedStatus,
    )
      ? '#0a0f26'
      : undefined,
  };
};

export type WidgetNodeInput = {
  title: string;
  hasDefault: boolean;
};

type WidgetNodeProps = {
  data: {
    widgetId: string;
    name: string;
    label: string;
    inputs: WidgetNodeInput[];
    outputs: string[];
    width: number;
    height: number;
    locked: boolean;
    handleRemove: (widgetId: string) => void;
    openConfig: () => void;
    duplicateWidget: () => void;
    showData: () => void;
    dataStatus: { [key: string]: boolean };
    selectedStatus: NodeSelectedStatus;
  };
};

const WidgetNode: React.FC<WidgetNodeProps> = ({
  data: {
    widgetId,
    name,
    label,
    inputs,
    outputs,
    width,
    height,
    locked,
    handleRemove,
    openConfig,
    duplicateWidget,
    showData,
    selectedStatus,
    dataStatus,
  },
}) => {
  const ICON_STYLE = getIconStyle(selectedStatus);
  const duplicateButton = (
    <IconButton
      aria-label="Clone Widget"
      style={ICON_STYLE}
      onClick={duplicateWidget}
      data-testid={FLOW_NODE_DUPLICATE}
    >
      <Tooltip title="Clone Widget">
        <span style={{ display: 'flex' }}>
          <CloneIcon size={16} />
        </span>
      </Tooltip>
    </IconButton>
  );

  const configButton = (
    <IconButton
      aria-label="Widget Configuration"
      style={ICON_STYLE}
      onClick={openConfig}
      data-testid={FLOW_NODE_CONFIG}
    >
      <Tooltip title="Widget Configuration">
        <span style={{ display: 'flex' }}>
          <EditIcon size={16} />
        </span>
      </Tooltip>
    </IconButton>
  );

  const deleteButton = (
    <ConfirmButton
      label="Are you sure?"
      onClick={() => handleRemove(widgetId)}
      aria-label="remove"
      yesLabel="Delete Widget"
      yesVariant="danger"
      noLabel="Cancel"
      style={ICON_STYLE}
      className="WidgetNode_Delete"
    >
      <Tooltip title="Remove">
        <span style={{ display: 'flex' }}>
          <CloseIcon size={20} />
        </span>
      </Tooltip>
    </ConfirmButton>
  );

  return (
    <div
      style={{ height, minWidth: width }}
      onClick={() => showData()}
      data-testid={`${FLOW_NODE}-${widgetId}`}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '28px',
        }}
      >
        <h3 data-testid={FLOW_NODE_WIDGET_NAME}>{name}</h3>
        {!locked && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
            }}
          >
            {configButton}
            {duplicateButton}
            {deleteButton}
          </div>
        )}
      </div>
      <h4 data-testid={FLOW_NODE_WIDGET_TYPE}>{label}</h4>
      {inputs.map(({ title, hasDefault }, i) => (
        <WidgetNodeHandle
          key={`${widgetId}_${title}`}
          top={HANDLE_HEIGHT * (i + 1) + HEADER_HEIGHT}
          type="target"
          title={title}
          widgetId={widgetId}
          icon={dataStatus[title] ? 'ok' : hasDefault ? 'warning' : 'alert'}
        />
      ))}
      {outputs.map((title, i) => (
        <WidgetNodeHandle
          key={`${widgetId}_${title}`}
          top={HANDLE_HEIGHT * (i + 1) + HEADER_HEIGHT}
          type="source"
          title={title}
          widgetId={widgetId}
          icon={dataStatus[title] ? 'ok' : 'alert'}
        />
      ))}
    </div>
  );
};

export default React.memo(WidgetNode);
