import React, { useCallback, useEffect, useState } from 'react';
import { Drawer } from '@OrigamiEnergyLtd/react-ui-components';
import { SIZE_DRAWER, SIZE_DRAWER_DRAGGER } from '../../util/testids';
import styled from 'styled-components';

const DrawerContent = styled.div`
  user-select: none;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.background.default};
  display: flex;
  flex-direction: column;
`;

const DrawerDragger = styled.div`
  position: absolute;
  z-index: 100;
  background-color: #fff;
`;

export type SizeDrawerProps = {
  className?: string;
  defaultSize: number;
  open: boolean;
  anchor: 'left' | 'right' | 'bottom';
  children: React.ReactNode;
  fixed?: boolean;
  zIndex?: number;
};

const SizeDrawerUnstyled: React.FC<SizeDrawerProps> = ({
  className,
  defaultSize,
  open,
  anchor,
  children,
  fixed = false,
}) => {
  const [drawerSize, setDrawerSize] = useState(defaultSize);
  const isHorizontal = anchor === 'bottom';
  const maxSize = (isHorizontal ? window.innerHeight : window.innerWidth) * 0.9;

  useEffect(() => {
    setDrawerSize(defaultSize);
  }, [defaultSize]);

  const handleMouseDown = () => {
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseMove = useCallback(
    (e: any) => {
      let newSize = defaultSize;

      switch (anchor) {
        case 'left':
          // positive mouse position
          newSize = e.clientX;
          break;
        case 'right':
          // body width and negative mouse position
          newSize = document.body.offsetWidth - e.clientX;
          break;

        case 'bottom':
          // body height and negative mouse position
          newSize = document.body.offsetHeight - e.clientY;
          break;
      }

      if (newSize < defaultSize) {
        setDrawerSize(defaultSize);
      } else if (newSize > maxSize) {
        setDrawerSize(maxSize);
      } else {
        setDrawerSize(newSize);
      }
    },
    [anchor, defaultSize, maxSize],
  );

  const internalStyle: React.CSSProperties = {};
  if (!fixed) {
    isHorizontal
      ? (internalStyle.height = drawerSize)
      : (internalStyle.width = drawerSize);
  }

  const barStyle: React.CSSProperties = isHorizontal
    ? { height: '5px', cursor: 'ns-resize', left: 0, right: 0 }
    : { width: '5px', cursor: 'ew-resize', top: 0, height: '100%' };

  switch (anchor) {
    case 'left':
      barStyle.right = 0;
      break;
    case 'right':
      barStyle.left = 0;
      break;
    case 'bottom':
      barStyle.top = 0;
      break;
  }

  return (
    <Drawer
      className={className}
      variant="persistent"
      PaperProps={{ style: internalStyle }}
      anchor={anchor}
      open={open}
      data-testid={SIZE_DRAWER}
    >
      {!fixed && (
        <DrawerDragger
          data-testid={SIZE_DRAWER_DRAGGER}
          onMouseDown={handleMouseDown}
          style={barStyle}
        />
      )}
      <DrawerContent>{children}</DrawerContent>
    </Drawer>
  );
};

const SizeDrawer = styled(SizeDrawerUnstyled)`
  flex-shrink: 0;
`;

export default SizeDrawer;
