import react, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentThemeSelector } from '../store/selectors';
import getTheme from '../theme';
import { Theme } from '@OrigamiEnergyLtd/design-tokens';

export const useImportTheme = () => {
  const [theme, setTheme] = useState<Theme | undefined>(undefined);
  const themeName = useSelector(currentThemeSelector);

  useEffect(() => {
    getTheme(themeName).then((theme) => {
      setTheme(theme);
    });
  }, [themeName]);

  return { theme, themeName };
};
