import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactFlow, { Edge, Node, NodeTypes } from 'reactflow';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  DATASOURCE_NO_VALUE_EMITTED,
  useSubscribeToDatasources,
} from '../../events/useSubscribeToDatasources';
import { RootState } from '../../store';
import configEditorSlice from '../../store/configEditorSlice';
import { Dashboard } from '../../store/dashboardSlice';
import dataLogSlice from '../../store/dataLogSlice';
import flowSlice, { FlowState } from '../../store/flowSlice';
import {
  activeDashboardSelector,
  dashboardLockedSelector,
  dataEventCountsSelector,
  dataLogWidgetDataSelector,
  flowSelector,
  highlightedWidgetIdSelector,
  widgetTypeLabelMapSelector,
} from '../../store/selectors';
import widgetSlice from '../../store/widgetSlice';
import { isDashboardEditable } from '../../util/dashboard';
import { getTrafficLightColor } from '../../util/flowGraph';
import { FLOW_CONTAINER } from '../../util/testids';
import { FlowWrapper } from './FlowWrapper';
import LayoutNode from './LayoutNode';
import WidgetNode, { WidgetNodeInput } from './WidgetNode';

type EventCounts = { [datasource: string]: number };

const connectionLineStyle = { stroke: 'red' };
const nodeTypes: NodeTypes = {
  widgetNode: WidgetNode,
  layoutWidgetNode: LayoutNode,
};

type FlowSelection = {
  highlightedId: string;
  widgetTypeMap: WidgetTypeLabelMap;
  eventCounts: EventCounts;
  locked: boolean;
  dashboard?: Dashboard;
  flow: FlowState;
  selectedWidgetId: string | undefined;
};

export type WidgetTypeLabelMap = {
  [key: string]: string;
};

const mapState = createStructuredSelector<RootState, FlowSelection>({
  highlightedId: highlightedWidgetIdSelector,
  widgetTypeMap: widgetTypeLabelMapSelector,
  eventCounts: dataEventCountsSelector,
  locked: dashboardLockedSelector,
  dashboard: activeDashboardSelector,
  flow: flowSelector,
  selectedWidgetId: dataLogWidgetDataSelector,
});

const mapDispatch = {
  handleRemove: widgetSlice.actions.removeWidgetRequest,
  duplicateWidget: widgetSlice.actions.duplicateWidgetRequest,
  onNodesChange: flowSlice.actions.onNodesChange,
  onEdgesChange: flowSlice.actions.onEdgesChange,
  openConfig: configEditorSlice.actions.openConfigRequest,
  selectWidget: dataLogSlice.actions.setWidgetDataDisplayWidgetId,
};

const connector = connect(mapState, mapDispatch);
export const HIGHLIGHT_COLOR = '#FFFFFF';

type FlowProps = ConnectedProps<typeof connector> & {
  offsetHeight?: number;
};

export enum NodeSelectedStatus {
  SELECTED = 'widget-selected',
  UNSELECTED = 'widget-unselected',
  RELATED = 'widget-related',
}

const Flow: FC<FlowProps> = ({
  highlightedId,
  widgetTypeMap,
  eventCounts,
  locked,
  dashboard,
  handleRemove,
  offsetHeight = 40,
  flow: { nodes, edges },
  onNodesChange,
  onEdgesChange,
  openConfig,
  duplicateWidget,
  selectWidget,
  selectedWidgetId,
}) => {
  const [height, setHeight] = useState(window.innerHeight - offsetHeight);

  const isContainerLocked = !isDashboardEditable(dashboard) || locked;
  const datasources = useMemo(() => {
    const datasources = nodes.reduce(
      (acc, { data }) => [
        ...acc,
        ...data.inputs.map(
          (di: { title: string; hasDefault: boolean }) => di.title,
        ),
        ...data.outputs,
      ],
      [] as string[],
    );

    return Array.from(new Set(datasources));
  }, [nodes]);

  const dataEvents: { [key: string]: string } =
    useSubscribeToDatasources(datasources);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight - offsetHeight);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [offsetHeight]);

  useEffect(() => {
    setHeight(window.innerHeight - offsetHeight);
  }, [offsetHeight]);

  const labelledEdges = edges.map((edge: Edge): Edge<any> => {
    const connectedToSelected =
      selectedWidgetId &&
      (edge.source === selectedWidgetId || edge.target === selectedWidgetId);
    const style = connectedToSelected ? { stroke: HIGHLIGHT_COLOR } : {};
    const count = eventCounts[edge.data.input];

    if (count) {
      return {
        ...edge,
        label: `Data Events: ${count}`,
        labelBgPadding: [8, 4],
        labelBgBorderRadius: 4,
        style,
        animated: Boolean(connectedToSelected) === false, // only animate lines if line connects to an unselected node
        labelBgStyle: {
          fill: getTrafficLightColor(count),
          fillOpacity: 0.7,
        },
      };
    }

    return { ...edge };
  });

  const getNodeSelectedStatus = (
    widgetId: string,
    relatedNodesWidgetIds: string[],
  ): NodeSelectedStatus => {
    if (selectedWidgetId === undefined) {
      return NodeSelectedStatus.UNSELECTED;
    }
    if (selectedWidgetId === widgetId) {
      return NodeSelectedStatus.SELECTED;
    }
    if (relatedNodesWidgetIds.indexOf(selectedWidgetId) > -1) {
      return NodeSelectedStatus.RELATED;
    }
    return NodeSelectedStatus.UNSELECTED;
  };

  let flowBody;
  if (nodes.length) {
    const nodeToFlowNode = (node: any): Node => {
      const selectedStatus = getNodeSelectedStatus(
        node.data.widgetId,
        node.data.relatedNodesWidgetIds,
      );

      const getDataStatus = (): { [key: string]: boolean } => {
        const inputs: WidgetNodeInput[] = node.data.inputs;
        const outputs: string[] = node.data.outputs;

        const titles = [...inputs.map((d) => d.title), ...outputs];

        const out: Record<string, boolean> = titles.reduce(
          (acc, d) => ({
            ...acc,
            [d]:
              dataEvents[d] !== undefined &&
              dataEvents[d] !== DATASOURCE_NO_VALUE_EMITTED,
          }),
          {} as { [key: string]: boolean },
        );

        return out;
      };

      return {
        ...node,
        data: {
          ...node.data,
          label: node.data.type
            ? widgetTypeMap[node.data.type] ?? node.data.type
            : 'Unknown widget type',
          locked: isContainerLocked,
          handleRemove,
          openConfig: () => openConfig({ widgetId: node.data.widgetId }),
          duplicateWidget: () => duplicateWidget(node.data.widgetId),
          showData: () => selectWidget(node.data.widgetId),
          selectedStatus,
          dataStatus: getDataStatus(),
        },
        className:
          highlightedId === node.data.widgetId
            ? `react-flow__node__highlighted ${selectedStatus}`
            : selectedStatus,
      };
    };

    flowBody = (
      <ReactFlow
        deleteKeyCode={null}
        nodesDraggable={!isContainerLocked}
        nodes={nodes.map(nodeToFlowNode)}
        edges={labelledEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        connectionLineStyle={connectionLineStyle}
        nodesConnectable={false}
        onInit={({ fitView }) => {
          setTimeout(() => fitView({ duration: 500 }), 200);
        }}
        minZoom={0.2}
      />
    );
  }

  return (
    <FlowWrapper
      $locked={isContainerLocked}
      $height={height}
      data-testid={FLOW_CONTAINER}
    >
      {flowBody}
    </FlowWrapper>
  );
};

export default connector(Flow);
