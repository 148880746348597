import { DashboardLayout } from './layout';

export const multiAssetsLayout: DashboardLayout = {
  id: 'multiAssetsOnly',
  label: 'Assets only (Multi-select)',
  headers: {
    center: [],
    left: [],
    right: [],
  },
  leftPanels: [
    {
      id: 'assetsSelector',
      label: 'Assets',
      widget: {
        type: 'AssetListWidget',
        maxWidth: 250,
        config: {
          header: 'Asset list panel',
          dataOutput: 'selectedAssets',
          dataOutputs: ['selectedAssets'],
          detailOutput: 'selectedAssetDetails',
          datasources: [],
          multiSelectMode: true,
          default: 'all',
        },
      },
    },
  ],
  rightPanels: [],
  type: 'Standard',
};
