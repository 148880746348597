import React, { FC, ReactNode } from 'react';
import { HeaderWidgetDefinition } from '../../layouts/layout';
import { useWidgetRegistry } from '../WidgetsRegistryProvider';

import { WebComponentWrapper } from '@OrigamiEnergyLtd/react-ui-components';
import styled from 'styled-components';
import { HEADER_WIDGET } from '../../util/testids';
import { updateUrlParam, URL_PARAM_PREFIX } from '../../route/routeUtils';
import dataLogSlice from '../../store/dataLogSlice';
import { useDispatch } from 'react-redux';

export type HeaderWidgetsProps = {
  widgetDefinitions: HeaderWidgetDefinition[];
  dashboardReady: boolean;
};

// TODO Move this into a theme once we fix themes
export const DASHBOARD_HEADER_HEIGHT = '40px';

const HeaderContainer = styled.div<{
  $widgetDefinition: HeaderWidgetDefinition;
}>`
  max-width: ${({ $widgetDefinition }) => $widgetDefinition.maxWidth + 'px'};
  max-height: ${DASHBOARD_HEADER_HEIGHT};
  overflow: hidden;
  padding-top: 6px;
`;
export const HeaderWidgets: FC<HeaderWidgetsProps> = ({
  widgetDefinitions,
  dashboardReady,
}: HeaderWidgetsProps) => {
  const widgetRegistry = useWidgetRegistry();
  const dispatch = useDispatch();

  if (!dashboardReady) {
    return null;
  }

  return (
    <>
      {widgetDefinitions
        .map<React.ReactNode>((widgetDefinition, index) => {
          const widgetRegistryItem = widgetRegistry.getItemByType(
            widgetDefinition.type,
          );

          return (
            widgetRegistryItem?.webComponent && (
              <HeaderContainer
                key={index}
                $widgetDefinition={widgetDefinition}
                data-testid={HEADER_WIDGET}
              >
                {/* HACK to avoid scrollbars */}
                <div style={{ height: 200 }}>
                  <WebComponentWrapper
                    widgetId={`headerWidget-${widgetDefinition.type}`}
                    config={widgetDefinition.config}
                    webComponentDefinition={widgetRegistryItem.webComponent}
                    onUrlParamUpdate={(datasource, data) => {
                      updateUrlParam(URL_PARAM_PREFIX + datasource, data);
                    }}
                    onReceiveData={(data, sourceWidgetId) => {
                      dispatch(
                        dataLogSlice.actions.onReceiveData({
                          data,
                          sourceWidgetId,
                        }),
                      );
                    }}
                  />
                </div>
              </HeaderContainer>
            )
          );
        })
        .reduce((acc: ReactNode[], current, index) => {
          return [
            ...acc,
            index ? <div key={`separator_${index}`}>|</div> : null,
            current,
          ];
        }, [])
        .filter(Boolean)}
    </>
  );
};
