import React, { FC } from 'react';
import {
  Button,
  CloseIcon,
  IconButton,
  Tooltip,
} from '@OrigamiEnergyLtd/react-ui-components';
import {
  CONFIG_EDITOR_DISCARD,
  CONFIG_EDITOR_SUBMIT,
  EDITOR_CONFIG_CLOSE,
  EDITOR_CONFIG_HEADER,
  EDITOR_CONFIG_HEADER_LABEL,
  EDITOR_CONFIG_JSON_TOGGLE,
} from '../../util/testids';
import styled from 'styled-components';

const ConfigEditorHeaderButtons = styled.div`
  .muisvgicon-root,
  .muiicon-root {
    font-size: 20px;
  }
`;

const ConfigEditorHeaderSaveButtons = styled.div`
  display: flex;
  button {
    margin-right: 15px;
  }
`;

type DashboardConfigEditorHeaderProps = {
  className?: string;
  label?: string;
  onClose: () => void;
  canSave?: boolean;
  showEditor?: boolean;
  toggleShowEditor?: () => void;
  handleSave: () => void;
  handleDiscard: () => void;
};

const ConfigEditorHeaderUnstyled: FC<DashboardConfigEditorHeaderProps> = ({
  className = '',
  label = 'Configuration',
  onClose,
  canSave = true,
  showEditor = false,
  toggleShowEditor,
  handleSave,
  handleDiscard,
}) => {
  return (
    <div data-testid={EDITOR_CONFIG_HEADER} className={className}>
      <div
        data-testid={EDITOR_CONFIG_HEADER_LABEL}
        style={{ paddingRight: 15 }}
      >
        {label}
      </div>
      <ConfigEditorHeaderSaveButtons>
        <Button
          variant="secondary"
          data-testid={CONFIG_EDITOR_DISCARD}
          disabled={!canSave}
          onClick={handleDiscard}
          aria-label="Discard Changes"
        >
          Discard
        </Button>
        <Button
          variant="primary"
          data-testid={CONFIG_EDITOR_SUBMIT}
          disabled={!canSave}
          onClick={handleSave}
          aria-label="Save Changes"
        >
          Save
        </Button>
      </ConfigEditorHeaderSaveButtons>
      <div style={{ flexGrow: 2 }}></div>
      {toggleShowEditor && (
        <div style={{ paddingRight: 15 }}>
          <input
            data-testid={EDITOR_CONFIG_JSON_TOGGLE}
            style={{ verticalAlign: 'bottom' }}
            type="checkbox"
            checked={showEditor}
            onChange={toggleShowEditor}
          />
          Edit json
        </div>
      )}
      <ConfigEditorHeaderButtons>
        <Tooltip title={`Close ${label}`}>
          <IconButton
            size="small"
            data-testid={EDITOR_CONFIG_CLOSE}
            onClick={onClose}
            aria-label={`Close ${label}`}
          >
            <CloseIcon size={20} />
          </IconButton>
        </Tooltip>
      </ConfigEditorHeaderButtons>
    </div>
  );
};

export const ConfigEditorHeader = styled(ConfigEditorHeaderUnstyled)`
  width: 100%;
  color: #fff;
  background-color: ${({ theme }) => theme.palette.background.header};
  display: flex;
  user-select: none;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  box-shadow:
    0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%);
`;
