import React from 'react';
import { FieldTemplateProps } from '@rjsf/utils';
import { JSONSchema7 } from 'json-schema';

export const autoEnumInput = (props: FieldTemplateProps) => {
  const { id, label, required, children, schema, formData } = props;
  const { enumDescriptions } = schema as JSONSchema7 & {
    enumDescriptions: { [key: string]: string };
  };

  let enumDescription;

  if (formData && enumDescriptions[formData]) {
    enumDescription = enumDescriptions[formData];
  }

  return (
    <>
      <div className="form-group field field-string">
        <label className="control-label" htmlFor={id}>
          {label}
          {required && <span className="required">*</span>}
        </label>
        {children}
      </div>
      {enumDescription && <p>{enumDescription}</p>}
    </>
  );
};
