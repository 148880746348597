import React from 'react';
import { Handle, HandleType, Position } from 'reactflow';
import {
  CheckCircleIcon,
  WarningIcon,
} from '@OrigamiEnergyLtd/react-ui-components';
import {
  FLOW_HANDLE,
  FLOW_HANDLE_ALERT_ICON,
  FLOW_HANDLE_INPUT_ICON,
  FLOW_HANDLE_OK_ICON,
  FLOW_HANDLE_OUTPUT_ICON,
  FLOW_HANDLE_WARNING_ICON,
} from '../../util/testids';

export type WidgetNodeHandleProps = {
  top: number;
  type: HandleType;
  title: string;
  widgetId: string;
  icon: 'ok' | 'warning' | 'alert';
};

const WidgetNodeHandle: React.FC<WidgetNodeHandleProps> = ({
  top,
  type,
  title,
  widgetId,
  icon,
}) => {
  const iconElement =
    icon === 'alert' ? (
      <WarningIcon
        size={16}
        color="#E94949"
        data-testid={FLOW_HANDLE_ALERT_ICON}
      />
    ) : icon === 'ok' ? (
      <CheckCircleIcon
        size={16}
        color="#95C135"
        data-testid={FLOW_HANDLE_OK_ICON}
      />
    ) : (
      <CheckCircleIcon
        size={16}
        color="#148cf0"
        data-testid={FLOW_HANDLE_WARNING_ICON}
      />
    );

  return (
    <Handle
      type={type}
      position={type === 'target' ? Position.Left : Position.Right}
      style={{ top }}
      id={`${widgetId}_${title}`}
    >
      <span data-testid={FLOW_HANDLE}>
        {type === 'target' && (
          <span
            data-testid={FLOW_HANDLE_INPUT_ICON}
            style={{ verticalAlign: 'middle' }}
          >
            {iconElement}
          </span>
        )}
        <span>{title}</span>
        {type !== 'target' && (
          <span
            data-testid={FLOW_HANDLE_OUTPUT_ICON}
            style={{ verticalAlign: 'middle' }}
          >
            {iconElement}
          </span>
        )}
      </span>
    </Handle>
  );
};

export default React.memo(WidgetNodeHandle);
