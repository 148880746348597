import {
  ModuleDTO,
  PublishModuleBody,
} from '@OrigamiEnergyLtd/ui-node-services';
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

type FormStates =
  | 'closed' // Form is closed and un-editable
  | 'open-editable' // form is open and being edite
  | 'open-submitting'; // form has been submitted and we're waiting for the api client to return;

type ModuleBaseState = {
  formState: FormStates;
  errorToDisplay: string | undefined;
};

const initial: ModuleBaseState = {
  formState: 'closed',
  errorToDisplay: undefined,
};

export const moduleAdapter = createEntityAdapter<ModuleDTO>({
  selectId: (module) => module.id,
});

export const initialState = moduleAdapter.getInitialState(initial);

export type ModuleState = typeof initialState;

export type PublishModuleDatasourcePayload = {
  datasource: string;
  alias: string;
  description?: string;
};

export type PublishModuleInputDatasourcePayload = {
  alias: string;
  description?: string;
  configType?: 'datasource' | 'user';
  defaultValueOptions?: {
    valueType: string;
    inputType: string;
    isMultiSelect?: boolean;
    enumOptions?: string[] | number[];
    defaultValue: string | number | string[] | number[];
  };
  datasource: string;
};

export type PublishModuleActionPayload = {
  moduleInfo: Pick<
    PublishModuleBody,
    'label' | 'description' | 'category' | 'readPermission'
  > & {
    config: {
      hidden: boolean;
      dataOutputs: PublishModuleDatasourcePayload[];
      datasources: PublishModuleInputDatasourcePayload[];
      excludedWidgetIds: string[];
    };
  };
};

type ModulePublishSuccess = {
  isSuccess: true;
  dto: ModuleDTO;
};

type ModulePublishError = {
  isSuccess: false;
  error: string;
};

export type ModulePublishResult = ModulePublishSuccess | ModulePublishError;

export const moduleFormIsOpen = (state: ModuleState) => {
  return state.formState !== 'closed';
};

export const moduleFormIsSubmitting = (state: ModuleState) => {
  return state.formState === 'open-submitting';
};

export const moduleSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    requestDrawerOpen: () => {
      // Nothing here
    },
    onDrawerOpen: (state) => {
      if (state.formState === 'closed') {
        state.formState = 'open-editable';
        // error is cleared on open, as it is possible to have an error from a bad submit, and then retry and succeed
        state.errorToDisplay = undefined;
      }
    },
    requestDrawerDiscard: (state) => {
      if (state.formState !== 'closed') {
        state.formState = 'closed';
      }
    },

    publishModule: (
      state,
      // Typedef required for saga
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<PublishModuleActionPayload>,
    ) => {
      // Placeholder for Saga
      if (state.formState === 'open-editable') {
        state.formState = 'open-submitting';
      }
    },

    // yes, this is horrible and should be more generic, but I have no idea where to put it
    // To be sent by the publishModuleSaga when it returns
    moduleUpdateReturned: (
      state,
      { payload }: PayloadAction<ModulePublishResult>,
    ) => {
      if (['closed', 'open-editable'].includes(state.formState)) return;

      // open-submitting
      if (payload.isSuccess) {
        state.formState = 'closed';
        return;
      }
      state.formState = 'open-editable';
      state.errorToDisplay = String(payload.error);
    },

    upsertModule: (state, { payload }: PayloadAction<ModuleDTO>) => {
      moduleAdapter.upsertOne(state, payload);
    },

    refreshModules: (state, { payload }: PayloadAction<ModuleDTO[]>) => {
      moduleAdapter.removeAll(state);
      moduleAdapter.upsertMany(state, payload);
    },
  },
});
