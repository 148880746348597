import { DashboardLayout } from './layout';

export const assetsDateLayout: DashboardLayout = {
  id: 'assetsDateLayout',
  label: 'Date and assets',
  headers: {
    center: [
      {
        type: 'DatetimeRangeWidget',
        config: {
          datasources: ['U_range'],
          dataOutputs: ['dateFrom', 'dateTo', 'range', 'granularity'],
          dataUrlOutputs: ['range'],
          useInlineInputs: true,
          autoUpdate: true,
          updateSecond: 1,
          startOfDayRelativeToMidnightHours: 0,
          datasourceFrom: 'dateFrom',
          datasourceTo: 'dateTo',
          dataOutputTimeRange: 'range',
          dataOutputGranularity: 'granularity',
          header: 'Date Picker',
          defaultRange: {
            range: 'today',
          },
        },
        maxWidth: 850,
      },
    ],
    left: [],
    right: [],
  },
  leftPanels: [
    {
      id: 'assetsSelector',
      label: 'Assets',
      widget: {
        type: 'AssetListWidget',
        maxWidth: 250,
        config: {
          header: 'Asset list panel',
          dataOutput: 'selectedAssets',
          dataOutputs: ['selectedAssets'],
          detailOutput: 'selectedAssetDetails',
          dataUrlOutputs: ['selectedAssets'],
          datasources: ['U_selectedAssets'],
          datasourceSelection: 'U_selectedAssets',
          multiSelectMode: false,
          default: 'first',
        },
      },
    },
  ],
  rightPanels: [],
  type: 'Standard',
};
